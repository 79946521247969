import * as React from 'react';
import * as ReactDOM from 'react-dom';
import * as serviceWorker from './global/serviceWorker';
import * as ReactRouter from "react-router-dom";
import App  from './App';
import './index.css';
import 'bootstrap/dist/css/bootstrap.css';
import {storesInstance} from "./store/Stores";
import * as MobX from "mobx-react";

ReactDOM.render(
    (<ReactRouter.BrowserRouter basename={process.env.PUBLIC_URL}>
        <MobX.Provider stores={storesInstance}>
            <App/>
        </MobX.Provider>
    </ReactRouter.BrowserRouter>)
    , document.getElementById('root')
);

serviceWorker.register();

