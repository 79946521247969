import * as React from 'react'
import * as ReactStrap from 'reactstrap';
import * as ReactRouter from 'react-router-dom';
import {Global} from "../../global/Global";
import {InterfaceStore} from "../../store/InterfaceStore";
import * as MobX from "mobx-react";

class Login extends React.Component <any, any> {
    username = '';
    password = '';

    readonly ifStore : InterfaceStore;

    updateLoginStatus : any;

    constructor(props : any) {
        super(props);
        this.state = {
            showSpinner : false,
            allDone: false,
            goRegister: false,
            goForgetPassword: false,
        };
        this.handleSubmit = this.handleSubmit.bind(this);

        this.ifStore = this.props.stores.ifStore;
        this.ifStore.message = null; // to prevent the message being shown when switching page

        this.updateLoginStatus = props.loginStatusCallback;
    }

    handleSubmit(event : any) {
        //THIS IS VERY IMPORTANT
        //https://stackoverflow.com/questions/39809943/react-preventing-form-submission
        event.preventDefault();
        this.setState({
            showSpinner : true,
        });
        let formData : any;
        formData = new FormData(event.target);
        let jsonObject : any = {};
        for (const [key, value]  of formData.entries()) {
            jsonObject[key] = value;

            if (key === 'username') {
                this.username = value;
            }
            if (key === 'password') {
                this.password = value;
            }
        }

        if (Global.isOnline()) {
            //alert('You are online, logging in through the server!');
            //this.setMessage('You are online, logging in through the server!');
            let jsonData = JSON.stringify(jsonObject);

            let url = Global.getUrlPrefix() + 'login';

            fetch(url, {
                method: 'POST',
                mode: "cors",
                body: jsonData,
                headers: {
                    'Content-Type': 'application/json'
                }
            }).then(response => {
                return response.json();
            }).then(responseObject => {
                if (!responseObject.errors || responseObject.errors.length === 0) {
                    Global.setOnlineAuthCookie(true);
                    Global.setOfflineAuthCookie(true);
                    Global.setLocalUsernamePasswordCookie(this.username, this.password);
                    Global.setAuthTokenCookie(responseObject.access_token);

                    //Update login status back to app bar
                    this.updateLoginStatus(true);

                    this.setState({
                        requestStatus: responseObject,
                        allDone: true,
                        showSpinner : false,
                    });
                } else {
                    //So we can alert any error messages
                    this.ifStore.message = responseObject;

                    //so we can populate the question
                    this.setState({
                        requestStatus: responseObject,
                        showSpinner : false,
                    });
                }
            }).catch(e => {
                //alert('Something is wrong, you are not login!');
                this.ifStore.message ={error:'Email address and/or password not found.'};
                console.log("SOMETHING IS WRONG!", e);
                this.setState({
                    showSpinner : false,
                });
            });
        } else {
            this.ifStore.message = 'You are offline, logging in locally!';
            if (Global.checkLocalUsernamePasswordCookie(this.username, this.password)) {
                Global.setOfflineAuthCookie(true);
                this.ifStore.message = 'You are offline, logging in locally successfully!';
                //Update login status back to app bar
                this.updateLoginStatus(true);
                //Navigate back to profile page
                this.setState({
                    allDone: true
                })
            } else {
                this.ifStore.message = 'You are offline, logging in locally unsuccessfully!' ;
            }
            this.setState({
                showSpinner : false,
            });
        }
        event.preventDefault();
    }

    render() {
        if (this.state.goRegister) {
            return (<ReactRouter.Redirect to={'/register'}/>)
        }
        if (this.state.goForgetPassword) {
            return (<ReactRouter.Redirect to={'/resetpassword'}/>)
        }
        if (this.state.allDone) {
            return(<ReactRouter.Redirect to="/" />);
        } else return (
            <div style={{padding: '5px'}}>
                <h2>Login</h2>
                <p>Welcome to MySSy, your secure and private space for Care records and more! To make sure your info stays safe, you'll need to enter your email address and password.</p>
                {this.state.showSpinner ? <ReactStrap.Spinner className={'m-4'} color="info"/> : null}
                <ReactStrap.Form onSubmit={this.handleSubmit}>

                    <ReactStrap.FormGroup row className={'m-2'}>
                        <ReactStrap.Label sm={2}> Email Address </ReactStrap.Label>
                        <ReactStrap.Col sm={10}>
                            <ReactStrap.Input type="email" id="username" name="username" placeholder="Username" />
                        </ReactStrap.Col>
                    </ReactStrap.FormGroup>

                    <ReactStrap.FormGroup row className={'m-2'}>
                        <ReactStrap.Label sm={2}> Password </ReactStrap.Label>
                        <ReactStrap.Col sm={10}>
                            <ReactStrap.Input type="password" autoComplete="on" id="password" name="password" placeholder="Password" />
                        </ReactStrap.Col>
                    </ReactStrap.FormGroup>

                    <ReactStrap.Button type="submit" className={'ml-4'}>Login</ReactStrap.Button>

                </ReactStrap.Form>

                <br/>

                <div className={'pl-4'} >
                    No MySSy account yet? Please
                    <ReactStrap.Button outline color="primary" className={'m-2'} onClick={() => {
                        this.setState({
                            goRegister : true,
                        })
                    }}> Register </ReactStrap.Button>
                </div>

                <br/>

                <div className={'pl-4'} >
                    Forgot your password? Please
                    <ReactStrap.Button outline color="primary" className={'m-2'} onClick={() => {
                        this.setState({
                            goForgetPassword : true,
                        })
                    }}> Reset Password </ReactStrap.Button>
                </div>

            </div>
        );
    }
}

export default MobX.inject('stores')(ReactRouter.withRouter(MobX.observer(Login)));