import * as React from 'react'
import {InterfaceStore} from "../store/InterfaceStore";
import * as MobX from "mobx-react";
import * as ReactRouter from "react-router-dom";

class PageNotFound extends React.Component <any, any> {
    readonly ifStore : InterfaceStore;

    constructor(props : any) {
        super(props);
        this.state = {};

        this.ifStore = this.props.stores.ifStore;
        if (this.ifStore) {
            this.ifStore.message = null; // to prevent the message being shown when switching page
        }
    }

    render() {
        return (
            <div>
                <hr/>
                Sorry, this page does not exist.
                <hr/>
            </div>
        );
    }
}
export default MobX.inject('stores')(ReactRouter.withRouter(MobX.observer(PageNotFound)));