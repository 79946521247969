import * as React from 'react';
import * as ReactStrap from "reactstrap";
import {MdRemoveRedEye} from "react-icons/md";
import * as MobX from "mobx-react";
import moment from 'moment';
import * as Emoji from "emoji-mart";
import * as ReactRouter from "react-router-dom";

class HistoryListItem extends React.Component <any, any> {
    toggle : any;

    constructor(props : any) {
        super(props);

        this.state = {
            detailsModal: false,
            historyContentItems: [],
            loading: true,
        };

        this.toggleDetailsModal = this.toggleDetailsModal.bind(this)
    }

    toggleDetailsModal() {
        this.setState((prevState : any) => ({
            detailsModal: !prevState.detailsModal,
        }));
    }

    render() {
        let emojiName = ''
        let action = this.props.historyItem.auditType
        let target = ''

        if (this.props.historyItem.objectType === 'RECORD_VERSION'  || this.props.historyItem.objectType === 'ATTACHMENT' ) {
            action += "  VERSION SHARE"
            let content = JSON.parse(this.props.historyItem.content)

            if (content) {
                emojiName = this.props.stores.rkStore.versionDefs[content.version].icon
                target    = this.props.stores.rkStore.versionDefs[content.version].label
            }
        }

        let historyContentItems = [] as any;
        if (this.props.historyItem.content !== "null") {
            let historyContentObject : any = {};
            let historyObject = JSON.parse(this.props.historyItem.content)

            if (this.props.historyItem.objectType === "RECORD") {
                historyContentObject = JSON.parse(historyObject.content)
                let key : any;
                for(key in historyContentObject) {
                    let entry = historyContentObject[key];
                    historyContentItems.push ({
                        name: key,
                        value: entry.value
                    })
                }
            } else {
                if (historyObject.content) {
                    historyContentItems.push({'name':'Share With', 'value': historyObject['tag']})
                    historyContentItems.push({'name':'Viewing Time', 'value': moment(historyObject['expiryDate']).format("Do MMM  YYYY")})

                    historyContentObject = JSON.parse(historyObject.content)

                    if (historyContentObject) {
                        historyContentItems.push({'name': 'Security Code', 'value': historyContentObject['code']})
                    }
                }
            }
        }

        return (

            <div>
                <ReactStrap.Row>
                    <ReactStrap.Col xs={2}>
                        <ReactStrap.Label>{moment(this.props.historyItem.dateCreated).format("Do MMM  YYYY")}</ReactStrap.Label>
                    </ReactStrap.Col>
                    <ReactStrap.Col xs={1}>
                        <ReactStrap.Label>{this.props.historyItem.principalId}</ReactStrap.Label>
                    </ReactStrap.Col>
                    <ReactStrap.Col xs={3}>
                        <ReactStrap.Label>{action}: &nbsp; {emojiName &&
                        <Emoji.Emoji emoji={emojiName} size={20}/>
                        } &nbsp; {target}
                        </ReactStrap.Label>
                    </ReactStrap.Col>

                    {(this.props.historyItem.content !== "null" && action.startsWith('UPDATE') ) &&
                        <ReactStrap.Col xs={3}>
                            <ReactStrap.Button size="sm" outline color="primary" onClick={this.toggleDetailsModal}>
                                <MdRemoveRedEye/></ReactStrap.Button>
                        </ReactStrap.Col>
                    }
                </ReactStrap.Row>
                <hr/>

                {this.state.detailsModal &&
                <ReactStrap.Modal isOpen={this.state.detailsModal} toggle={this.toggleDetailsModal}>
                    <ReactStrap.ModalHeader toggle={this.toggle}>Updated details</ReactStrap.ModalHeader>
                    <ReactStrap.ModalBody>
                        <ReactStrap.Row style={{paddingLeft: "10px"}}>
                            <ReactStrap.Col sm={12}>
                                {historyContentItems.map((item : any) =>
                                    <div>
                                        <ReactStrap.Row>
                                            <ReactStrap.Label><strong>{item.name + ':'}</strong></ReactStrap.Label>
                                        </ReactStrap.Row>
                                        <ReactStrap.Row>
                                            <ReactStrap.Label>{item.value}</ReactStrap.Label>
                                        </ReactStrap.Row>
                                    </div>
                                )}
                            </ReactStrap.Col>
                        </ReactStrap.Row>
                    </ReactStrap.ModalBody>
                    <ReactStrap.ModalFooter>
                        <ReactStrap.Button color="secondary" onClick={this.toggleDetailsModal}>Close</ReactStrap.Button>
                    </ReactStrap.ModalFooter>
                </ReactStrap.Modal>
                }
            </div>
        )
    }
}
export default MobX.inject('stores')(ReactRouter.withRouter(MobX.observer(HistoryListItem)))