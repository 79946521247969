import * as React from 'react';
import * as ReactStrap from 'reactstrap';
import {Global} from "../global/Global";
import * as ReactRouter from "react-router-dom";
import VersionsLegend from '../components/VersionsLegend';
import {MdRemoveRedEye} from 'react-icons/md';

import * as MobX from "mobx-react";
import {InterfaceStore} from "../store/InterfaceStore";
import {RecordkeepingStore} from "../store/RecordkeepingStore";
import BioRecordEdit from "../components/BioRecordEdit";
import RegisteredRecordEdit from "../components/RegisteredRecordEdit";
import PersonalRecordEdit from "../components/PersonalRecordEdit";
import Attachment from "../components/Attachment";
import Preview from "../components/Preview";
import {ISite, OrgDataStore} from "../store/OrgDataStore";
import LoadingSpinner from "../components/LoadingSpinner";

class RecordEdit extends React.Component <any, any> {
    toggle : any;

    readonly ifStore : InterfaceStore;
    readonly rkStore : RecordkeepingStore;
    readonly odStore : OrgDataStore;

    constructor(props : any) {
        super(props);

        this.state = {
            //for re-direct navigation
            goProfile: false,
            goVersionsEdit: false,
            pendingLeave: '',
            leaveModal: false,
            pageDirty: false,
        };

        this.ifStore = this.props.stores.ifStore;
        this.ifStore.message = null; // to prevent the message being shown when switching page

        this.rkStore = this.props.stores.rkStore;
        this.odStore = this.props.stores.odStore;
    }

    getVersionsForItem = (name : any) => {
        let result : any = [];
        let content : any = this.rkStore.recordContent[name];

        if (content){
            result = content.versions
        }

        return result;
    }

    handleFieldChange = (event : any) => {
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;

        if (typeof(this.rkStore.recordContent[name]) == "undefined") {
            //default new items to be in the profile version
            this.rkStore.recordContent[name] = {
                versions: ['set0']
            }
        }
        this.rkStore.recordContent[name]['value'] = value;

        this.setState({
            pageDirty: true
        });
    }

    handleAttachmentSelect = (href:any) => {
        if (href === 'clear') {
            this.rkStore.attachment = null
            this.rkStore.attachmentHref = null
        } else {
            this.rkStore.attachmentFilename = href.filename
            this.rkStore.getAttachment(href.href)
        }

        this.setState({
            pageDirty: true
        });

    }

    handleSubmit = (event : any) => {
        //THIS IS VERY IMPORTANT
        //https://stackoverflow.com/questions/39809943/react-preventing-form-submission
        event.preventDefault();

        //set up for validating record
        let errors:string[] = []

        if (!this.rkStore.recordLabel){
            errors.push('You need to enter a label for this record')
        }

        if (!this.rkStore.recordType){
            errors.push('You need to enter a type for this record')
        }

        if (!this.rkStore.recordDate){
            errors.push('You need to enter a date for this record')
        }

        if ( errors.length  === 0 && !this.rkStore.recordContent.description && this.rkStore.recordContent.label) {
            this.rkStore.recordContent.description = {
                value: this.rkStore.recordContent.label.value
            }
        }

        if (errors.length > 0) {
            this.ifStore.message = {
                message: "Incomplete record",
                errors: errors
            }
        } else {
            this.setState({
                showSpinner: true,
            });

            if (this.rkStore.record.label) {
                this.rkStore.saveRecord()
            } else {
                this.rkStore.createRecord()
            }

            this.setState({
                showSpinner: false,
                pageDirty: false,

            });
        }

        event.preventDefault();

        window.scrollTo(0, 0);
    }

     handleVersionsChange = (name : any, set : any) => {
         let item : any = this.rkStore.recordContent[name];

         if (typeof(item) == "undefined") {
             item = {}
         }

         let itemVersions = item.versions;

         if(!itemVersions || itemVersions.length === 0) {
             itemVersions = [set]
         } else {
             let index = itemVersions.indexOf(set);
             if (index < 0) {
                 itemVersions.push(set);
             } else {
                 itemVersions.splice(index, 1);
             }
         }

         if(this.rkStore.recordContent[name]) {
             this.rkStore.recordContent[name]['versions'] = itemVersions;
         }

         this.setState({
             pageDirty: true
         });
    }

    getFieldValue = (name : any) => {
        return this.rkStore.recordContent[name]? this.rkStore.recordContent[name]['value'] : ""
    }

    toggleLeaveModal = () => {
        this.setState((prevState : any) => ({
            leaveModal: !prevState.leaveModal,
        }));
    }

    leavePage = () => {
        //refresh the orginal record as we are discarding any edits
        this.rkStore.getRecord(this.rkStore.record.href)
        if (this.state.pendingLeave && this.state.pendingLeave === 'profile') {
            this.setState({
                goProfile: true,
            })
        }

        if (this.state.pendingLeave && this.state.pendingLeave === 'versionsEdit') {
            this.setState({
                goVersionsEdit: true,
            })
        }
    }

    toggleTab = (tab : any) => {
        if (this.state.activeTab !== tab) {
            this.setState({
                activeTab: tab
            });
        }
    }

    render() {
        if (Global.getOfflineAuthCookie() === false && Global.getOnlineAuthCookie() === false) {
            //alert ('You are not login!!!');
            return <ReactRouter.Redirect to="/login" />;
        }

        if(this.state.goProfile) {
            return <ReactRouter.Redirect to="/" />;
        }

        if(this.state.goVersionsEdit) {
            return <ReactRouter.Redirect to="/versiondefs" />;
        }

        let rec : any = this.rkStore.record;

        //Decide which sort of record to display
        let recordComponent: any = null;

        let siteLabel:string = ''

        let canHaveAttachment = false
        if(rec && rec.recordcategory === 'Personal' && rec.recordtype === 'Bio') {
            recordComponent = <BioRecordEdit handleFieldChange={this.handleFieldChange} getFieldValue={this.getFieldValue} handleVersionsChange ={this.handleVersionsChange} getVersionsForItem={this.getVersionsForItem} handleAttachmentSelect={this.handleAttachmentSelect}/>
        } else if (rec.originid) {
            recordComponent = <RegisteredRecordEdit handleFieldChange={this.handleFieldChange} getFieldValue={this.getFieldValue} handleVersionsChange ={this.handleVersionsChange} getVersionsForItem={this.getVersionsForItem} handleAttachmentSelect={this.handleAttachmentSelect}/>
            canHaveAttachment = true;

            if (this.odStore.selectedSites) {
                let  matchingSite = this.odStore.selectedSites.find((site:ISite) => {
                    return this.rkStore.record.site.tag === site.tag
                });

                if(matchingSite) {
                    siteLabel = matchingSite.label;
                }
            }


        } else {
            recordComponent = <PersonalRecordEdit handleFieldChange={this.handleFieldChange} getFieldValue={this.getFieldValue} handleVersionsChange ={this.handleVersionsChange} getVersionsForItem={this.getVersionsForItem} handleAttachmentSelect={this.handleAttachmentSelect}/>
            canHaveAttachment = true;
        }

         return (
             <ReactStrap.Container fluid={true}>

                     {!this.rkStore.record.label &&
                         <ReactStrap.Row style={{padding: "10px"}}>
                             <ReactStrap.Col sm={8}>
                                 <h2 className={'m-2'}>Add a Record </h2>
                             </ReactStrap.Col>
                         </ReactStrap.Row>
                     }
                     {this.rkStore.record.label &&
                        <ReactStrap.Row style={{padding: "10px"}}>
                             <ReactStrap.Col sm={8}>
                                 { this.rkStore.recordLabel === "Personal details"
                                 && <h4 className={'m-2'}> My Personal Information </h4>}
                                 { this.rkStore.recordLabel !== "Personal details"
                                 && <h4 className={'m-2'}> {this.rkStore.recordLabel} </h4>
                                 }
                             </ReactStrap.Col>
                             <ReactStrap.Col className="text-right">
                                 <ReactStrap.Button outline color="primary" onClick={() => {
                                     if (this.state.pageDirty) {
                                         this.setState({
                                             pendingLeave: 'profile',
                                         });
                                         this.toggleLeaveModal()
                                     } else {
                                         this.setState({
                                             goProfile: true,
                                         })
                                     }
                                 }}> <MdRemoveRedEye/></ReactStrap.Button>
                             </ReactStrap.Col>
                        </ReactStrap.Row>
                     }


                 <ReactStrap.Row>
                     <ReactStrap.Col sm={12} className="text-right">

                         <VersionsLegend versions={this.rkStore.versionDefs} goVersionsEdit={() => {
                             if (this.state.pageDirty) {
                                 this.setState({
                                     pendingLeave: 'versionsEdit',
                                 });
                                 this.toggleLeaveModal()
                             } else {
                                 this.setState({
                                     goVersionsEdit: true,
                                 })
                             }
                         }}/>

                     </ReactStrap.Col>
                 </ReactStrap.Row>

                 <ReactStrap.Form onSubmit={this.handleSubmit}>

                     {recordComponent}

                    <ReactStrap.Button type="submit" className={'ml-4'}>Save</ReactStrap.Button>
                </ReactStrap.Form>

                <p/>

                {canHaveAttachment && this.rkStore.attachment &&
                    <div>
                        <br/>

                            <p> Copy stored on system, originally from {siteLabel}</p>
                            <Attachment/>
                     </div>
                }

                 {canHaveAttachment && this.rkStore.record.recordAttachments && this.rkStore.record.recordAttachments[0] && !this.rkStore.attachment &&
                    <LoadingSpinner msg={'Fetching attachment (this may take some time)...'}/>
                 }

                 {canHaveAttachment && siteLabel && !this.rkStore.attachment && this.odStore.previewAttachment &&
                    <div>
                        <br/>
                        <p> Preview from {siteLabel}</p>
                        <Preview/>
                    </div>
                }

                 {canHaveAttachment && siteLabel && !this.rkStore.attachment && !this.odStore.previewAttachment &&
                    <LoadingSpinner msg={'Fetching attachment (this may take some time)...'}/>
                 }

                <ReactStrap.Modal isOpen={this.state.leaveModal} toggle={this.toggleLeaveModal}>
                    <ReactStrap.ModalHeader toggle={this.toggle}>Unsaved Changes</ReactStrap.ModalHeader>
                    <ReactStrap.ModalBody>
                        You have unsaved changes on this page.  Leave page?
                    </ReactStrap.ModalBody>
                    <ReactStrap.ModalFooter>
                        <ReactStrap.Button color="primary" onClick={this.leavePage}>Leave</ReactStrap.Button>{' '}
                        <ReactStrap.Button color="secondary" onClick={this.toggleLeaveModal}>Cancel</ReactStrap.Button>
                    </ReactStrap.ModalFooter>
                </ReactStrap.Modal>
             </ReactStrap.Container>
        );
    }
}
export default MobX.inject('stores')(ReactRouter.withRouter(MobX.observer(RecordEdit)));