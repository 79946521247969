import {Global} from "../global/Global";

import BaseDao from "./BaseDao";

export default class RecordDao  extends BaseDao {
    constructContentObj(content: any): any {
    }
    constructDefaultObj(): any {
    }
    fetchAPI(): Promise<any> {
        return new Promise<any>(function () {
        });
    }
    pushAPI(content: any): Promise<any> {
        return new Promise<any>(function () {
        });
    }

    fetchRecords(filterString: string) {
        //todo: sync with local version
        /**********************************************************************************
         * Go get it
         **********************************************************************************/
        let url = Global.getUrlPrefix() + 'record' + (filterString ? '?'+ filterString : '');
        return fetch(url, {
            method: 'GET',
            mode: "cors",
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + Global.getAuthTokenCookie()
            }
        }).then(response => {
            return response.json();
        }).then(responseObject => {
            return this.promiseNormalisedResponse('loading record', responseObject)
        }).catch( (e) => {
            return this.promiseNormalisedResponse('loading record', e.message)
        });

    }

    fetchRecord(url: string) {
        //todo: sync with local version
        /**********************************************************************************
         * Go get it
         **********************************************************************************/
        return fetch(url, {
            method: 'GET',
            mode: "cors",
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + Global.getAuthTokenCookie()
            }
        }).then(response => {
            return response.json();
        }).then(responseObject => {
            return this.promiseNormalisedResponse('loading record', responseObject)
        }).catch( (e) => {
            return this.promiseNormalisedResponse('loading record', e.message)
        });

    }

    fetchAttachment(url: string) {
        //todo: sync with local version
        /**********************************************************************************
         * Go get it
         **********************************************************************************/
        return fetch(url + '?i', {
            method: 'GET',
            mode: "cors",
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + Global.getAuthTokenCookie()
            }
        }).then(response => {
            return response.blob();
        }).then(responseObject => {
            return this.promiseNormalisedResponse('loading record attachment', responseObject)
        }).catch( (e) => {
            return this.promiseNormalisedResponse('loading record attachment', e.message)
        });

    }

    fetchRecordHistory(id: string) {
        //todo: sync with local version
        /**********************************************************************************
         * Go get it
         **********************************************************************************/
        let url = Global.getUrlPrefix() + 'audit/record/' + id;
        return fetch(url, {
            method: 'GET',
            mode: "cors",
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + Global.getAuthTokenCookie()
            }
        }).then(response => {
            return response.json();
        }).then(responseObject => {
            return this.promiseNormalisedResponse('loading record history', responseObject)
        }).catch( (e) => {
            return this.promiseNormalisedResponse('loading record history', e.message)
        });

    }

    saveRecord(record: any, href: string) {
        //todo: sync with local version

        if (href) {
            record['attachmentHref'] = href;
        }

        let payload = JSON.stringify(record);

        return fetch(record.href, {
            method: 'PUT',
            mode: "cors",
            body: payload,
            headers: {
                'Authorization': 'Bearer ' + Global.getAuthTokenCookie()
            }
        }).then(response => {
            return response.json();
        }).then(responseObject => {
            return this.promiseNormalisedResponse('saving record', responseObject)
        }).catch( (e) => {
            return this.promiseNormalisedResponse('saving record', e.message)
        });

    }

    createRecord(record: any, performanceId:string, href: string) {
        //todo: sync with local version

        record['performanceId'] = performanceId;

        if (href) {
            record['attachmentHref'] = href;
        }

        let payload = JSON.stringify(record);

        let url = Global.getUrlPrefix() + 'record';
        return fetch(url, {
            method: 'POST',
            mode: "cors",
            body: payload,
            headers: {
                'Authorization': 'Bearer ' + Global.getAuthTokenCookie()
            }
        }).then(response => {
            return response.json();
        }).then(responseObject => {
            return this.promiseNormalisedResponse('creating record', responseObject)
        }).catch( (e) => {
            return this.promiseNormalisedResponse('creating record', e.message)
        });

    }

    createPerformance(perf: any) {
        //todo: sync with local version

        let payload = JSON.stringify(perf);

        let url = Global.getUrlPrefix() + 'performance';
        return fetch(url, {
            method: 'POST',
            mode: "cors",
            body: payload,
            headers: {
                'Authorization': 'Bearer ' + Global.getAuthTokenCookie()
            }
        }).then(response => {
            return response.json();
        }).then(responseObject => {
            return this.promiseNormalisedResponse('creating record', responseObject)
        }).catch((e) => {
            return this.promiseNormalisedResponse('creating record', e.message)
        });
    }

    deleteItem= (url: string) => {
        //todo: sync with local version

        return fetch(url, {
            method: 'DELETE',
            mode: "cors",
            headers: {
                'Authorization': 'Bearer ' + Global.getAuthTokenCookie()
            }
        }).then(response => {
            return response.json();
        }).then(responseObject => {
            return this.promiseNormalisedResponse('deleting record', responseObject)
        }).catch( (e) => {
            return this.promiseNormalisedResponse('deleting record', e.message)
        });


    }

    fetchAllTags() {
        //todo: sync with local version
        /**********************************************************************************
         * Go get it
         **********************************************************************************/
        let url = Global.getUrlPrefix() + 'recordtags';
        return fetch(url, {
            method: 'GET',
            mode: "cors",
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + Global.getAuthTokenCookie()
            }
        }).then(response => {
            return response.json();
        }).then(responseObject => {
            return this.promiseNormalisedResponse('loading record tags', responseObject)
        }).catch( (e) => {
            return this.promiseNormalisedResponse('loading record tags', e.message)
        });

    }
}
