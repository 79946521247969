import {Global} from "../global/Global";

import BaseDao from "./BaseDao";

export default class UploadsDao  extends BaseDao {

    constructContentObj(content: any): any {
    }
    constructDefaultObj(): any {
    }
    fetchAPI(): Promise<any> {
        return new Promise<any>(function () {
        });
    }
    pushAPI(content: any): Promise<any> {
        return new Promise<any>(function () {
        });
    }

    fetchUploads() {

        /**********************************************************************************
         * Go get it
         **********************************************************************************/
        let url = Global.getUrlPrefix() + 'attachment';

        return fetch(url, {
            method: 'GET',
            mode: "cors",
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + Global.getAuthTokenCookie()
            }
        }).then(response => {
            return response.json();
        }).then(responseObject => {
            return this.promiseNormalisedResponse('loading uploads', responseObject)
        }).catch( (e) => {
            return this.promiseNormalisedResponse('loading uploads', e.message)
        });

    }

    fetchUploadBlob(url: string) {

        /**********************************************************************************
         * Go get it
         **********************************************************************************/
        return fetch(url, {
            method: 'GET',
            mode: "cors",
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + Global.getAuthTokenCookie()
            }
        }).then(response => {
            return response.blob();
        }).then(responseObject => {
            return this.promiseNormalisedResponse('loading upload', responseObject)
        }).catch( (e) => {
            return this.promiseNormalisedResponse('loading upload', e.message)
        });

    }

    createUpload(payload: any) {

        /**********************************************************************************
         * Go get create it
         **********************************************************************************/
        let url = Global.getUrlPrefix() + 'attachment';

        return fetch(url, {
            method: 'POST',
            mode: "cors",
            body: payload,
            headers: {
                'Authorization': 'Bearer ' + Global.getAuthTokenCookie(),
            }
            }).then(response => {
            return response.blob();
        }).then(responseObject => {
            return this.promiseNormalisedResponse('creating upload', responseObject)
        }).catch( (e) => {
            return this.promiseNormalisedResponse('creating upload', e.message)
        });

    }

    deleteUpload(href: string) {

        return fetch(href, {
            method: 'DELETE',
            mode: "cors",
            headers: {
                'Authorization': 'Bearer ' + Global.getAuthTokenCookie()
            }
        }).then(response => response.json()
        ).then(responseObject => {
            return this.promiseNormalisedResponse('deleting record version', responseObject)
        }).catch( (e) => {
            return this.promiseNormalisedResponse('deleting record version', e.message)
        });
    }

    saveRecordVersionDefs(versions: any) {
        //todo: sync with local version

        let contentPart : any = {};
        contentPart.content = JSON.stringify(versions);
        let payload = JSON.stringify(contentPart);

        return fetch('', {
            method: 'PUT',
            mode: "cors",
            body: payload,
            headers: {
                'Authorization': 'Bearer ' + Global.getAuthTokenCookie()
            }
        }).then(response => {
            return response.json();
        }).then(responseObject => {
            return this.promiseNormalisedResponse('saving record version defs', responseObject)
        }).catch( (e) => {
            return this.promiseNormalisedResponse('saving record version defs', e.message)
        });

    }

    createSharedVersion( blob: Blob, versionShare : any) {

        /*---------------------------------------------------
         * First, create the attachment
         *---------------------------------------------------*/
        let content = {
            name: "Version",
            filename: versionShare.filename
        };

        //Create a multipart payload, starting with the whole record
        var payload = new FormData()
        payload.append("content", JSON.stringify(content));
        payload.append("Version", blob);

        let url = Global.getUrlPrefix() + 'attachment';

        return fetch(url, {
            method: 'POST',
            mode: "cors",
            body: payload,
            headers: {
                'Authorization': 'Bearer ' + Global.getAuthTokenCookie()
            }
        }).then(response => {
            return response.json();
        }).then(responseObject => {
            /*---------------------------------------------------
             * Next, create the version record
             *---------------------------------------------------*/
            let respContent = responseObject.content;
            versionShare["attachmentId"] = respContent.href.substring(respContent.href.lastIndexOf('/') + 1);
            versionShare["attachmentHref"] = respContent.externalHref;

            let content : any = {};
            content['tag'] = versionShare.tag ? versionShare.tag : "Not Specified";
            content['versionId'] = versionShare.versionSelected;
            content['label'] = 'Version of Record';
            content['description'] = 'PDF Version of Record';
            content['recordId'] = versionShare.recordId;
            content['dateExpired'] = versionShare.expiryDate;
            content['content'] = {code: versionShare.code};

            let jsonData = JSON.stringify(content);

            let url = Global.getUrlPrefix() + 'recordversion';

            return fetch(url, {
                method: 'POST',
                mode: "cors",
                body: jsonData,
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + Global.getAuthTokenCookie()
                }
            })
        }).then(response => {
            return response.json();
        }).then(responseObject => {
            /*---------------------------------------------------
             * And lik the attachment to the version record
             *---------------------------------------------------*/
            let responseContent = responseObject.content;

            let content : any = {};
            content['label'] = 'Version of Record';
            content['description'] = 'PDF Version of Record';
            content['recordId'] = responseContent.href.substring(responseContent.href.lastIndexOf('/') + 1);
            content['attachmentId'] = versionShare.attachmentId;

            let jsonData = JSON.stringify(content);

            let url = Global.getUrlPrefix() + 'linkattachment';

            return fetch(url, {
                method: 'POST',
                mode: "cors",
                body: jsonData,
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + Global.getAuthTokenCookie()
                }
            })
        }).then(response => {
            return response.json();
        }).then(responseObject => {
            return this.promiseNormalisedResponse('saving share version', responseObject)
        }).catch( (e) => {
            return this.promiseNormalisedResponse('saving share version', e.message)
        });
    }

    fetchRecordVersions(id: string){
        let url = Global.getUrlPrefix() + 'recordversion?id=' + id;

        return fetch(url, {
            method: 'GET',
            mode: "cors",
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + Global.getAuthTokenCookie()
            }
        }).then(response => response.json()).then(responseObject => {

            //go get all of the individual versions
            let promises = responseObject.content.map((item : any) => fetch(item.href, {
                method: 'GET',
                mode: "cors",
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + Global.getAuthTokenCookie()
                }
            }).then(response => response.json()));

            //wait for them all to complete
            return Promise.all(promises)
        }).then(results => {
            return this.promiseNormalisedResponse('loading record versions', results)
        }).catch( (e) => {
            return this.promiseNormalisedResponse('loading record versions', e.message)
        });
    }

    deleteRecordVersion(version: any) {
        let url = version.attachments[0].deleteHref;

        return fetch(url, {
            method: 'DELETE',
            mode: "cors",
            headers: {
                'Authorization': 'Bearer ' + Global.getAuthTokenCookie()
            }
        }).then(response => response.json()
        ).then(responseObject => {
            let url = version.attachments[0].attachment.href;
            return fetch(url, {
                method: 'DELETE',
                mode: "cors",
                headers: {
                    'Authorization': 'Bearer ' + Global.getAuthTokenCookie()
                }
            })
        }).then(response => response.json()
        ).then(responseObject => {
            let url = version.href;
            return fetch(url, {
                method: 'DELETE',
                mode: "cors",
                headers: {
                    'Authorization': 'Bearer ' + Global.getAuthTokenCookie()
                }
            })
        }).then(results => {
            return this.promiseNormalisedResponse('deleting record version', results)
        }).catch( (e) => {
            return this.promiseNormalisedResponse('deleting record version', e.message)
        });
    }

    updateRecordVersion(version: any, payload: any) {
        let url = version.href;

        return fetch(url, {
            method: 'PUT',
            mode: "cors",
            body: payload,
            headers: {
                'Authorization': 'Bearer ' + Global.getAuthTokenCookie()
            }
        }).then(response => {
            return response.json();
        }).then(results => {
            return this.promiseNormalisedResponse('updating record version', results)
        }).catch( (e) => {
            return this.promiseNormalisedResponse('updfating record version', e.message)
        });
    }

}
