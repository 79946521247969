import * as React from 'react';
import * as ReactStrap from 'reactstrap';
import classnames from 'classnames';
import cat from "../assets/cat.jpg";
import * as ReactRouter from "react-router-dom";
import VersionsEdit from './VersionsEdit';
import {FaEnvelope, FaHome, FaUser, FaTransgenderAlt, FaPhone, FaChild, FaPaw} from "react-icons/fa";
import {MdLocalHospital, MdContactPhone, MdPeople, MdCheck, MdClear} from 'react-icons/md';
import {GoAlert, GoCalendar} from 'react-icons/go';
import {TiThumbsUp, TiThumbsDown} from 'react-icons/ti';
import {IoIosTrendingUp, IoIosBulb, IoIosPeople, IoIosHappy, IoIosAlert} from 'react-icons/io';
import * as MobX from "mobx-react";
import {InterfaceStore} from "../store/InterfaceStore";
import {RecordkeepingStore} from "../store/RecordkeepingStore";
import Uploads from "./Uploads";

class BioRecordEdit extends React.Component <any, any> {
    toggle : any;

    readonly ifStore : InterfaceStore;
    readonly rkStore : RecordkeepingStore;

    constructor(props : any) {
        super(props);

        this.state = {
            updateStatus: '',
            pictureSrc: '',

            activeTab: '1',

            pickerModal: false,
        };

        this.ifStore = this.props.stores.ifStore;
        this.ifStore.message = null; // to prevent the message being shown when switching page

        this.rkStore = this.props.stores.rkStore;
    }

    toggleTab = (tab : any) => {
        if (this.state.activeTab !== tab) {
            this.setState({
                activeTab: tab
            });
        }
    }

    togglePickerModal = () => {
        this.setState((prevState : any) => ({
            pickerModal: !prevState.pickerModal,
        }));
    }

    handlePictureSelect = (href:any) => {
        this.props.handleAttachmentSelect(href);
        this.togglePickerModal();

        //dummy up a field value change so the field versions stuff works properly
        let event = {
            target: {
                type: 'foo',
                name: 'image',
                value: 'image'
            }
        }

        this.props.handleFieldChange(event)
    }

    clearPicture = () => {
        this.props.handleAttachmentSelect('clear')
    }

    render() {
        let pictureSrc = this.rkStore.attachment ? this.rkStore.attachment : cat;
         return (
             <ReactStrap.Container fluid={true}>
                
                <ReactStrap.Row>
                    <ReactStrap.Col sm={12}>
                        <p>Enter in your personal details. Remember that it is up to you what info you want to store. Everything here is optional.</p>
                    </ReactStrap.Col>
                </ReactStrap.Row>

                    <ReactStrap.Nav tabs>
                        <ReactStrap.NavItem>
                            <ReactStrap.NavLink
                                className={classnames({ active: this.state.activeTab === '1' })}
                                onClick={() => { this.toggleTab('1'); }}
                            >
                                Essentials
                            </ReactStrap.NavLink>
                        </ReactStrap.NavItem>
                        <ReactStrap.NavItem>
                            <ReactStrap.NavLink
                                className={classnames({ active: this.state.activeTab === '2' })}
                                onClick={() => { this.toggleTab('2'); }}
                            >
                                About me
                            </ReactStrap.NavLink>
                        </ReactStrap.NavItem>
                        <ReactStrap.NavItem>
                            <ReactStrap.NavLink
                                className={classnames({ active: this.state.activeTab === '3' })}
                                onClick={() => { this.toggleTab('3'); }}
                            >
                                Family, Friends, and Pets
                            </ReactStrap.NavLink>
                        </ReactStrap.NavItem>
                        <ReactStrap.NavItem>
                            <ReactStrap.NavLink
                                className={classnames({ active: this.state.activeTab === '4' })}
                                onClick={() => { this.toggleTab('4'); }}
                            >
                                Interacting With Me
                            </ReactStrap.NavLink>
                        </ReactStrap.NavItem>
                    </ReactStrap.Nav>

                    <ReactStrap.TabContent activeTab={this.state.activeTab}>
                        <ReactStrap.TabPane tabId="1">
                            <ReactStrap.FormGroup row className={'m-2'}>
                                <ReactStrap.Col sm={2}>
                                    <img src={pictureSrc} alt="personal representation" className="rounded-circle" width="120" height="120" />
                                </ReactStrap.Col>
                                <ReactStrap.Col sm={8}>
                                    <ReactStrap.Button onClick={this.togglePickerModal}>Choose Picture</ReactStrap.Button>
                                    <ReactStrap.Button color={'link'} onClick={this.clearPicture}>Remove picture</ReactStrap.Button>
                                </ReactStrap.Col>

                                <ReactStrap.Col sm={2}>
                                    <VersionsEdit name={'image'} onChange={this.props.handleVersionsChange}/>
                                </ReactStrap.Col>
                            </ReactStrap.FormGroup>

                            <ReactStrap.FormGroup row className={'m-2'}>
                                <ReactStrap.Label sm={2}>
                                    <FaUser /> Full Name
                                </ReactStrap.Label>
                                <ReactStrap.Col sm={8}>
                                    <ReactStrap.Input type="text" id="fullname" name="fullname" placeholder="Full Name" value={this.props.getFieldValue('fullname')} onChange={this.props.handleFieldChange}/>
                                </ReactStrap.Col>
                                <ReactStrap.Col sm={2}>
                                    <VersionsEdit name={'fullname'} onChange={this.props.handleVersionsChange}/>
                                </ReactStrap.Col>
                            </ReactStrap.FormGroup>

                            <ReactStrap.FormGroup row className={'m-2'}>
                                <ReactStrap.Label sm={2}>
                                    <FaUser /> Preferred Name
                                </ReactStrap.Label>
                                <ReactStrap.Col sm={8}>
                                    <ReactStrap.Input type="text" id="prefname" name="prefname" placeholder="Preferred Name" value={this.props.getFieldValue('prefname')} onChange={this.props.handleFieldChange}/>
                                </ReactStrap.Col>
                                <ReactStrap.Col sm={2}>
                                    <VersionsEdit name={'prefname'} onChange={this.props.handleVersionsChange}/>
                                </ReactStrap.Col>
                            </ReactStrap.FormGroup>

                            <ReactStrap.FormGroup row className={'m-2'}>
                                <ReactStrap.Label sm={2}>
                                    <FaTransgenderAlt /> Gender
                                </ReactStrap.Label>
                                <ReactStrap.Col sm={8}>
                                    <ReactStrap.CustomInput type="select" id="gender" name="gender" placeholder="Gender" value={this.props.getFieldValue('gender')} onChange={this.props.handleFieldChange}>
                                        <option value="">Select</option>
                                        <option value="f">Female</option>
                                        <option value="m">Male</option>
                                        <option value="o">Other</option>
                                        <option value="p">Prefer not to say</option>
                                    </ReactStrap.CustomInput>
                                </ReactStrap.Col>
                                <ReactStrap.Col sm={2}>
                                    <VersionsEdit name={'gender'} onChange={this.props.handleVersionsChange}/>
                                </ReactStrap.Col>
                            </ReactStrap.FormGroup>

                            <ReactStrap.FormGroup row className={'m-2'}>
                                <ReactStrap.Label sm={2}>
                                    <GoCalendar /> Date of Birth
                                </ReactStrap.Label>
                                <ReactStrap.Col sm={8}>
                                    <ReactStrap.Input type="date" id="age" name="age" placeholder="Date of Birth Calendar Picker" value={this.props.getFieldValue('age')} onChange={this.props.handleFieldChange}/>
                                </ReactStrap.Col>
                                <ReactStrap.Col sm={2}>

                                    <VersionsEdit name={'age'} onChange={this.props.handleVersionsChange}/>
                                </ReactStrap.Col>
                            </ReactStrap.FormGroup>

                            <ReactStrap.FormGroup row className={'m-2'}>
                                <ReactStrap.Label sm={2}>
                                    <MdContactPhone /> Contacting Me
                                </ReactStrap.Label>
                                <ReactStrap.Col sm={8}>
                                    <ReactStrap.Input type="textarea" id="contactme" name="contactme" placeholder="How I like to be contacted, availability etc." value={this.props.getFieldValue('contactme')} onChange={this.props.handleFieldChange}/>
                                </ReactStrap.Col>
                                <ReactStrap.Col sm={2}>
                                    <VersionsEdit name={'contactme'} onChange={this.props.handleVersionsChange}/>
                                </ReactStrap.Col>
                            </ReactStrap.FormGroup>

                            <ReactStrap.FormGroup row className={'m-2'}>
                                <ReactStrap.Label sm={2}>
                                    <FaPhone /> Phone Number
                                </ReactStrap.Label>
                                <ReactStrap.Col sm={8}>
                                    <ReactStrap.Input type="text" id="phone" name="phone" placeholder="Phone Number" value={this.props.getFieldValue('phone')} onChange={this.props.handleFieldChange}/>
                                </ReactStrap.Col>
                                <ReactStrap.Col sm={2}>
                                    <VersionsEdit name={'phone'} onChange={this.props.handleVersionsChange}/>
                                </ReactStrap.Col>
                            </ReactStrap.FormGroup>

                            <ReactStrap.FormGroup row className={'m-2'}>
                                <ReactStrap.Label sm={2}>
                                    <FaEnvelope /> Email Address
                                </ReactStrap.Label>
                                <ReactStrap.Col sm={8}>
                                    <ReactStrap.Input type="email" id="email" name="email" placeholder="Email" value={this.props.getFieldValue('email')} onChange={this.props.handleFieldChange}/>
                                </ReactStrap.Col>
                                <ReactStrap.Col sm={2}>
                                    <VersionsEdit name={'email'} onChange={this.props.handleVersionsChange}/>
                                </ReactStrap.Col>
                            </ReactStrap.FormGroup>

                            <ReactStrap.FormGroup row className={'m-2'}>
                                <ReactStrap.Label sm={2}>
                                    <FaHome /> Address
                                </ReactStrap.Label>
                                <ReactStrap.Col sm={8}>
                                    <ReactStrap.Input type="text" id="address" name="address" placeholder="Address" value={this.props.getFieldValue('address')} onChange={this.props.handleFieldChange}/>
                                </ReactStrap.Col>
                                <ReactStrap.Col sm={2}>
                                    <VersionsEdit name={'address'} onChange={this.props.handleVersionsChange}/>
                                </ReactStrap.Col>
                            </ReactStrap.FormGroup>

                            <ReactStrap.FormGroup row className={'m-2'}>
                                <ReactStrap.Label sm={2}>
                                    <GoAlert /> Emerg. Contact
                                </ReactStrap.Label>
                                <ReactStrap.Col sm={8}>
                                    <ReactStrap.Input type="text" id="emergcontact" name="emergcontact" placeholder="Emergency Contact" value={this.props.getFieldValue('emergcontact')} onChange={this.props.handleFieldChange}/>
                                </ReactStrap.Col>
                                <ReactStrap.Col sm={2}>
                                    <VersionsEdit name={'emergcontact'} onChange={this.props.handleVersionsChange}/>
                                </ReactStrap.Col>
                            </ReactStrap.FormGroup>
                        </ReactStrap.TabPane>

                        <ReactStrap.TabPane tabId="2">
                            <ReactStrap.FormGroup row className={'m-2'}>
                                <ReactStrap.Label sm={2}>
                                    <FaChild /> My Story
                                </ReactStrap.Label>
                                <ReactStrap.Col sm={8}>
                                    <ReactStrap.Input type="textarea" id="story" name="story" placeholder="My Story" value={this.props.getFieldValue('story')} onChange={this.props.handleFieldChange}/>
                                </ReactStrap.Col>
                                <ReactStrap.Col sm={2}>
                                    <VersionsEdit name={'story'} onChange={this.props.handleVersionsChange}/>
                                </ReactStrap.Col>
                            </ReactStrap.FormGroup>

                            <ReactStrap.FormGroup row className={'m-2'}>
                                <ReactStrap.Label sm={2}>
                                    <IoIosTrendingUp /> My Goals
                                </ReactStrap.Label>
                                <ReactStrap.Col sm={8}>
                                    <ReactStrap.Input type="textarea" id="goals" name="goals" placeholder="My Goals" value={this.props.getFieldValue('goals')} onChange={this.props.handleFieldChange}/>
                                </ReactStrap.Col>
                                <ReactStrap.Col sm={2}>
                                    <VersionsEdit name={'goals'} onChange={this.props.handleVersionsChange}/>
                                </ReactStrap.Col>
                            </ReactStrap.FormGroup>

                            <ReactStrap.FormGroup row className={'m-2'}>
                                <ReactStrap.Label sm={2}>
                                    <MdLocalHospital /> My Health
                                </ReactStrap.Label>
                                <ReactStrap.Col sm={8}>
                                    <ReactStrap.Input type="textarea" id="health" name="health" placeholder="My health" value={this.props.getFieldValue('health')} onChange={this.props.handleFieldChange}/>
                                </ReactStrap.Col>
                                <ReactStrap.Col sm={2}>
                                    <VersionsEdit name={'health'} onChange={this.props.handleVersionsChange}/>
                                </ReactStrap.Col>
                            </ReactStrap.FormGroup>

                            <ReactStrap.FormGroup row className={'m-2'}>
                                <ReactStrap.Label sm={2}>
                                    <IoIosBulb /> My Interests
                                </ReactStrap.Label>
                                <ReactStrap.Col sm={8}>
                                    <ReactStrap.Input type="textarea" id="interests" name="interests" placeholder="My interests" value={this.props.getFieldValue('interests')} onChange={this.props.handleFieldChange}/>
                                </ReactStrap.Col>
                                <ReactStrap.Col sm={2}>
                                    <VersionsEdit name={'interests'} onChange={this.props.handleVersionsChange}/>
                                </ReactStrap.Col>
                            </ReactStrap.FormGroup>
                        </ReactStrap.TabPane>

                        <ReactStrap.TabPane tabId="3">
                            <ReactStrap.FormGroup row className={'m-2'}>
                                <ReactStrap.Label sm={2}>
                                    <IoIosPeople /> About my family
                                </ReactStrap.Label>
                                <ReactStrap.Col sm={8}>
                                    <ReactStrap.Input type="textarea" id="family" name="family" placeholder="About my family" value={this.props.getFieldValue('family')} onChange={this.props.handleFieldChange}/>
                                </ReactStrap.Col>
                                <ReactStrap.Col sm={2}>
                                    <VersionsEdit name={'family'} onChange={this.props.handleVersionsChange}/>
                                </ReactStrap.Col>
                            </ReactStrap.FormGroup>

                            <ReactStrap.FormGroup row className={'m-2'}>
                                <ReactStrap.Label sm={2}>
                                    <MdPeople /> My friends
                                </ReactStrap.Label>
                                <ReactStrap.Col sm={8}>
                                    <ReactStrap.Input type="textarea" id="friends" name="friends" placeholder="About my friends" value={this.props.getFieldValue('friends')} onChange={this.props.handleFieldChange}/>
                                </ReactStrap.Col>
                                <ReactStrap.Col sm={2}>
                                    <VersionsEdit name={'friends'} onChange={this.props.handleVersionsChange}/>
                                </ReactStrap.Col>
                            </ReactStrap.FormGroup>

                            <ReactStrap.FormGroup row className={'m-2'}>
                                <ReactStrap.Label sm={2}>
                                    <FaPaw /> My pets
                                </ReactStrap.Label>
                                <ReactStrap.Col sm={8}>
                                    <ReactStrap.Input type="textarea" id="pets" name="pets" placeholder="Pets" value={this.props.getFieldValue('pets')} onChange={this.props.handleFieldChange}/>
                                </ReactStrap.Col>
                                <ReactStrap.Col sm={2}>
                                    <VersionsEdit name={'pets'} onChange={this.props.handleVersionsChange}/>
                                </ReactStrap.Col>
                            </ReactStrap.FormGroup>
                        </ReactStrap.TabPane>

                        <ReactStrap.TabPane tabId="4">
                            <ReactStrap.FormGroup row className={'m-2'}>
                                <ReactStrap.Label sm={2}>
                                    <TiThumbsUp /> Things I like
                                </ReactStrap.Label>
                                <ReactStrap.Col sm={8}>
                                    <ReactStrap.Input type="textarea" id="likes" name="likes" placeholder="My likes" value={this.props.getFieldValue('likes')} onChange={this.props.handleFieldChange}/>
                                </ReactStrap.Col>
                                <ReactStrap.Col sm={2}>
                                    <VersionsEdit name={'likes'} onChange={this.props.handleVersionsChange}/>
                                </ReactStrap.Col>
                            </ReactStrap.FormGroup>

                            <ReactStrap.FormGroup row className={'m-2'}>
                                <ReactStrap.Label sm={2}>
                                    <TiThumbsDown /> Things I don't like
                                </ReactStrap.Label>
                                <ReactStrap.Col sm={8}>
                                    <ReactStrap.Input type="textarea" id="dislikes" name="dislikes" placeholder="My dislikes" value={this.props.getFieldValue('dislikes')} onChange={this.props.handleFieldChange}/>
                                </ReactStrap.Col>
                                <ReactStrap.Col sm={2}>
                                    <VersionsEdit name={'dislikes'} onChange={this.props.handleVersionsChange}/>
                                </ReactStrap.Col>
                            </ReactStrap.FormGroup>

                            <ReactStrap.FormGroup row className={'m-2'}>
                                <ReactStrap.Label sm={2}>
                                    <IoIosAlert /> What upsets me
                                </ReactStrap.Label>
                                <ReactStrap.Col sm={8}>
                                    <ReactStrap.Input type="textarea" id="triggers" name="triggers" placeholder="My triggers" value={this.props.getFieldValue('triggers')} onChange={this.props.handleFieldChange}/>
                                </ReactStrap.Col>
                                <ReactStrap.Col sm={2}>
                                    <VersionsEdit name={'triggers'} onChange={this.props.handleVersionsChange}/>
                                </ReactStrap.Col>
                            </ReactStrap.FormGroup>

                            <ReactStrap.FormGroup row className={'m-2'}>
                                <ReactStrap.Label sm={2}>
                                    <IoIosHappy /> What calms me down
                                </ReactStrap.Label>
                                <ReactStrap.Col sm={8}>
                                    <ReactStrap.Input type="textarea" id="calms" name="calms" placeholder="Things that I find calming" value={this.props.getFieldValue('calms')} onChange={this.props.handleFieldChange}/>
                                </ReactStrap.Col>
                                <ReactStrap.Col sm={2}>
                                    <VersionsEdit name={'calms'} onChange={this.props.handleVersionsChange}/>
                                </ReactStrap.Col>
                            </ReactStrap.FormGroup>

                            <ReactStrap.FormGroup row className={'m-2'}>
                                <ReactStrap.Label sm={2}>
                                    <MdCheck /> Do these things
                                </ReactStrap.Label>
                                <ReactStrap.Col sm={8}>
                                    <ReactStrap.Input type="textarea" id="dos" name="dos" placeholder="Dos" value={this.props.getFieldValue('dos')} onChange={this.props.handleFieldChange}/>
                                </ReactStrap.Col>
                                <ReactStrap.Col sm={2}>
                                    <VersionsEdit name={'dos'} onChange={this.props.handleVersionsChange}/>
                                </ReactStrap.Col>
                            </ReactStrap.FormGroup>

                            <ReactStrap.FormGroup row className={'m-2'}>
                                <ReactStrap.Label sm={2}>
                                    <MdClear /> Don't do these things
                                </ReactStrap.Label>
                                <ReactStrap.Col sm={8}>
                                    <ReactStrap.Input type="textarea" id="donts" name="donts" placeholder="Don'ts" value={this.props.getFieldValue('donts')} onChange={this.props.handleFieldChange}/>
                                </ReactStrap.Col>
                                <ReactStrap.Col sm={2}>
                                    <VersionsEdit name={'donts'} onChange={this.props.handleVersionsChange}/>
                                </ReactStrap.Col>
                            </ReactStrap.FormGroup>
                        </ReactStrap.TabPane>
                    </ReactStrap.TabContent>

                 <ReactStrap.Modal isOpen={this.state.pickerModal} toggle={this.togglePickerModal}>
                     <ReactStrap.ModalHeader toggle={this.toggle}>Choose Picture</ReactStrap.ModalHeader>
                     <ReactStrap.ModalBody>
                         <Uploads types={'images'} callback={this.props.callback} selectCallback={this.handlePictureSelect}/>
                     </ReactStrap.ModalBody>
                     <ReactStrap.ModalFooter>
                         <ReactStrap.Button color="secondary" onClick={this.togglePickerModal}>Cancel</ReactStrap.Button>
                     </ReactStrap.ModalFooter>
                 </ReactStrap.Modal>

              </ReactStrap.Container>
        );
    }
}
export default MobX.inject('stores')(ReactRouter.withRouter(MobX.observer(BioRecordEdit)));