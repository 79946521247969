import * as React from 'react';
import * as ReactStrap from 'reactstrap';
import * as ReactRouter from "react-router-dom";
import {FaFolderOpen} from "react-icons/fa";
import * as MobX from "mobx-react";
import {InterfaceStore} from "../store/InterfaceStore";
import {RecordkeepingStore} from "../store/RecordkeepingStore";

class RecCatTypWidget extends React.Component <any, any> {
    readonly ifStore : InterfaceStore;
    readonly rkStore : RecordkeepingStore;


    constructor(props : any) {
        super(props);

        this.ifStore = this.props.stores.ifStore;
        this.ifStore.message = null; // to prevent the message being shown when switching page

        this.rkStore = this.props.stores.rkStore;

    }

    handleFieldChange = (event : any) => {
        let value =  event.target.value;
        this.setState({
            cattype: value
        });

        let parts = value.split('/')

        let myEvent:any = {
            target: {},
        }

        myEvent.target.name = 'recordCategory';
        myEvent.target.value = parts[0];
        this.props.handleFieldChange(myEvent);

        myEvent.target.name = 'recordType';
        myEvent.target.value = parts[1];
        this.props.handleFieldChange(myEvent);
    }

    render() {
         return (
             <ReactStrap.FormGroup row className={'m-2'}>
                 <ReactStrap.Label sm={2}>
                     <FaFolderOpen /> Record Type <span style={{ color: 'red' }}>*</span>
                 </ReactStrap.Label>
                 <ReactStrap.Col sm={8}>
                     <ReactStrap.CustomInput type="select" id="cattype" name="cattype" value={this.rkStore.recordCatType} onChange={this.handleFieldChange}>
                         <option >- Please Select One -</option>
                         <option value="Care/Case Plan">Care - Case Plan</option>
                         <option value="Care/Incident Report">Care - Incident report</option>
                         <option value="Care/Leaving Care Plan">Care - Leaving care plan</option>
                         <option value="Care/Placement Referral">Care - Placement referral</option>
                         <option value="Care/Other">Care - Other</option>
                         <option value="Civic/Birth Certificate">Civic - Birth certificate</option>
                         <option value="Civic/Driver's License">Civic - Driver's licence</option>
                         <option value="Civic/Other">Civic - Other</option>
                         <option value="Court/Report">Court - Report</option>
                         <option value="Court/Other">Court - Other</option>
                         <option value="Education/Education Plan">Education - Education plan</option>
                         <option value="Education/School Report">Education - School report</option>
                         <option value="Education/Form">Education - Form</option>
                         <option value="Education/Other">Education - Other</option>
                         <option value="Health/Plan">Health - Plan</option>
                         <option value="Health/health Care Card">Health - Heath care card</option>
                         <option value="Health/Test results">Health - Test results</option>
                         <option value="Health/Checkup">Health - Check up</option>
                         <option value="Health/Procedure">Health - Procedure</option>
                         <option value="Health/Assessment">Health - Assessment</option>
                         <option value="Health/Medicare Card">Health - Medicare card</option>
                         <option value="Health/Referral">Health - Referral</option>
                         <option value="Health/Certificate">Health - Certificate</option>
                         <option value="Health/Other">Health - Other</option>
                         <option value="Personal/Bio">Personal - Bio</option>
                         <option value="Personal/Meeting Note">Personal - Meeting note</option>
                         <option value="Personal/Other">Personal - Other</option>

                     </ReactStrap.CustomInput>
                 </ReactStrap.Col>
             </ReactStrap.FormGroup>
        );
    }
}
export default MobX.inject('stores')(ReactRouter.withRouter(MobX.observer(RecCatTypWidget)));