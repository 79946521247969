import * as React from 'react';
import * as ReactStrap from 'reactstrap';
import * as ReactRouter from "react-router-dom";
import VersionsEdit from './VersionsEdit';
import {FaFolder, FaRegCalendarAlt, FaRegCalendarCheck, FaStickyNote, FaTags} from "react-icons/fa";
import * as MobX from "mobx-react";
import {InterfaceStore} from "../store/InterfaceStore";
import {RecordkeepingStore} from "../store/RecordkeepingStore";

import {Global} from "../global/Global";
import TagWidget from "./TagWidget";
import {IoIosMenu} from "react-icons/all";
import {OrgDataStore} from "../store/OrgDataStore";

class RegisteredRecordEdit extends React.Component <any, any> {
    toggle : any;

    readonly ifStore : InterfaceStore;
    readonly rkStore : RecordkeepingStore;
    readonly odStore : OrgDataStore;

    constructor(props : any) {
        super(props);

        this.ifStore = this.props.stores.ifStore;
        this.ifStore.message = null; // to prevent the message being shown when switching page

        this.rkStore = this.props.stores.rkStore;
        this.odStore = this.props.stores.odStore;
    }

    render() {
         return (
             <ReactStrap.Container fluid={true}>
                
                <ReactStrap.Row>
                    <ReactStrap.Col sm={12}>
                        <p>Enter the details of this record. Remember that it is up to you what info you want to change. Everything here is optional.</p>
                    </ReactStrap.Col>
                </ReactStrap.Row>

                 <ReactStrap.Row>
                     <ReactStrap.Label >
                         <b>Original Label</b> : {this.rkStore.record.label}
                     </ReactStrap.Label>
                 </ReactStrap.Row>

                <ReactStrap.FormGroup row className={'m-2'}>
                    <ReactStrap.Label sm={2}>
                        <FaFolder /> My Label
                    </ReactStrap.Label>
                    <ReactStrap.Col sm={10}>
                        <ReactStrap.Input type="text" id="mylabel" name="mylabel" placeholder="My label" value={this.props.getFieldValue('mylabel')} onChange={this.props.handleFieldChange}/>
                    </ReactStrap.Col>
                </ReactStrap.FormGroup>

                 <ReactStrap.Row>
                     <ReactStrap.Label >
                         <b>Original Date</b> : {Global.getDateString(this.rkStore.record.citeDate,this.rkStore.record.citeDateAccuracy)}
                     </ReactStrap.Label>
                 </ReactStrap.Row>

                 <ReactStrap.FormGroup row className={'m-2'}>
                     <ReactStrap.Label sm={2}>
                         <FaRegCalendarAlt /> My Date
                     </ReactStrap.Label>
                     <ReactStrap.Col sm={10}>
                         <ReactStrap.Input type="date" id="mydate" name="mydate" placeholder="My date" value={this.props.getFieldValue('mydate')} onChange={this.props.handleFieldChange}/>
                     </ReactStrap.Col>

                     <ReactStrap.Label sm={2}>
                         <FaRegCalendarCheck /> My Date Accuracy
                     </ReactStrap.Label>
                     <ReactStrap.Col sm={10}>
                         <ReactStrap.CustomInput type="select" id="mydateaccuracy" name="mydateaccuracy" placeholder="Accuracy" value={this.props.getFieldValue('mydateaccuracy')} onChange={this.props.handleFieldChange}>
                             <option value="1">This exact day</option>
                             <option value="2">This month</option>
                             <option value="3">This year</option>
                         </ReactStrap.CustomInput>
                     </ReactStrap.Col>
                 </ReactStrap.FormGroup>

                 <ReactStrap.Row>
                     <ReactStrap.Label >
                         <b>Original Description</b> : {this.rkStore.record.description}
                     </ReactStrap.Label>
                 </ReactStrap.Row>

                 <ReactStrap.FormGroup row className={'m-2'}>
                     <ReactStrap.Label sm={2}>
                         <IoIosMenu /> My Description
                     </ReactStrap.Label>
                     <ReactStrap.Col sm={10}>
                         <ReactStrap.Input type="textarea" id="mydescription" name="mydescription" placeholder="My description" value={this.props.getFieldValue('mydescription')} onChange={this.props.handleFieldChange}/>
                     </ReactStrap.Col>
                 </ReactStrap.FormGroup>

                 <ReactStrap.FormGroup row className={'m-2'}>
                     <ReactStrap.Label sm={2}>
                         <FaStickyNote /> My Notes
                     </ReactStrap.Label>
                     <ReactStrap.Col sm={8}>
                         <ReactStrap.Input type="textarea" id="notes" name="notes" placeholder="My notes" value={this.props.getFieldValue('notes')} onChange={this.props.handleFieldChange}/>
                     </ReactStrap.Col>
                     <ReactStrap.Col sm={2}>
                         <VersionsEdit name={'notes'} onChange={this.props.handleVersionsChange}/>
                     </ReactStrap.Col>
                 </ReactStrap.FormGroup>

                 <ReactStrap.FormGroup row className={'m-2'}>
                     <ReactStrap.Label sm={2}>
                         <FaTags/> Tags:
                     </ReactStrap.Label>
                     <ReactStrap.Col sm={8}>
                         <TagWidget handleFieldChange={this.props.handleFieldChange}/>
                     </ReactStrap.Col>
                     <ReactStrap.Col sm={2}>
                         <VersionsEdit name={'tags'} onChange={this.props.handleVersionsChange}/>
                     </ReactStrap.Col>
                 </ReactStrap.FormGroup>

              </ReactStrap.Container>
        );
    }
}
export default MobX.inject('stores')(ReactRouter.withRouter(MobX.observer(RegisteredRecordEdit)));