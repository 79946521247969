import * as React from 'react';
import * as ReactStrap from 'reactstrap';
import * as Emoji from 'emoji-mart';
import {RecordkeepingStore} from "../store/RecordkeepingStore";
import * as MobX from "mobx-react";
import * as ReactRouter from "react-router-dom";

class VersionsEdit extends React.Component <any, any> {
    readonly rkStore : RecordkeepingStore;

    constructor(props : any) {
        super(props);

        this.rkStore = this.props.stores.rkStore;
    }

    onRadioBtnClick = (selected : any) => {
        this.props.onChange(this.props.name, selected)
    }

    isValidVersion = (set : any) => {
        return this.rkStore.versionDefs[set] && this.rkStore.versionDefs[set].label && this.rkStore.versionDefs[set].icon !== "question"
    }

    render() {
        //let itemVersions = this.rkStore.getVersionsForItem(this.props.name)
        let itemVersions = this.rkStore.recordInstanceContent[this.props.name] ? this.rkStore.recordInstanceContent[this.props.name]['versions']  : []
        return (
            <div >
                <ReactStrap.ButtonGroup>
                    {itemVersions && this.isValidVersion("set0") &&
                    <ReactStrap.Button outline color="success" size="sm" onClick={() => this.onRadioBtnClick('set0')} active={itemVersions.includes('set0')}>
                        <Emoji.Emoji emoji={this.rkStore.versionDefs.set0.icon} size={20}/>
                    </ReactStrap.Button>
                    }
                    {itemVersions && this.isValidVersion("set1") &&
                    <ReactStrap.Button outline color="success" size="sm" onClick={() => this.onRadioBtnClick('set1')} active={itemVersions.includes('set1')}>
                        <Emoji.Emoji emoji={this.rkStore.versionDefs.set1.icon} size={20}/>
                    </ReactStrap.Button>
                    }
                    {itemVersions && this.isValidVersion("set2") &&
                    <ReactStrap.Button outline color="success" size="sm" onClick={() => this.onRadioBtnClick('set2')} active={itemVersions.includes('set2')}>
                        <Emoji.Emoji emoji={this.rkStore.versionDefs.set2.icon} size={20}/>
                    </ReactStrap.Button>
                    }
                    {itemVersions && this.isValidVersion("set3") &&
                    <ReactStrap.Button outline color="success" size="sm" onClick={() => this.onRadioBtnClick('set3')} active={itemVersions.includes('set3')}>
                        <Emoji.Emoji emoji={this.rkStore.versionDefs.set3.icon} size={20}/>
                    </ReactStrap.Button>
                    }
                    {itemVersions && this.isValidVersion("set4") &&
                    <ReactStrap.Button outline color="success" size="sm" onClick={() => this.onRadioBtnClick('set4')} active={itemVersions.includes('set4')}>
                        <Emoji.Emoji emoji={this.rkStore.versionDefs.set4.icon} size={20}/>
                    </ReactStrap.Button>
                    }
                    {itemVersions && this.isValidVersion("set5") &&
                    <ReactStrap.Button outline color="success" size="sm" onClick={() => this.onRadioBtnClick('set5')} active={itemVersions.includes('set5')}>
                        <Emoji.Emoji emoji={this.rkStore.versionDefs.set5.icon} size={20}/>
                    </ReactStrap.Button>
                    }
                </ReactStrap.ButtonGroup>
            </div>
        );
    }
}

export default MobX.inject('stores')(ReactRouter.withRouter(MobX.observer(VersionsEdit)));