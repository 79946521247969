import * as React from 'react';
import * as ReactStrap from 'reactstrap';
import * as ReactRouter from "react-router-dom";
import * as MobX from "mobx-react";

import rhiannon from '../assets/rhiannon.jpg';
import aedan    from '../assets/aedan.jpg';
import liz      from '../assets/liz.jpg';
import joanne   from '../assets/joanne.png';
import matt     from '../assets/matt.jpg';
import han      from '../assets/han.png';
import ella     from '../assets/ella.jpg';
import greg     from '../assets/greg.jpg';
import {Global} from "../global/Global";

class Credits extends React.Component <any, any> {

    render() {
        if (Global.getOfflineAuthCookie() === false && Global.getOnlineAuthCookie() === false) {
            //alert ('You are not login!!!');
            return <ReactRouter.Redirect to="/login"/>;
        }
        return (
            <ReactStrap.Container>
                <ReactStrap.Row style={{padding: "10px"}}>
                    <ReactStrap.Col sm={12}>
                        <strong>MySSy</strong> was developed as part of the <a href={'https://rights-records.it.monash.edu/research-development-agenda/rights-in-records-by-design/'}><em>Rights in Records by Design </em></a>a
                        research project, conducted at the Faculty of Information Technology, Monash University, Melbourne Australia.
                    </ReactStrap.Col>
                </ReactStrap.Row>

                <ReactStrap.Row style={{padding: "10px"}}>
                    <ReactStrap.Col sm={12}>
                        The system co-designers were:
                    </ReactStrap.Col>
                </ReactStrap.Row>

                <ReactStrap.Row style={{padding: "10px"}}>
                    <ReactStrap.Col sm={2}>
                        <img height={100} src={rhiannon} alt={''}/>
                    </ReactStrap.Col>
                    <ReactStrap.Col sm={9}>
                        <ReactStrap.Label>Rhiannon Abeling</ReactStrap.Label>
                    </ReactStrap.Col>
                </ReactStrap.Row>

                <ReactStrap.Row style={{padding: "10px"}}>
                    <ReactStrap.Col sm={2}>
                        <img height={100} src={aedan} alt={''}/>
                    </ReactStrap.Col>
                    <ReactStrap.Col sm={10}>
                        <ReactStrap.Label>Aedan Brittain</ReactStrap.Label>
                    </ReactStrap.Col>
                </ReactStrap.Row>

                <ReactStrap.Row style={{padding: "10px"}}>
                    <ReactStrap.Col sm={2}>
                        <img height={100} src={liz} alt={''}/>
                    </ReactStrap.Col>
                    <ReactStrap.Col sm={9}>
                        <ReactStrap.Label>Elizabeth Constable</ReactStrap.Label>
                    </ReactStrap.Col>
                </ReactStrap.Row>

                <ReactStrap.Row style={{padding: "10px"}}>
                    <ReactStrap.Col sm={2}>
                        <img height={100} src={joanne} alt={''}/>
                    </ReactStrap.Col>
                    <ReactStrap.Col sm={9}>
                        <ReactStrap.Label>Joanne Evans</ReactStrap.Label>
                    </ReactStrap.Col>
                </ReactStrap.Row>

                <ReactStrap.Row style={{padding: "10px"}}>
                    <ReactStrap.Col sm={2}>
                        <img height={100} src={matt} alt={''}/>
                    </ReactStrap.Col>
                    <ReactStrap.Col sm={9}>
                        <ReactStrap.Label>Matthew Kelemen</ReactStrap.Label>
                    </ReactStrap.Col>
                </ReactStrap.Row>

                <ReactStrap.Row style={{padding: "10px"}}>
                    <ReactStrap.Col sm={2}>
                        <img height={100} src={han} alt={''}/>
                    </ReactStrap.Col>
                    <ReactStrap.Col sm={9}>
                        <ReactStrap.Label>Han Phan</ReactStrap.Label>
                    </ReactStrap.Col>
                </ReactStrap.Row>

                <ReactStrap.Row style={{padding: "10px"}}>
                    <ReactStrap.Col sm={2}>
                        <img height={100} src={ella} alt={''}/>
                    </ReactStrap.Col>
                    <ReactStrap.Col sm={9}>
                        <ReactStrap.Label>Ella Roberts</ReactStrap.Label>
                    </ReactStrap.Col>
                </ReactStrap.Row>

                <ReactStrap.Row style={{padding: "10px"}} >
                    <ReactStrap.Col sm={2}>
                        <img height={100} src={greg} alt=''/>
                    </ReactStrap.Col>
                    <ReactStrap.Col sm={9}>
                        <ReactStrap.Label>Greg Rolan</ReactStrap.Label>
                    </ReactStrap.Col>
                </ReactStrap.Row>

                <ReactStrap.Row style={{padding: "10px"}}>
                    <ReactStrap.Col sm={12}>
                        The <em>Rights in Records by Design Project</em> is funded through an Australian Research Council (ARC) Discovery Grant DP170100198. The chief investigators are Associate Professor Joanne Evans (Monash University), Associate Professor Jacqueline Wilson (Federation University Australia), Professor Sue McKemmish (Monash), Associate Professor Philip Mendes (Monash), Professor Keir Reeves (Federation), and Dr Jane Bone (Monash).
                   </ReactStrap.Col>
                </ReactStrap.Row>

                <ReactStrap.Row style={{padding: "10px"}}>
                    <ReactStrap.Col sm={12}>
                        Associate Professor Joanne Evans is the recipient of Australian Research Council Future Fellowship FT140100073 <em>Connecting the Disconnected: Co-Designing Inclusive Archival and Recordkeeping Systems</em>.
                    </ReactStrap.Col>
                </ReactStrap.Row>

                <ReactStrap.Row style={{padding: "10px"}}>
                    <ReactStrap.Col sm={12}>
                        For more information, please contact <a href={'mailto:joanne.evans@monash.edu'}>Assoc. Prof. Joanne Evans</a> or <a href={'mailto:greg.rolan@monash.edu'}>Dr Gregory Rolan</a>.
                    </ReactStrap.Col>
                </ReactStrap.Row>


            </ReactStrap.Container>
        )
    }
}
export default MobX.inject('stores')(ReactRouter.withRouter(MobX.observer(Credits)))