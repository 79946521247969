import * as React from "react";
import * as ReactRouter from "react-router-dom";
import * as ReactStrap from 'reactstrap';
import { File } from "./File";
import { Global } from '../global/Global';

export default class Files extends React.Component <any, any> {
    files : any;
    renderFiles : any;

    constructor(props:any) {
        super(props);

        this.files = [
            {
                FileName: 'File 1',
                FileDesc: 'This is file one!'
            },
            {
                FileName: 'File 2',
                FileDesc: 'This is file two!'
            },
            {
                FileName: 'File 3',
                FileDesc: 'This is file three!'
            },
            {
                FileName: 'File 4',
                FileDesc: 'This is file four!'
            },
        ];
        this.renderFiles = [];

        for (const [index, value] of this.files.entries()) {
            if (index === undefined) {
                alert('no files');
            }
            this.renderFiles.push(
                <File FileInfo={value}/>
            );
        };
    }

    render() {
        if (Global.getOfflineAuthCookie() === false && Global.getOnlineAuthCookie() === false) {
            //alert ('You are not login!!!');
            return <ReactRouter.Redirect to="/login"/>;
        }
        return (
            <div className={'m-2'}>
                <h2> All Your Files Are Here!!! </h2>
                <h5> Files (documents, images, sounds) can be used to construct your records.</h5>
                <hr/>
                <ReactStrap.Card body className={'m-3'} outline color={'primary'}>
                    <ReactStrap.CardTitle><h4>Upload A New File</h4></ReactStrap.CardTitle>

                    <ReactStrap.CardText>
                        <ReactStrap.Row>
                            <ReactStrap.Col sm={3}>
                                <strong>Choose File:</strong>
                            </ReactStrap.Col>
                            <ReactStrap.Col sm={9}>
                                <ReactStrap.Input type="file" name="uploadFile" id="uploadFile" />
                            </ReactStrap.Col>
                        </ReactStrap.Row>
                        <ReactStrap.Row>
                            <ReactStrap.Col sm={3}>
                                <strong>File Name:</strong>
                            </ReactStrap.Col>
                            <ReactStrap.Col sm={9}>
                                <ReactStrap.Input type='text' name="uploadFileName" id="uploadFileName" />
                            </ReactStrap.Col>
                        </ReactStrap.Row>
                        <ReactStrap.Row>
                            <ReactStrap.Col sm={3}>
                                <strong>Description:</strong>
                            </ReactStrap.Col>
                            <ReactStrap.Col sm={9}>
                                <ReactStrap.Input type='textarea' name="uploadFileDesc" id="uploadFileDesc" />
                            </ReactStrap.Col>
                        </ReactStrap.Row>
                    </ReactStrap.CardText>

                    <ReactStrap.Button className={'m-1'}
                            onClick = {() => {
                                alert('Uploading a New File');
                            }}
                    >
                        Upload
                    </ReactStrap.Button>
                </ReactStrap.Card>
                <hr/>
                {this.renderFiles}
            </div>
        );
    }
}