import * as React from 'react';
import './App.css';
//import Menu from "./Menu";
import AppBar  from "./AppBar";

export default class App extends React.Component <any, any> {
    render() {
        return (
            <div>
                {/*<Menu/>*/}
                <AppBar/>
            </div>
        );
    };
}
