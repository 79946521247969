import * as React from 'react';
import * as MobX from "mobx-react";
import * as ReactRouter from "react-router-dom";
import * as ReactStrap from "reactstrap";

import {BarLoader} from "react-spinners";

class LoadingSpinner extends React.Component <any, any> {

    render() {

        return (
            <div>
                <ReactStrap.Row>
                    <ReactStrap.Col sm={12}>
                        <ReactStrap.Label>{this.props.msg}</ReactStrap.Label>
                    </ReactStrap.Col>
                </ReactStrap.Row>
                <ReactStrap.Row>
                    <ReactStrap.Col sm={12}>
                        <BarLoader
                            width={500}
                            color={'#123abc'}
                            loading={true}
                        />
                    </ReactStrap.Col>
                </ReactStrap.Row>
            </div>
        );
    }
}

export default MobX.inject('stores')(ReactRouter.withRouter(MobX.observer(LoadingSpinner)));