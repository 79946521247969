import * as React from 'react';
import * as ReactRouter from "react-router-dom";
import * as MobX from "mobx-react";
import {InterfaceStore} from "../store/InterfaceStore";
import {RecordkeepingStore} from "../store/RecordkeepingStore";

//@ts-ignore
import { WithContext as ReactTags } from 'react-tag-input';

class TagWidget extends React.Component <any, any> {
    readonly ifStore : InterfaceStore;
    readonly rkStore : RecordkeepingStore;

    readonly KeyCodes:any  = {
        comma: 188,
        enter: 13,
    };

    readonly delimiters: any = [this.KeyCodes.comma, this.KeyCodes.enter];

    constructor(props : any) {
        super(props);

        this.ifStore = this.props.stores.ifStore;
        this.ifStore.message = null; // to prevent the message being shown when switching page

        this.rkStore = this.props.stores.rkStore;

    }

    handleDelete = (i: string) => {
        let tags:any = this.rkStore.recordContent.tags.value.filter((tag: string, index: string) => index !== i)

        let myEvent:any  = {
            target: {
                name: 'tags',
                value: tags
            }
        }

        this.props.handleFieldChange(myEvent);
    }

    handleAddition = (tag:any)  => {
        let tags: any = this.rkStore.recordContent.tags ? this.rkStore.recordContent.tags.value : []
        tags.push(tag)

        let myEvent:any = {
            target: {
                name: 'tags',
                value: tags
            }
        }

        this.props.handleFieldChange(myEvent);

    }

    handleDrag = (tag: string, currPos: number , newPos: number) => {
        const tags = [...this.state.tags];
        const newTags = tags.slice();

        newTags.splice(currPos, 1);
        newTags.splice(newPos, 0, tag);

        // re-render
        this.setState({ tags: newTags });
    }

    render() {
        let tags:any  = this.rkStore.recordContent.tags ? this.rkStore.recordContent.tags.value : []

         return (
            <ReactTags tags={tags}
                suggestions={this.rkStore.allTags}
                handleDelete={this.handleDelete}
                handleAddition={this.handleAddition}
                handleDrag={this.handleDrag}
                delimiters={this.delimiters}
                inputFieldPosition="inline"
                autocomplete={1}
                       readOnly ={this.props.readOnly}
            />
        );
    }
}
export default MobX.inject('stores')(ReactRouter.withRouter(MobX.observer(TagWidget)));