import * as React from 'react';
import * as ReactStrap from "reactstrap";
import * as Emoji from "emoji-mart";
import {MdRemoveRedEye, MdDeleteForever} from "react-icons/md";
import {IoIosTimer} from "react-icons/io";
import moment from 'moment';
import * as MobX from "mobx-react";
import * as ReactRouter from "react-router-dom";

class VersionListItem extends React.Component <any, any> {
    deleteVersion : any;
    editVersion : any;
    constructor(props : any) {
        super(props);

        this.launchAttachmentWindow = this.launchAttachmentWindow.bind(this)

        this.deleteVersion = props.deleteVersion;
        this.editVersion = props.editVersion;
    }

    launchAttachmentWindow() {
        let attachment : any = this.props.version.attachments[0].attachment;
        let win : any = window.open(attachment.externalHref, '_blank');
        win.focus();
    }

    render() {
        let dateStyle : any = {};
        let expired : any = '';
        if (moment(this.props.version.expireDate).isBefore(moment() )) {
            dateStyle={border: '1px solid red'};
            expired='!';
        }

        let href = this.props.version.attachments && this.props.version.attachments[0] && this.props.version.attachments[0].attachment ? this.props.version.attachments[0].attachment.externalHref : '';

        return (
            <div>
                <ReactStrap.Row>
                    <ReactStrap.Col xs={1}>
                        <Emoji.Emoji emoji={this.props.version.vdIcon} size={20}/>
                    </ReactStrap.Col>
                    <ReactStrap.Col xs={2}>
                        <ReactStrap.Label>{this.props.version.vdLabel}</ReactStrap.Label>
                    </ReactStrap.Col>
                    <ReactStrap.Col xs={2}>
                        <ReactStrap.Label>{this.props.version.tag}</ReactStrap.Label>
                    </ReactStrap.Col>
                    <ReactStrap.Col xs={2}>
                        <ReactStrap.Label style={dateStyle}> {moment(this.props.version.expireDate).format("ddd, MMM Do YYYY")} {expired} </ReactStrap.Label>
                    </ReactStrap.Col>
                    <ReactStrap.Col xs={2}>
                        <ReactStrap.Label>{this.props.version.code}</ReactStrap.Label>
                    </ReactStrap.Col>
                    {this.props.showButtons &&
                        <ReactStrap.Col xs={3}>
                            <ReactStrap.Button size="sm" outline color="primary" onClick={this.launchAttachmentWindow}>
                                <MdRemoveRedEye/></ReactStrap.Button>
                            &nbsp;
                            <ReactStrap.Button size="sm" outline color="primary" onClick={() => this.editVersion(this.props.version)}> <IoIosTimer/></ReactStrap.Button>
                            &nbsp;
                            <ReactStrap.Button size="sm" outline color="primary"
                                    onClick={() => this.deleteVersion(this.props.version)}> <MdDeleteForever
                                color='red' size={14} /></ReactStrap.Button>
                        </ReactStrap.Col>
                    }
                </ReactStrap.Row>
                <ReactStrap.Row>
                    <ReactStrap.Col sm={12}>
                        {href}
                    </ReactStrap.Col>
                </ReactStrap.Row>
                <hr/>
            </div>
        )
    }
}
export default MobX.inject('stores')(ReactRouter.withRouter(MobX.observer(VersionListItem)));