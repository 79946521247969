import * as React from "react";
import * as ReactRouter from "react-router-dom";
import * as ReactStrap from 'reactstrap';
import { Global } from '../global/Global';
import * as MobX from "mobx-react";
import Uploader from "./Uploader";
import Upload from "./Upload";
import {InterfaceStore} from "../store/InterfaceStore";
import {UploadsStore} from "../store/UploadsStore";

class Uploads extends React.Component <any, any> {
    readonly ifStore : InterfaceStore;
    readonly uplStore : UploadsStore;

    constructor(props:any) {
        super(props);

        this.ifStore = this.props.stores.ifStore;
        this.ifStore.message = null; // to prevent the message being shown when switching page

        this.uplStore = this.props.stores.uplStore;
        this.uplStore.loadUploads();

        this.handleSelect = this.handleSelect.bind(this);
        this.handleDelete = this.handleDelete.bind(this);
    }

    handleSelect(href: any) {
        if (this.props.selectCallback) {
            this.props.selectCallback(href);
        }
    }

    handleDelete(href: string) {

        /**********************************************************************************
         * Go delete the attachment
         **********************************************************************************/
        this.uplStore.removeUpload(href);
    }

    render() {
        if (Global.getOfflineAuthCookie() === false && Global.getOnlineAuthCookie() === false) {
            //alert ('You are not login!!!');
            return <ReactRouter.Redirect to="/login"/>;
        }

        let uploads:any = this.uplStore.uploads
        if (this.props.types ) {
            if (this.props.types === 'images') {
                uploads= this.uplStore.uploads.filter((upload:any ) => {
                    return upload.fileName.endsWith('.png') || upload.fileName.endsWith('.jpg') || upload.fileName.endsWith('.jpeg') || upload.fileName.endsWith('.svg')
                });
            }
        }

        return (
            <ReactStrap.Container fluid={true}>
                <ReactStrap.Row style={{padding: "10px"}}>
                    <ReactStrap.Col xs={10}>
                        <h2>Manage My Uploaded Files</h2>
                    </ReactStrap.Col>
                    <ReactStrap.Col xs={2} className="text-right">
                        <Uploader/>
                    </ReactStrap.Col>
                </ReactStrap.Row>

                <ReactStrap.Row>
                    <p>Tap an upload to select.</p>
                </ReactStrap.Row>
                <ReactStrap.Row>
                    {uploads.map((upload: any) =>
                        <ReactStrap.Col xs={6} key={upload.href}>
                            <Upload  upload={upload} selectCallback={this.handleSelect} deleteCallback={this.handleDelete}/>
                        </ReactStrap.Col>
                    )}
                </ReactStrap.Row>

            </ReactStrap.Container>
        );
    }
}

export default MobX.inject('stores')(ReactRouter.withRouter(MobX.observer(Uploads)));