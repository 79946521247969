import Dexie from 'dexie';

const LocalDatabase = new Dexie('LocalDatabase');

//Creating local table settings
console.log('LocalDatabase: Creating local table settings');
LocalDatabase.version(1).stores({
    settings: '++',
});
console.log('LocalDatabase: Creating local table settings finished');

export default LocalDatabase;