import BaseDao from "./BaseDao";
import {Global} from "../global/Global";
import {IRecord, ISite} from "../store/OrgDataStore";

export default class OrgDao extends BaseDao {
    constructContentObj(content: any): any {
    }
    constructDefaultObj(): any {
    }
    fetchAPI(): Promise<any> {
        return new Promise<any>(function () {
        });
    }
    pushAPI(content: any): Promise<any> {
        return new Promise<any>(function () {
        });
    }

    fetchAllSites() {
        let url = Global.getUrlPrefix() + 'site';

        return fetch(url, {
            method: 'GET',
            mode: "cors",
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + Global.getAuthTokenCookie()
            }
        }).then(response => {
            //console.log('FETCH ALL SITES');
            //console.log(response.json());
            return response.json();
        }).then(responseObject => {
            return this.promiseNormalisedResponse('getting all org sites', responseObject);
        }).catch( (e) => {
            //console.log('ERROR FETCHING', e.message);
            return this.promiseNormalisedResponse('getting all org sites', e.message);
        });
    }

    fetchSelectedSites() {
        let url = Global.getUrlPrefix() + 'siteregistry';

        return fetch(url, {
            method: 'GET',
            mode: "cors",
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + Global.getAuthTokenCookie()
            }
        }).then(response => {
            return response.json();
        }).then(responseObject => {
            return this.promiseNormalisedResponse('getting all selected org sites', responseObject);
        }).catch( (e) => {
            return this.promiseNormalisedResponse('getting all selected org sites', e.message);
        });
    }

    registerSite (aSite : ISite) {
        let url = Global.getUrlPrefix() + 'siteregistry/' + Global.idFromUrl(aSite.href);
        //console.log('Register Site: ', url);
        return fetch(url, {
            method: 'PUT',
            mode: "cors",
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + Global.getAuthTokenCookie()
            }
        }).then(response => {
            return response.json();
        }).then(responseObject => {
            return this.promiseNormalisedResponse('registering a site', responseObject);
        }).catch( (e) => {
            return this.promiseNormalisedResponse('registering a site', e.message);
        });
    }

    deRegisterSite (aSite : ISite) {
        let url = Global.getUrlPrefix() + 'siteregistry/' + Global.idFromUrl(aSite.href);

        return fetch(url, {
            method: 'DELETE',
            mode: "cors",
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + Global.getAuthTokenCookie()
            }
        }).then(response => {
            return response.json();
        }).then(responseObject => {
            return this.promiseNormalisedResponse('de-registering a site', responseObject);
        }).catch( (e) => {
            return this.promiseNormalisedResponse('de-registering a site', e.message);
        });
    }


    fetchAllSiteRecords (siteID : string) {
        let subject : string = 'Alex Doe';

        let url = Global.getUrlPrefix() + 'site/' + siteID + '/record?s=' + subject;
        return fetch(url, {
            method: 'GET',
            mode: "cors",
            headers: {
                'Authorization': 'Bearer ' + Global.getAuthTokenCookie()
            }
        }).then(response => {
            //console.log('ALL RECORDS of ALEX DOE SUCCESS: ', response);
            return response.json();
        }).then(responseObject => {
            return this.promiseNormalisedResponse('fetching records of a site', responseObject)
        }).catch((e) => {
            //console.log('ALL RECORDS of ALEX DOE FAIL: ', e);
            return this.promiseNormalisedResponse('fetching records of a site', e.message)
        });
    }
    registerSiteRecord (siteID : string, aRecord : IRecord) {
        let url = Global.getUrlPrefix() + 'site/' + siteID + '/record/'+ aRecord._id;
        return fetch(url, {
            method: 'POST',
            mode: "cors",
            headers: {
                'Authorization': 'Bearer ' + Global.getAuthTokenCookie()
            }
        }).then(response => {
            return response.json();
        }).then(responseObject => {
            return this.promiseNormalisedResponse('registering a site record', responseObject)
        }).catch((e) => {
            return this.promiseNormalisedResponse('registering a site record', e.message)
        });
    }

    registerSiteRecords (siteID : string, records : IRecord[]) {
        //go update all of the records
        let promises:any = records.map((item : any) => {
            let url:string = Global.getUrlPrefix() + 'site/' + siteID + '/record/' + item._id
            //console.log('fetching from ' + url)

            return fetch(url, {
                method: 'POST',
                mode: "cors",
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + Global.getAuthTokenCookie()
                }
            }).then(response => response.json())
        });

        //wait for them all to complete
        return Promise.all(promises).then((responseObject) => {
            return this.promiseNormalisedResponse('registering a site record', responseObject)
        }).catch((e) => {
            return this.promiseNormalisedResponse('registering a site record', e.message)
        });
    }

    deRegisterSiteRecord (siteID : string, aRecord : IRecord) {
        let url = Global.getUrlPrefix() + 'site/' + siteID + '/record/'+ aRecord._id;
        return fetch(url, {
            method: 'DELETE',
            mode: "cors",
            headers: {
                'Authorization': 'Bearer ' + Global.getAuthTokenCookie()
            }
        }).then(response => {
            return response.json();
        }).then(responseObject => {
            return this.promiseNormalisedResponse('registering a site record', responseObject)
        }).catch((e) => {
            return this.promiseNormalisedResponse('registering a site record', e.message)
        });
    }

    fetchPreviewAttachment (siteID : string, aRecord : IRecord) {
        let url = Global.getUrlPrefix() + 'site/' + siteID + '/record/'+ aRecord._id;
        return fetch(url, {
            method: 'GET',
            mode: "cors",
            headers: {
                'Authorization': 'Bearer ' + Global.getAuthTokenCookie()
            }
        }).then(response => {
            return response.blob();
        }).then(responseObject => {
            return this.promiseNormalisedResponse('fetching preview attachment', responseObject)
        }).catch((e) => {
            return this.promiseNormalisedResponse('fetching preview attachment', e.message)
        });
    }

    registerAttachment (siteID : string, recordID:string) {
        let url = Global.getUrlPrefix() + 'site/' + siteID + '/record/'+ recordID + '/attachment';
        return fetch(url, {
            method: 'POST',
            mode: "cors",
            headers: {
                'Authorization': 'Bearer ' + Global.getAuthTokenCookie()
            }
        }).then(response => {
            return response.json();
        }).then(responseObject => {
            return this.promiseNormalisedResponse('registering attachment', responseObject)
        }).catch((e) => {
            return this.promiseNormalisedResponse('registering attachment', e.message)
        });
    }

    deregisterAttachment (siteID : string, recordID:string) {
        let url = Global.getUrlPrefix() + 'site/' + siteID + '/record/'+ recordID + '/attachment';
        return fetch(url, {
            method: 'DELETE',
            mode: "cors",
            headers: {
                'Authorization': 'Bearer ' + Global.getAuthTokenCookie()
            }
        }).then(response => {
            return response.json();
        }).then(responseObject => {
            return this.promiseNormalisedResponse('registering attachment', responseObject)
        }).catch((e) => {
            return this.promiseNormalisedResponse('registering attachment', e.message)
        });
    }
}