import * as React from 'react';
import * as ReactStrap from 'reactstrap';
import {Global} from "../global/Global";
import 'emoji-mart/css/emoji-mart.css';
import {MdCancel, MdEdit} from 'react-icons/md';
import * as Emoji from 'emoji-mart';
import * as ReactRouter from "react-router-dom";
import * as MobX from "mobx-react";
import {InterfaceStore} from "../store/InterfaceStore";
import {RecordkeepingStore} from "../store/RecordkeepingStore";

class VersionDefs extends React.Component <any, any> {
    toggle : any;

    readonly ifStore : InterfaceStore;
    readonly rkStore : RecordkeepingStore;

    constructor(props : any) {
        super(props);

        this.state = {
            setPicker: null,
            modal: false,
            pageDirty: false,
            goRecordEdit: false,
        };

        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleFieldChange = this.handleFieldChange.bind(this);
        this.handlePickedIcon = this.handlePickedIcon.bind(this);
        this.launchIconPicker = this.launchIconPicker.bind(this);
        this.toggleIconModal = this.toggleIconModal.bind(this);
        this.handleIconCancel = this.handleIconCancel.bind(this);
        this.toggleLeaveModal = this.toggleLeaveModal.bind(this);
        this.leavePage = this.leavePage.bind(this);

        this.ifStore = this.props.stores.ifStore;
        this.ifStore.message = null; // to prevent the message being shown when switching page

        this.rkStore = this.props.stores.rkStore;
        this.rkStore.loadRecordVersionDefs();

    }

    handleSubmit(event : any) {
        //THIS IS VERY IMPORTANT
        //https://stackoverflow.com/questions/39809943/react-preventing-form-submission
        event.preventDefault();

        this.setState({
            showSpinner : true,
        });

        this.rkStore.saveRecordVersionDefs();

        this.setState({
            showSpinner : false,
            pageDirty: false,
        });
        event.preventDefault();
    }

    handleFieldChange(event : any) {
        this.rkStore.versionDefs[event.target.name].label = event.target.value;

        this.setState({
            pageDirty: true
        });
    }

    launchIconPicker(set : any) {
        this.setState({
            setPicker: set,
        });

        this.toggleIconModal()
    }

    handlePickedIcon (icon : any) {
        this.rkStore.versionDefs[this.state.setPicker].icon = icon.id
        this.setState({
            setPicker: null,
            pageDirty: true
        });

        this.toggleIconModal()
    }

    handleIconCancel(set : any){
        this.rkStore.versionDefs[set] = {label: "", icon: "question"}
        this.setState({
            pageDirty: true
        });
    }

    toggleIconModal() {
        this.setState((prevState : any) => ({
            modal: !prevState.modal
        }));
    }

    toggleLeaveModal() {
        this.setState((prevState : any) => ({
            leaveModal: !prevState.leaveModal,
        }));
    }

    leavePage() {
        this.setState({
            goRecordEdit: true,
        })
    }

    render() {
        if (Global.getOfflineAuthCookie() === false && Global.getOnlineAuthCookie() === false) {
            //alert ('You are not login!!!');
            return <ReactRouter.Redirect to="/login" />;
        }

        if(this.state.goRecordEdit) {
            return <ReactRouter.Redirect to="/recordedit" />;
        }

        let versions : { [key: string]:{icon: string, label: string }} = this.rkStore.versionDefs;

        const modalCloseBtn = <button className="close" onClick={this.toggleIconModal}>&times;</button>;
        return (
            <ReactStrap.Container fluid={true}>
                <ReactStrap.Row style={{padding: "10px"}}>
                    <ReactStrap.Col sm={8}>
                        <h2 className={'m-2'}>Manage My Sharing Profiles</h2>
                    </ReactStrap.Col>
                    <ReactStrap.Col className="text-right">
                        <ReactStrap.Button outline color="primary" onClick={() => {
                            if (this.state.pageDirty) {
                                this.toggleLeaveModal()
                            } else {
                                this.setState({
                                    goRecordEdit: true,
                                })
                            }
                        }}> <MdEdit /></ReactStrap.Button>
                    </ReactStrap.Col>

                </ReactStrap.Row>

                <ReactStrap.Row>
                    <ReactStrap.Col sm={12}>
                        <p>Click on version icon to change.</p>
                    </ReactStrap.Col>
                </ReactStrap.Row>

                <ReactStrap.Form onSubmit={this.handleSubmit}>
                    <ReactStrap.Modal isOpen={this.state.modal} toggle={this.toggleIconModal} className={this.props.className}>
                        <ReactStrap.ModalHeader toggle={this.toggle} close={modalCloseBtn} >Choose an icon</ReactStrap.ModalHeader>
                        <Emoji.Picker onSelect={this.handlePickedIcon}/>
                    </ReactStrap.Modal>

                    <ReactStrap.FormGroup row className={'m-1 '}>
                        <ReactStrap.Label xs={1}>
                            Profile Version
                        </ReactStrap.Label>
                        <ReactStrap.Col sm={3}>
                            <ReactStrap.Input disabled type="text" id="set0" name="set0" defaultValue="Profile" placeholder="Profile"/>
                        </ReactStrap.Col>
                        <ReactStrap.Col xs={1}>
                            <span style={{display:'inline-block',paddingTop: '8px'}}><Emoji.Emoji emoji='bust_in_silhouette' size={20} /></span>
                        </ReactStrap.Col>
                    </ReactStrap.FormGroup>

                    <ReactStrap.FormGroup row className={'m-1'}>
                        <ReactStrap.Label xs={1}>
                            Version #1
                        </ReactStrap.Label>
                        <ReactStrap.Col sm={3}>
                            <ReactStrap.Input type="text" id="set1" name="set1" placeholder="Bio Version Name" value={versions['set1'] ? versions['set1'].label : ''} onChange={this.handleFieldChange}/>
                        </ReactStrap.Col>
                        <ReactStrap.Col xs={1}>
                            <span style={{display:'inline-block',paddingTop: '8px'}}>
                                <Emoji.Emoji emoji={versions['set1']  &&  versions['set1'].icon ? versions['set1'].icon : 'one'} size={20} onClick={()=>this.launchIconPicker('set1')}/>
                            </span>
                            <span style={{display:'inline-block', verticalAlign: "text-top"}}>
                                &nbsp;<MdCancel onClick={()=>this.handleIconCancel('set1')}/>
                            </span>
                        </ReactStrap.Col>
                    </ReactStrap.FormGroup>

                    <ReactStrap.FormGroup row className={'m-1'}>
                        <ReactStrap.Label xs={1}>
                            Version #2
                        </ReactStrap.Label>
                        <ReactStrap.Col sm={3}>
                            <ReactStrap.Input type="text" id="set2" name="set2" placeholder="Bio Version Name" value={versions['set2'] ? versions['set2'].label : ''} onChange={this.handleFieldChange}/>
                        </ReactStrap.Col>
                        <ReactStrap.Col xs={1}>
                            <span style={{display:'inline-block',paddingTop: '8px'}}>
                                <Emoji.Emoji onClick={()=>this.launchIconPicker('set2')} emoji={versions['set2']  &&  versions['set2'].icon ? versions['set2'].icon : 'two'} size={20} />
                            </span>
                            <span style={{display:'inline-block', verticalAlign: "text-top"}}>
                                &nbsp;<MdCancel onClick={()=>this.handleIconCancel('set2')}/>
                            </span>
                        </ReactStrap.Col>
                    </ReactStrap.FormGroup>

                    <ReactStrap.FormGroup row className={'m-1'}>
                        <ReactStrap.Label xs={1}>
                            Version #3
                        </ReactStrap.Label>
                        <ReactStrap.Col sm={3}>
                            <ReactStrap.Input type="text" id="set3" name="set3" placeholder="Bio Version Name" value={versions['set3'] ? versions['set3'].label : ''} onChange={this.handleFieldChange}/>
                        </ReactStrap.Col>
                        <ReactStrap.Col xs={1}>
                            <span style={{display:'inline-block',paddingTop: '8px'}}>
                                <Emoji.Emoji onClick={()=>this.launchIconPicker('set3')} emoji={versions['set3']  &&  versions['set3'].icon ? versions['set3'].icon : 'three'} size={20} />
                            </span>
                            <span style={{display:'inline-block', verticalAlign: "text-top"}}>
                                &nbsp;<MdCancel onClick={()=>this.handleIconCancel('set3')}/>
                            </span>
                        </ReactStrap.Col>
                    </ReactStrap.FormGroup>

                    <ReactStrap.FormGroup row className={'m-1'}>
                        <ReactStrap.Label xs={1}>
                            Version #4
                        </ReactStrap.Label>
                        <ReactStrap.Col sm={3}>
                            <ReactStrap.Input type="text" id="set4" name="set4" placeholder="Bio Version Name" value={versions['set4'] ? versions['set4'].label : ''} onChange={this.handleFieldChange}/>
                        </ReactStrap.Col>
                        <ReactStrap.Col xs={1}>
                            <span style={{display:'inline-block',paddingTop: '8px'}}>
                                <Emoji.Emoji onClick={()=>this.launchIconPicker('set4')} emoji={versions['set4']  &&  versions['set4'].icon ? versions['set4'].icon : 'four'} size={20} />
                            </span>
                            <span style={{display:'inline-block', verticalAlign: "text-top"}}>
                                &nbsp;<MdCancel onClick={()=>this.handleIconCancel('set4')}/>
                            </span>
                        </ReactStrap.Col>
                    </ReactStrap.FormGroup>

                    <ReactStrap.FormGroup row className={'m-1'}>
                        <ReactStrap.Label xs={1}>
                            Version #5
                        </ReactStrap.Label>
                        <ReactStrap.Col sm={3}>
                            <ReactStrap.Input type="text" id="set5" name="set5" placeholder="Bio Version Name" value={versions['set5'] ? versions['set5'].label : ''} onChange={this.handleFieldChange}/>
                        </ReactStrap.Col>
                        <ReactStrap.Col xs={1}>
                            <span style={{display:'inline-block',paddingTop: '8px'}}>
                                <Emoji.Emoji onClick={()=>this.launchIconPicker('set5')} emoji={versions['set5']  &&  versions['set5'].icon ? versions['set5'].icon : 'five'} size={20} />
                            </span>
                            <span style={{display:'inline-block', verticalAlign: "text-top"}}>
                                &nbsp;<MdCancel onClick={()=>this.handleIconCancel('set5')}/>
                            </span>
                        </ReactStrap.Col>
                    </ReactStrap.FormGroup>

                    <ReactStrap.Button type="submit" className={'ml-4'}>Update</ReactStrap.Button>
                </ReactStrap.Form>

                <ReactStrap.Modal isOpen={this.state.leaveModal} toggle={this.toggleLeaveModal}>
                    <ReactStrap.ModalHeader toggle={this.toggle}>Unsaved Changes</ReactStrap.ModalHeader>
                    <ReactStrap.ModalBody>
                        You have unsaved changes on this page.  Leave page?
                    </ReactStrap.ModalBody>
                    <ReactStrap.ModalFooter>
                        <ReactStrap.Button color="primary" onClick={this.leavePage}>Leave</ReactStrap.Button>{' '}
                        <ReactStrap.Button color="secondary" onClick={this.toggleLeaveModal}>Cancel</ReactStrap.Button>
                    </ReactStrap.ModalFooter>
                </ReactStrap.Modal>
            </ReactStrap.Container>
        )
    }
}
export default MobX.inject('stores')(ReactRouter.withRouter(MobX.observer(VersionDefs)))