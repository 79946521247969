import * as React from 'react';
import * as ReactStrap from "reactstrap";
import {Global} from "../global/Global";
import * as ReactRouter from "react-router-dom";
import * as MobX from "mobx-react";
import {InterfaceStore} from "../store/InterfaceStore";
import {AppStore} from "../store/AppStore";

class SystemPreferences extends React.Component <any, any> {
    readonly ifStore : InterfaceStore;
    readonly appStore : AppStore;

    constructor(props : any) {
        super(props);

        this.ifStore = this.props.stores.ifStore;
        this.ifStore.message = null; // to prevent the message being shown when switching page

        this.appStore = this.props.stores.appStore;

        this.handleSubmit = this.handleSubmit.bind(this);
    }

    handleSubmit(event : any) {
        //THIS IS VERY IMPORTANT
        //https://stackoverflow.com/questions/39809943/react-preventing-form-submission
        event.preventDefault();
        this.appStore.SetSettings();
    }

    componentDidMount() {
        this.appStore.GetSettings();
    }

    render() {
        if (Global.getOfflineAuthCookie() === false && Global.getOnlineAuthCookie() === false) {
            //alert ('You are not login!!!');
            return <ReactRouter.Redirect to="/login" />;
        }
        return (
            <div>
                <h2> Configure system behaviour. </h2>
                <ReactStrap.Form onSubmit={this.handleSubmit}>

                    <ReactStrap.FormGroup row className={'m-2'}>
                        <ReactStrap.Label sm={4}> Idle timeout</ReactStrap.Label>
                        <ReactStrap.Col sm={8}>
                            <ReactStrap.CustomInput type="select" id="idletime" name="idletime"
                                         placeholder="Idle time"
                                                    value={this.appStore.temp_settings.idletime}
                                                    defaultValue={this.appStore.settings.idletime.toString()}
                                         onChange={(e)=>{
                                            //alert('onchange' + e.target.value);
                                            // this.setState({
                                            //     idletime: e.target.value
                                            // })
                                             this.appStore.temp_settings.idletime = Number(e.target.value);
                                         }}>
                                <option value="">Select</option>
                                <option value={30}>0.5 minute</option>
                                <option value={60}>1 minute</option>
                                <option value={120}>2 minutes</option>
                                <option value={240}>4 minutes</option>
                                <option value={300}>5 minutes</option>
                                <option value={600}>10 minutes</option>
                                <option value={1200}>20 minutes</option>
                                <option value={1800}>30 minutes</option>
                            </ReactStrap.CustomInput>
                        </ReactStrap.Col>
                    </ReactStrap.FormGroup>

                    <ReactStrap.FormGroup row className={'m-2'}>
                        <ReactStrap.Label sm={4}> Idle countdown </ReactStrap.Label>
                        <ReactStrap.Col sm={8}>
                            <ReactStrap.CustomInput type="select" id="countdowntime" name="countdowntime"
                                         placeholder="Countdown time"
                                                    value={this.appStore.temp_settings.countdowntime}
                                                    defaultValue={this.appStore.settings.countdowntime.toString()}
                                         onChange={(e)=>{
                                             //alert('onchange' + e.target.value);
                                             // this.setState({
                                             //     countdowntime: e.target.value
                                             // })
                                             this.appStore.temp_settings.countdowntime = Number(e.target.value);
                                         }}>
                                <option value="">Select</option>
                                <option value={10}>10 seconds</option>
                                <option value={20}>20 seconds</option>
                                <option value={30}>30 seconds</option>
                                <option value={60}>1 minute</option>
                            </ReactStrap.CustomInput>
                        </ReactStrap.Col>
                    </ReactStrap.FormGroup>

                    <ReactStrap.Button type="submit" className={'ml-4'}>Save</ReactStrap.Button>

                </ReactStrap.Form>
            </div>
        );
    }
}
export default MobX.inject('stores')(ReactRouter.withRouter(MobX.observer(SystemPreferences)));