import * as React from "react";
import * as MobX from "mobx-react";
import * as ReactRouter from "react-router-dom";
import {Global} from "../global/Global";

class Tutorial extends React.Component <any, any> {
    render() {
        if (Global.getOfflineAuthCookie() === false && Global.getOnlineAuthCookie() === false) {
            //alert ('You are not login!!!');
            return <ReactRouter.Redirect to="/login"/>;
        }
        return (
            <div>
                <h1>MySSy Tutorial</h1>
                <span style={{ margin: '10px',}}>
                    The introduction, initial tutorials and future tutorials can be found at
                </span>
                <br/>
                <span style={{ margin: '10px',}}>
                    <a href="https://rirservermonash.gitlab.io/landing/" target="_blank" rel="noopener noreferrer">
                        MySSy Landing Page
                    </a>
                </span>
                {/*<Card body inverse color="info" style={{ margin: '3px',}}>

                    <CardTitle><h2>Record Version (e.g., Sharing Profiles)</h2></CardTitle>
                    <CardSubtitle>When sharing bio details and records with others – either via download or through the controlled sharing mechanism,
                        it is necessary to select which information and/or metadata to share.
                        The ‘Sharing Profiles’ functionality enables the creation of up to 6 profiles each with a description and custom emoji.
                        These sharing profiles can then be applied to each element of your bio details, and the notes and tags you add to records.
                        Selecting one of these profiles when viewing Bio Details will show the information that will be shared.
                        This creates a Personal Record of type Bio.
                        This page can be accessed from My Info, then Manage Record Versions.</CardSubtitle>
                    <img width="70%" style={{ margin: '2px',}} src={tut_recordversion} alt="tut_recordversion" />
                </Card>
                <Card body inverse color="info" style={{ margin: '3px',}}>
                    <CardTitle><h2>Record Construction</h2></CardTitle>
                    <CardSubtitle>Each piece of information can be assigned to one or more record version.</CardSubtitle>
                    <img width="70%" style={{ margin: '2px',}} src={tut_recordconstruct} alt="tut_recordconstruct" />
                </Card>
                <Card body inverse color="info" style={{ margin: '3px',}}>
                    <CardTitle><h2>Record Sharing (1)</h2></CardTitle>
                    <CardSubtitle>Two recording sharing mechanisms: (1) Managed Online Sharing (2) UnManaged Local Copy Download.</CardSubtitle>
                    <img width="70%" style={{ margin: '2px',}} src={tut_recordshare1} alt="tut_recordshare1" />
                </Card>
                <Card body inverse color="info" style={{ margin: '3px',}}>
                    <CardTitle><h2>Record Sharing (2)</h2></CardTitle>
                    <CardSubtitle>Setup and manage an online sharing version.</CardSubtitle>
                    <img width="70%" style={{ margin: '2px',}} src={tut_recordshare2} alt="tut_recordshare2" />
                    <img width="70%" style={{ margin: '2px',}} src={tut_recordshare3} alt="tut_recordshare3" />
                    <img width="70%" style={{ margin: '2px',}} src={tut_recordshare4} alt="tut_recordshare4" />
                </Card>*/}
            </div>
        );
    }
}
export default MobX.inject('stores')(ReactRouter.withRouter(MobX.observer(Tutorial)))