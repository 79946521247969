import * as React from 'react';
import * as ReactStrap from 'reactstrap';
import {
    FaDatabase,
    FaEdit,
    FaCogs,
    FaUserTimes,
    FaClone,
    FaRegBuilding,
    FaShareAlt, FaFolderOpen, FaCloudUploadAlt, FaHome, FaLock, FaIdBadge,
    FaInfoCircle,
} from 'react-icons/fa';
import { MdSettings, MdHistory} from 'react-icons/md';
import {IoMdPersonAdd, IoIosLogIn, IoIosLogOut, IoIosSync} from 'react-icons/io';
import logo from './assets/logo_notext.png';
import * as ReactRouter from "react-router-dom";
import Record from "./pages/Record";
import Register from "./pages/security/Register";
import RegisterSuccess from "./pages/security/RegisterSuccess";
import Login from "./pages/security/Login";
import ResetPassword from "./pages/security/ResetPassword";
import ResetPasswordSuccess from "./pages/security/ResetPasswordSuccess";
import PageNotFound from "./global/PageNotFound";
import Logout from "./pages/security/Logout";
import RecordEdit from "./pages/RecordEdit";
import VersionDefs from "./pages/VersionDefs";
import SystemPreferences from "./pages/SystemPreferences";
import Records from './pages/Records';
import Files from './experiment/Files';
import Uploads from './components/Uploads';
import ManageShares from "./pages/ManageShares";
import History from "./pages/History";
import IdleTimer from 'react-idle-timer';
import OrgDataManager from './pages/OrgDataManager';
import Countdown from 'react-countdown-now';
import {Global} from './global/Global';
import * as MobX from "mobx-react";
import ResponseMessage from "./components/ResponseMessage";
import {InterfaceStore} from "./store/InterfaceStore";
import {RecordkeepingStore} from "./store/RecordkeepingStore";
import {OrgDataStore} from "./store/OrgDataStore";
import Credits from "./pages/Credits";
import {GoNote} from "react-icons/all";
import Notes from "./pages/Notes";
import NoteRecordEdit from "./components/NoteRecordEdit";
import {AppStore} from "./store/AppStore";
import NoteRecordWrapper from "./components/NoteRecordWrapper";
import Tutorial from "./pages/Tutorial";

let OnlineOffline = require('react-detect-offline');
let Online = OnlineOffline.Online;
let Offline = OnlineOffline.Offline;
let Detector = OnlineOffline.Detector;

class AppBar extends React.Component <any, any> {

    onIdle : any;
    onActive : any;
    renderer : any;

    readonly ifStore : InterfaceStore;
    readonly rkStore : RecordkeepingStore;
    readonly odStore : OrgDataStore;
    readonly appStore : AppStore;

    constructor(props : any) {
        super(props);

        this.toggle = this.toggle.bind(this);

        this.state = {
            isOpen: false,
            message : null,
            login: false,
            idle: false,
            // systemPreferences: {
            //     idleTime : Global.DEFAULT_IDLE_TIME,
            //     idleCountdown : Global.DEFAULT_COUNTDOWN_TIME,
            // },
            searchTerms: '',

        };

        this.ifStore = this.props.stores.ifStore;
        this.rkStore = this.props.stores.rkStore;
        this.odStore = this.props.stores.odStore;
            this.appStore = this.props.stores.appStore;

        this.handleLogin = this.handleLogin.bind(this);
        this.AppInit = this.AppInit.bind(this);
        this.onBackButtonEvent = this.onBackButtonEvent.bind(this);
        this.onAppClose = this.onAppClose.bind(this);
        this.countdownRenderer = this.countdownRenderer.bind(this);


        this.onIdle = this._onIdle.bind(this);
        this.onActive = this._onActive.bind(this);

    }

    countdownRenderer (rendererObj : any) {
        //console.log('rendererObj', rendererObj);
        if (rendererObj.completed) {
            // Render a completed state
            //alert('count down finished, quit');

            //User is not touching the app and the countdown ends
            //Unset authentication Cookies
            Global.setOnlineAuthCookie(false);
            Global.setOfflineAuthCookie(false);
            Global.setAuthTokenCookie(null);
            //Update login status back to app bar
            this.setState({
                login: false
            });
            return null;
        } else {
            // Render a countdown
            return  this.state.login? <div className={'m-3'}>
                <h4> It seems that you are not around!
                    <br/>
                    To protect your privacy, logging out in {rendererObj.hours}:{rendererObj.minutes}:{rendererObj.seconds}!
                    <br/>
                    Interact with the system to cancel the countdown and continue!
                </h4>
            </div> : null;
        }
    }

    onBackButtonEvent = (e : any) => {
        e.preventDefault();
        this.toggleLeaveModal()
    };

    onAppClose = (e : any) => {
        const confirmationMessage = 'Do you want to close MySSy?';
        e.returnValue = confirmationMessage;     // Gecko, Trident, Chrome 34+
        return confirmationMessage;              // Gecko, WebKit, Chrome <34
    };

    componentDidMount() {
        window.onpopstate = this.onBackButtonEvent;
        window.onbeforeunload = this.onAppClose;
    }

    // handling changes in system preferences
    // handleSetSystemPreferences(newIdleTime : any, newIdleCountdown : any) {
    //     let objPref = {
    //         idleTime : newIdleTime,
    //         idleCountdown: newIdleCountdown,
    //     };
    //     // set to AppBar state
    //     this.setState({
    //         systemPreferences : objPref,
    //     });
    // }

    AppInit() {
        /*
         *
         * LOADING RECORDS (Settings record)
         * Update the system preferences based on server data if online
         * When offline use settings stored in local database if available, if not use default settings
         *
         */
        this.appStore.GetSettings();
        //console.log('SETTINGS IN APPBAR', this.appStore.settings);
        /*if(Global.isOnline()) {
            // Load system preferences from server
            let url = Global.getUrlPrefix() + 'getsettings';
            fetch(url, {
                method: 'GET',
                mode: "cors",
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + Global.getAuthTokenCookie()
                }
            }).then(response => {
                return response.json();
            }).then(responseObject => {
                let jsonContent = responseObject.content;
                let objContent = JSON.parse(jsonContent);

                //If the server db does not have settings of this user yet, return here so the default values are used
                if (Global.isJsonObjEmpty(objContent)) {
                    return;
                }

                /!*
                 *
                 * SYNC: Because the local database may have contents that are newer than server database
                 * synchronization process need to be done.
                 *
                 *!/

                // load local database info
                LocalDatabase.table('settings')
                    .get(1)
                    .then((settings) => {
                        if (settings === undefined) {
                            return;
                        }
                        //There is record of settings in local database, extract and use these
                        let localDBIdleTime : any = settings.Content.idleTime;
                        let localDBIdleCountdown : any = settings.Content.idleCountdown;
                        let localModifiedTime : any = settings.Content.modifiedTime;

                        //Check if local database is newer than server database, then sync
                        if (objContent.modifiedTime < localModifiedTime) {
                            //save the local database to server
                            let url : any = Global.getUrlPrefix() + 'putsettings';
                            let jsonObj : any = {
                                content: {
                                    idletime : localDBIdleTime,
                                    countdowntime: localDBIdleCountdown,
                                    modifiedTime : localModifiedTime
                                }
                            };
                            let jsonData : any = JSON.stringify(jsonObj);
                            fetch(url, {
                                method: 'PUT',
                                mode: "cors",
                                body: jsonData,
                                headers: {
                                    'Authorization': 'Bearer ' + Global.getAuthTokenCookie()
                                }
                            }).then(( response : any )  => {
                                return response.json();
                            }).then(( responseObject : any ) => {
                                //So we can alert any success/error messages
                                //alert("SYNCED : " + JSON.stringify(responseObject));
                                this.ifStore.message = 'App settings successfully stored in server';

                            }).catch(function (this : AppBar, e : any) {
                                this.ifStore.message = {error:'System error when saving settings to server.'};
                                console.log("SOMETHING IS WRONG!", e);
                            });

                            // Apply local database settings to the app
                            this.handleSetSystemPreferences(localDBIdleTime, localDBIdleCountdown);

                            //return here!!!
                            //return;
                        } else {
                            // if the local database is older than the server data, simple store the server data to local DB
                            // Store server data to to local database
                            //alert ('ELSE')
                            let objPref = {
                                idleTime : objContent.idletime,
                                idleCountdown : objContent.countdowntime,
                                modifiedTime : objContent.modifiedTime
                            };
                            let isDirty : boolean = true;
                            if (Global.isOnline()) // not necessary, should always be isDirty = false
                                isDirty = false;
                            let objPrefLocalDB : any = {
                                Content : objPref,
                                dirty : isDirty
                            };
                            //alert (JSON.stringify(objPrefLocalDB));
                            LocalDatabase.table('settings')
                                .put(objPrefLocalDB,1)
                                .then((id) => {
                                    //alert("STORE SETTINGS TO LOCAL DATABASE DONE! ID = " + id);
                                });

                            // Activate the effect of new settings
                            this.handleSetSystemPreferences(objContent.idletime, objContent.countdowntime);
                        }
                    })
                    .catch((e : any) => {
                        //alert('CATCH')
                        //if the local database does not have settings, simply store the server data to local DB
                        // Store server data to to local database
                        let objPref = {
                            idleTime : objContent.idletime,
                            idleCountdown : objContent.countdowntime,
                            modifiedTime : objContent.modifiedTime
                        };
                        let isDirty : boolean = true;
                        if (Global.isOnline()) // not necessary, should always be isDirty = false
                            isDirty = false;
                        let objPrefLocalDB = {
                            Content : objPref,
                            dirty : isDirty
                        };
                        //alert (JSON.stringify(objPrefLocalDB));
                        LocalDatabase.table('settings')
                            .put(objPrefLocalDB,1)
                            .then((id) => {
                                //alert("STORE SETTINGS TO LOCAL DATABASE DONE! ID = " + id);
                            });

                        // Activate the effect of new settings
                        this.handleSetSystemPreferences(objContent.idletime, objContent.countdowntime);
                    })
            }).catch(function (e) {
                console.log("SOMETHING IS WRONG!", e);
            });
        } else {
            // when the app is offline, check if settings are in local database, yes: use them, no: use defaults
            LocalDatabase.table('settings')
                .get(1)
                .then((settings : any) => {
                    if (settings === undefined) {
                        return;
                    }
                    //There are record of settings in local database, extract and use these
                    let localDBIdleTime = settings.Content.idleTime;
                    let localDBIdleCountdown = settings.Content.idleCountdown;
                    // Activate the effects of local settings
                    this.handleSetSystemPreferences(localDBIdleTime, localDBIdleCountdown);
                })
                .catch(); // When there is no local data, do nothing so the default values for settings are used
        }*/
    }

    handleLogin(loginStatus : any) {
        this.setState({
            login : loginStatus,
        });
        if(loginStatus) {
            this.AppInit();

            this.rkStore.getRecords('c=Personal&t=Bio', true);
            this.rkStore.loadRecordVersionDefs();
        }
    }

    toggle() {
        this.setState({
            isOpen: !this.state.isOpen
        });
    }

    _onIdle(e : any) {
        //alert('You are idle');
        this.setState({
            idle : true
        });
        //this.countDown.start();
    }

    _onActive(e : any) {
        //alert('You are active');
        this.setState({
            idle : false
        });
    }

    toggleLeaveModal = () => {
        this.setState((prevState : any) => ({
            leaveModal: !prevState.leaveModal,
        }));
    }

    leavePage = () => {
        //Unset authentication Cookies
        Global.setOnlineAuthCookie(false);
        Global.setOfflineAuthCookie(false);
        this.handleLogin(false);

        this.toggleLeaveModal()
    }

    handleSearchTermsChange = (event: any) => {
        this.setState({
            searchTerms: event.target.value
        });
    }

    handleSearchKeyDown = (e:any) => {
        if (e.key === 'Enter') {
            this.rkStore.getRecords('q=' + this.state.searchTerms);
            this.props.history.push('/records')
        }
    }

    render() {
        if (!this.state.login) {
            Global.setOnlineAuthCookie(false);
            Global.setOfflineAuthCookie(false);
        }

        var editBio = () => {
            this.rkStore.getRecords('c=Personal&t=Bio', true);
            this.props.history.push('/recordedit')
        };

        var viewBio = () => {
            this.rkStore.getRecords('c=Personal&t=Bio', true);
            this.props.history.push('/')
        };

        var listRecords = () => {
            //this.odStore.GetAllSitesFromServer();//also refresh when tab changed, or select/unselect buttons clicked
            //this.odStore.GetSelectedSitesFromServer();//also refresh when tab changed, or select/unselect buttons clicked
            this.rkStore.getRecords();
            this.props.history.push('/records')
        };

        var manageShares = () => {
            this.props.stores.rkStore.getRecords('', false, true);
            this.props.history.push('/manageshares')
        };

        var viewHistory = () => {
            this.props.stores.rkStore.getRecords('', false, true, true);
            this.props.history.push('/history')
        };

        var findRecords = () => {
            //this.odStore.GetAllSitesFromServer();//also refresh when tab changed, or select/unselect buttons clicked
            //this.odStore.GetSelectedSitesFromServer();//also refresh when tab changed, or select/unselect buttons clicked
            this.props.history.push('/orgdatamanager')
        };

        var viewNotes = () => {
            this.rkStore.getRecords();
            this.props.history.push('/notes')
        };

        return (
            <div >

{/*
                <span>idletime = {this.appStore.settings.idletime}; countdowntime = {this.appStore.settings.countdowntime}</span>
                <br/>
                <span>tmp_idletime = {this.appStore.temp_settings.idletime}; tmp_countdowntime = {this.appStore.temp_settings.countdowntime}</span>

*/}

                <IdleTimer
                    element={document}
                    onActive={this.onActive}
                    onIdle={this.onIdle}
                    debounce={250}
                    timeout={1000 * this.appStore.settings.idletime} />

                {this.state.idle ? <Countdown
                    date={Date.now() + 1000 * this.appStore.settings.countdowntime}
                    renderer={this.countdownRenderer}
                    autoStart={true}/> : null}


                <ReactStrap.Navbar expand="xl" color="primary" light dark>
                    <ReactStrap.NavbarBrand  tag={ReactRouter.Link} onClick={viewBio} to="/"> <img src={logo} alt="logo" className="mr-3"/> <FaHome/> Home </ReactStrap.NavbarBrand>

                    <ReactStrap.Nav navbar expand="lg" >
                        <ReactStrap.NavItem>
                            <ReactStrap.NavLink onClick={listRecords} tag={ReactRouter.Link} to="/records"><FaFolderOpen/> My records</ReactStrap.NavLink>
                        </ReactStrap.NavItem>
                        <ReactStrap.NavItem>
                            <ReactStrap.NavLink onClick={findRecords} tag={ReactRouter.Link} to="/orgdatamanager"><FaRegBuilding /> Find Records</ReactStrap.NavLink>
                        </ReactStrap.NavItem>

                    </ReactStrap.Nav>

                    <ReactStrap.NavbarToggler onClick={this.toggle} />
                    <ReactStrap.Collapse isOpen={this.state.isOpen} navbar>
                        <ReactStrap.Nav className="ml-auto" navbar>

                            <ReactStrap.Input type="text" id="search" name="search" placeholder="Search..." value={this.state.searchTerms} onChange={this.handleSearchTermsChange} onKeyDown={this.handleSearchKeyDown}  disabled={!this.state.login}/>

                            <ReactStrap.UncontrolledDropdown nav inNavbar>
                                <ReactStrap.DropdownToggle nav caret>
                                    <FaLock /> Account
                                </ReactStrap.DropdownToggle>
                                <ReactStrap.DropdownMenu right>
                                    <ReactStrap.DropdownItem tag={ReactRouter.Link} to="/login" disabled={this.state.login}>
                                        <IoIosLogIn /> Login
                                    </ReactStrap.DropdownItem>
                                    <ReactStrap.DropdownItem tag={ReactRouter.Link} to="/logout" disabled={!this.state.login}>
                                        <IoIosLogOut /> Logout
                                    </ReactStrap.DropdownItem>
                                    <ReactStrap.DropdownItem divider />
                                    {/*<ReactStrap.DropdownItem tag={ReactRouter.Link} to="/editprofile"  disabled={!this.state.login}>
                                        <FaEdit /> Change Login Details
                                    </ReactStrap.DropdownItem>*/}
                                    <ReactStrap.DropdownItem tag={ReactRouter.Link} to="/resetpassword"  disabled={false}>
                                        <IoIosSync/> Forgot Password
                                    </ReactStrap.DropdownItem>
                                    <ReactStrap.DropdownItem divider />
                                    <ReactStrap.DropdownItem tag={ReactRouter.Link} to="/deleteprofile"  disabled={true}>
                                        <FaUserTimes /> Deactivate Account
                                    </ReactStrap.DropdownItem>
                                    <ReactStrap.DropdownItem tag={ReactRouter.Link} to="/register"  disabled={this.state.login}>
                                        <IoMdPersonAdd /> Register
                                    </ReactStrap.DropdownItem>
                                </ReactStrap.DropdownMenu>
                            </ReactStrap.UncontrolledDropdown>

                            <ReactStrap.UncontrolledDropdown nav inNavbar>
                                <ReactStrap.DropdownToggle nav caret>
                                    <MdSettings /> Settings
                                </ReactStrap.DropdownToggle>
                                <ReactStrap.DropdownMenu right>
                                    <ReactStrap.DropdownItem tag={ReactRouter.Link} to="/preferences"  disabled={!this.state.login}>
                                        <FaCogs /> System Preferences
                                    </ReactStrap.DropdownItem>
                                    <ReactStrap.DropdownItem tag={ReactRouter.Link} to="/tutorial"  disabled={!this.state.login}>
                                        <FaInfoCircle /> Tutorial
                                    </ReactStrap.DropdownItem>
                                    <ReactStrap.DropdownItem tag={ReactRouter.Link} to="/credits"  disabled={!this.state.login}>
                                        <FaIdBadge /> Credits
                                    </ReactStrap.DropdownItem>
                                </ReactStrap.DropdownMenu>
                            </ReactStrap.UncontrolledDropdown>

                            <ReactStrap.UncontrolledDropdown nav inNavbar>
                                <ReactStrap.DropdownToggle nav caret>
                                    <FaDatabase /> My Info
                                </ReactStrap.DropdownToggle>
                                <ReactStrap.DropdownMenu right>
                                    <ReactStrap.DropdownItem onClick={editBio} to="/recordedit" disabled={!this.state.login}>
                                        <FaEdit /> Update My Personal Info
                                    </ReactStrap.DropdownItem>
                                    <ReactStrap.DropdownItem onClick={viewNotes} to="/notes" disabled={!this.state.login}>
                                        <GoNote /> Update My Notes
                                    </ReactStrap.DropdownItem>
                                    <ReactStrap.DropdownItem divider />
                                    <ReactStrap.DropdownItem onClick={listRecords} tag={ReactRouter.Link} to="/records" disabled={!this.state.login}>
                                        <FaFolderOpen />  Manage My Uploaded Records
                                    </ReactStrap.DropdownItem>
                                    <ReactStrap.DropdownItem tag={ReactRouter.Link} to="/uploads" disabled={!this.state.login}>
                                        <FaCloudUploadAlt /> Manage My Uploaded Files
                                    </ReactStrap.DropdownItem>
                                    <ReactStrap.DropdownItem divider />
                                    <ReactStrap.DropdownItem tag={ReactRouter.Link} to="/versiondefs" disabled={!this.state.login}>
                                        <FaClone /> Manage My Sharing Profiles
                                    </ReactStrap.DropdownItem>
                                    <ReactStrap.DropdownItem onClick={manageShares} to="/manageshares" disabled={!this.state.login}>
                                        <FaShareAlt /> Manage My Shares
                                    </ReactStrap.DropdownItem>
                                    <ReactStrap.DropdownItem divider />

                                    <ReactStrap.DropdownItem divider />
                                    <ReactStrap.DropdownItem onClick={viewHistory} to="/history" disabled={!this.state.login}>
                                        <MdHistory /> View Activity History
                                    </ReactStrap.DropdownItem>

                                </ReactStrap.DropdownMenu>
                            </ReactStrap.UncontrolledDropdown>
                        </ReactStrap.Nav>
                    </ReactStrap.Collapse>
                </ReactStrap.Navbar>

                <ResponseMessage/>

                <ReactRouter.Switch>

                    <ReactRouter.Route path="/" exact render={()=><Record />}/>
                    <ReactRouter.Route path="/login" render={()=><Login  loginStatusCallback={this.handleLogin}/>}/>
                    <ReactRouter.Route path="/logout" render={()=><Logout  loginStatusCallback={this.handleLogin}/>}/>
                    <ReactRouter.Route path="/register" render={()=><Register />}/>
                    <ReactRouter.Route path="/registersuccess" render={()=><RegisterSuccess />}/>
                    <ReactRouter.Route path="/resetpassword" render={()=><ResetPassword />}/>
                    <ReactRouter.Route path="/resetpasswordsuccess" render={()=><ResetPasswordSuccess />}/>
                    <ReactRouter.Route path="/preferences" render={()=><SystemPreferences/>}/>
                    <ReactRouter.Route path="/recordedit" render={()=><RecordEdit />}/>
                    <ReactRouter.Route path="/versiondefs" render={()=><VersionDefs />}/>
                    <ReactRouter.Route path="/records" render={()=><Records />}/>
                    <ReactRouter.Route path="/files" render={()=><Files />}/>
                    <ReactRouter.Route path="/manageshares" render={()=><ManageShares />}/>
                    <ReactRouter.Route path="/history" render={()=><History />}/>
                    <ReactRouter.Route path="/uploads" render={()=><Uploads />}/>
                    <ReactRouter.Route path="/orgdatamanager" render={()=><OrgDataManager />}/>
                    <ReactRouter.Route path="/record" exact render={()=><Record />}/>
                    <ReactRouter.Route path="/credits" exact render={()=><Credits />}/>
                    <ReactRouter.Route path="/notes" exact render={()=><Notes />}/>
                    <ReactRouter.Route path="/noteedit" exact render={()=><NoteRecordEdit />}/>
                    <ReactRouter.Route path="/noteview" exact render={()=><NoteRecordWrapper />}/>
                    <ReactRouter.Route path="/tutorial" exact render={()=><Tutorial />}/>

                    <ReactRouter.Route render={()=><PageNotFound />}/>

                </ReactRouter.Switch>

                <p/>
                <ResponseMessage/>
                <ReactStrap.Alert color="primary" className="ml-sm-auto" >
                    <Online>Online</Online><Offline>Offline</Offline> | {this.state.login ? 'Logged in' : 'Not logged in'}.
                </ReactStrap.Alert>
                <Detector
                    render={ ( {online} : {online : any} ) => {
                        if (online) {
                            //alert('Detector: Online');
                            //TODO: Check if login token is there, if not, go back to login page

                            //TODO: If the token is there do nothing. Because it means the user
                            // has not logout. We also do nothing when status changes to offline.

                            // TODO: Refresh the init process when offline changes to online (this is for sync purpose)
                            // Only run this when user logged in
                            //if (this.state.login) {
                            //    this.AppInit();
                            //}

                            //render nothing
                            return null;
                        } else {
                            //alert('Detector: Offline');
                            // Should we turn onlineAuth to false?
                            // probably not because it would cause inconvenience for the user to re-login
                            //Global.setOnlineAuthCookie(false);

                            //render nothing
                            return null;
                        }
                    }
                    }
                />

                <ReactStrap.Modal isOpen={this.state.leaveModal} toggle={this.toggleLeaveModal}>
                    <ReactStrap.ModalHeader toggle={this.toggle}>Unsaved Changes</ReactStrap.ModalHeader>
                    <ReactStrap.ModalBody>
                        Do you really want to log out?
                    </ReactStrap.ModalBody>
                    <ReactStrap.ModalFooter>
                        <ReactStrap.Button color="primary" onClick={this.leavePage}>Leave</ReactStrap.Button>{' '}
                        <ReactStrap.Button color="secondary" onClick={this.toggleLeaveModal}>Stay</ReactStrap.Button>
                    </ReactStrap.ModalFooter>
                </ReactStrap.Modal>
            </div>
        );
    }
}

export default MobX.inject('stores')(ReactRouter.withRouter(MobX.observer(AppBar)));