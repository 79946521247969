import * as React from 'react';

import * as ReactRouter from 'react-router-dom';
import * as ReactStrap from 'reactstrap';

import * as MobX from "mobx-react";
import {InterfaceStore} from "../store/InterfaceStore";
import {RecordkeepingStore} from "../store/RecordkeepingStore";
import {Global} from "../global/Global";
import Attachment from "./Attachment";
import TagWidget from "./TagWidget";
import {ISite, OrgDataStore} from "../store/OrgDataStore";
import Preview from "./Preview";
import {MdHighlightOff, MdOfflinePin} from "react-icons/all";
import LoadingSpinner from "./LoadingSpinner";

class RegisteredRecord extends React.Component <any, any> {

    readonly ifStore : InterfaceStore;
    readonly rkStore : RecordkeepingStore;
    readonly odStore : OrgDataStore;

    constructor(props : any) {
        super(props);

        this.state = {
            goRecords: false
        };

        this.ifStore = this.props.stores.ifStore;
        this.ifStore.message = null; // to prevent the message being shown when switching page

        this.rkStore = this.props.stores.rkStore;
        this.odStore = this.props.stores.odStore;
    }

    registerAttachment = () => {
        this.rkStore.registerSiteAttachment()
    }

    deregisterAttachment = () => {
        this.rkStore.deregisterSiteAttachment()
    }

    deregisterRecord = () => {
        this.odStore.deRegisterSiteRecordFromServer();
        this.setState({
            goRecords: true
        });
    }

    render() {

        if(this.state.goRecords) {
            return <ReactRouter.Redirect to="/records" />;
        }

        let content = this.rkStore.recordContent;

        let showField = (name : any) => {
            return (content && content[name] && content[name]['value'] && content[name]['versions'] && (content[name]['versions']).includes(this.props.versionSelected) )
        }

        let siteLabel:string = ''
        if (this.odStore.selectedSites) {
           let  matchingSite = this.odStore.selectedSites.find((site:ISite) => {
                return this.rkStore.record.site.tag === site.tag
            });

           if(matchingSite) {
               siteLabel = matchingSite.label;
           }
        }

        return (
            <ReactStrap.Container fluid={true}>

                {content &&
                    <div>
                        <ReactStrap.Row>
                            <ReactStrap.Col sm={11}>
                                <ReactStrap.Label><strong>Record from {siteLabel}</strong></ReactStrap.Label>
                            </ReactStrap.Col>
                            {!this.rkStore.recordAttachment &&
                                <ReactStrap.Col sm={1} className="text-right">
                                    <ReactStrap.Button outline color="primary"
                                                       onClick={() => this.deregisterRecord()}><MdHighlightOff/></ReactStrap.Button>
                                </ReactStrap.Col>
                            }
                        </ReactStrap.Row>

                        <div>
                            <p>{(this.rkStore.recordDescription)} </p>
                        </div>

                        {(content.myDate || content.myLabel || content.myDescription) &&
                            <hr/>
                        }

                        {content.myDate &&
                        <div>
                            <h5>Official date of record</h5>
                            <p>{Global.getDateString(this.rkStore.recordDate, this.rkStore.recordDateAccuracy)} </p>
                        </div>
                        }

                        {content.myLabel &&
                        <div>
                            <h5>Official record label</h5>
                            <p>{this.rkStore.record.label} </p>
                        </div>
                        }

                        {content.myDescription &&
                        <div>
                            <h5>Official Description</h5>
                            <p>{this.rkStore.record.description} </p>
                        </div>
                        }

                        <div>
                            <hr/>
                            {showField('notes') &&
                                <div>
                                    <h5>My notes</h5>
                                    <p>{content.notes && content.notes.value ? content.notes.value : "No notes."} </p>
                                </div>
                            }
                        </div>

                        {showField('tags') &&
                            <div>
                                <h5>Tags</h5>
                                <TagWidget readOnly={true}/>
                            </div>
                        }

                        <p/>

                        {this.rkStore.attachment &&
                        <div>
                            <ReactStrap.Row>
                                <ReactStrap.Col sm={10}>
                                    <ReactStrap.Label data-html2canvas-ignore> Copy stored on system, originally from {siteLabel}</ReactStrap.Label>
                                </ReactStrap.Col>
                                <ReactStrap.Col sm={2} className="text-right">
                                    <ReactStrap.Button outline color="primary" onClick={this.deregisterAttachment} data-html2canvas-ignore><MdHighlightOff/></ReactStrap.Button>
                                </ReactStrap.Col>
                            </ReactStrap.Row>
                            <p/>
                            <Attachment/>
                        </div>
                        }
                        {!this.rkStore.attachment && this.odStore.previewAttachment &&
                            <div>
                                <ReactStrap.Row>
                                    <ReactStrap.Col sm={10}>
                                        <ReactStrap.Label>Preview from {siteLabel}</ReactStrap.Label>
                                    </ReactStrap.Col>
                                    <ReactStrap.Col sm={2} className="text-right">
                                        <ReactStrap.Button outline color="primary" onClick={this.registerAttachment} data-html2canvas-ignore><MdOfflinePin/></ReactStrap.Button>
                                    </ReactStrap.Col>
                                </ReactStrap.Row>
                                <p/>
                                <Preview/>
                            </div>
                        }
                        {!this.rkStore.attachment && !this.odStore.previewAttachment &&
                            <LoadingSpinner shouldSpin={!this.rkStore.attachment && !this.odStore.previewAttachment} msg={'Fetching attachment (this may take some time)...'} />
                        }

                    </div>
                }

            </ReactStrap.Container>
        );
    }
}
export default MobX.inject('stores')(ReactRouter.withRouter(MobX.observer(RegisteredRecord)));