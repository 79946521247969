import * as React from 'react';
import * as ReactStrap from 'reactstrap';
import * as ReactRouter from "react-router-dom";
import {Global} from "../../global/Global";
import {InterfaceStore} from "../../store/InterfaceStore";
import * as MobX from "mobx-react";

class Register extends React.Component <any, any> {
    readonly ifStore : InterfaceStore;

    constructor(props : any) {
        super(props);

        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleCode = this.handleCode.bind(this);

        this.state = {
            registerStatus: '',
            showSpinner : false,
            allDone: false
        };

        this.ifStore = this.props.stores.ifStore;
        this.ifStore.message = null; // to prevent the message being shown when switching page
    }

    handleCode(event : any) {
        //THIS IS VERY IMPORTANT
        //https://stackoverflow.com/questions/39809943/react-preventing-form-submission
        event.preventDefault();

        let formData : any = new FormData(event.target);
        let jsonObject : any = {};
        for (const [key, value]  of formData.entries()) {
            jsonObject[key] = value;
        }
        let jsonData = JSON.stringify(jsonObject);

        let url = Global.getUrlPrefix() + 'anon/verifyregistration';

        fetch(url, {
            method: 'POST',
            mode: "cors",
            body: jsonData,
            headers: {
                'Content-Type': 'application/json'
            }
        }).then( response => {
            return response.json();
        }).then(responseObject => {
            if (responseObject.errors.length === 0) {
                this.setState({
                    allDone: true
                });
            } else {
                //So we can alert any error messages
                this.ifStore.message = responseObject;

                //so we can populate the question
                this.setState({
                    requestStatus: responseObject
                });
            }
        }).catch(function(this : Register, e : any) {
            this.ifStore.message = {error:'System error when registering.'};
            console.log("SOMETHING IS WRONG!", e);
        });
        event.preventDefault();
    }

    handleSubmit(event : any) {
        //THIS IS VERY IMPORTANT
        //https://stackoverflow.com/questions/39809943/react-preventing-form-submission
        event.preventDefault();

        this.setState({
            showSpinner : true,
        });

        const formData : any = new FormData(event.target);
        let jsonObject : any = {};
        for (const [key, value]  of formData.entries()) {
            jsonObject[key] = value;
        }
        let jsonData = JSON.stringify(jsonObject);

        let url = Global.getUrlPrefix() + 'anon/register';

        fetch(url, {
            method: 'POST',
            mode: "cors",
            body: jsonData,
            headers: {
                'Content-Type': 'application/json'
            }
        }).then(response => {
            return response.json();
        }).then(responseObject => {
            let response = JSON.stringify(responseObject);
            this.setState({
                registerStatus: response,
                showSpinner : false,
            });

            //So we can alert any success/error messages
            this.ifStore.message = responseObject;

        }).catch(function (this : Register, e : any) {
            this.ifStore.message = {error:'System error when registering.'};
            this.setState({
                showSpinner : false,
            });
            console.log("SOMETHING IS WRONG!", e);
        });
        event.preventDefault();
    }


    render() {
        if (this.state.allDone) {
            return(<ReactRouter.Redirect to="/registersuccess" />);
        } else return (
            <div>
                {this.state.showSpinner ? <ReactStrap.Spinner className={'m-4'} color="info"/> : null}
                <h2>Register</h2>
                <ReactStrap.Form onSubmit={this.handleSubmit}>
                    <ReactStrap.FormGroup row className={'m-2'}>
                        <ReactStrap.Label sm={2}>
                            Email
                        </ReactStrap.Label>
                        <ReactStrap.Col sm={10}>
                            <ReactStrap.Input type="email" id="email" name="email" placeholder="Email" />
                        </ReactStrap.Col>
                    </ReactStrap.FormGroup>

                    <ReactStrap.FormGroup row className={'m-2'}>
                        <ReactStrap.Label sm={2}> Password </ReactStrap.Label>
                        <ReactStrap.Col sm={10}>
                            <ReactStrap.Input type="password" id="password" name="password" placeholder="Password" />
                        </ReactStrap.Col>
                    </ReactStrap.FormGroup>

                    <ReactStrap.FormGroup row className={'m-2'}>
                        <ReactStrap.Label sm={2}> Repeat Password </ReactStrap.Label>
                        <ReactStrap.Col sm={10}>
                            <ReactStrap.Input type="password" id="password2" name="password2" placeholder="Repeat Password" />
                        </ReactStrap.Col>
                    </ReactStrap.FormGroup>

                    <ReactStrap.FormGroup row className={'m-2'}>
                        <ReactStrap.Label sm={2}> Question </ReactStrap.Label>
                        <ReactStrap.Col sm={10}>
                            <ReactStrap.Input type="text" id="question1" name="question1" placeholder="Question" />
                        </ReactStrap.Col>
                    </ReactStrap.FormGroup>

                    <ReactStrap.FormGroup row className={'m-2'}>
                        <ReactStrap.Label sm={2}> Answer </ReactStrap.Label>
                        <ReactStrap.Col sm={10}>
                            <ReactStrap.Input type="text" id="answer1" name="answer1" placeholder="Answer" />
                        </ReactStrap.Col>
                    </ReactStrap.FormGroup>
                    <ReactStrap.Button type="submit" className={'ml-4'}>Get Code</ReactStrap.Button>
                </ReactStrap.Form>

                <ReactStrap.Form onSubmit={this.handleCode}>
                    {/*<FormGroup row className={'m-2'}>*/}
                        {/*<Label sm={2}> Username </Label>*/}
                        {/*<Col sm={10}>*/}
                            {/*<Input type="text" id="username" name="username" placeholder="Username" />*/}
                        {/*</Col>*/}
                    {/*</FormGroup>*/}

                    <ReactStrap.FormGroup row className={'m-2'}>
                        <ReactStrap.Label sm={2}>
                            Received Code
                        </ReactStrap.Label>
                        <ReactStrap.Col sm={10}>
                            <ReactStrap.Input type="text" id="token" name="token" placeholder="Received Code" />
                        </ReactStrap.Col>
                    </ReactStrap.FormGroup>

                    <ReactStrap.Button type="submit" className={'ml-4'}>Register</ReactStrap.Button>
                </ReactStrap.Form>
            </div>
        );
    }
}
export default MobX.inject('stores')(ReactRouter.withRouter(MobX.observer(Register)));