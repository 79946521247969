import {Global} from "../global/Global";
import LocalDatabase from "../LocalDatabase";

export interface IResponse {
    api_version : string;
    messages : string;
    errors : [];
    content : string;
}

export interface ISync {
    local_db_name : string; //Use 'N/A' if no local storage required
}

//export interface IContent {
//    modifiedTime: number;
//}

export interface ICallbackAPI {
    () : void;
}

export interface ICallbackConstructObj {
    () : any;
}

export default abstract class BaseDao {

    syncObj : ISync;
    responseObj : IResponse;

    constructor() {
        this.syncObj = {
            local_db_name : Global.NO_DB_NAME,
        };

        this.responseObj = {
            api_version : "",
            messages : "",
            errors : [],
            content : "",
        };
    }

    //TODO: implement this function in child DAO
    // This function is used to execute the API to push content to remote server
    abstract pushAPI(content : any) : Promise<any>;

    //TODO: implement this function in child DAO
    // This function is used to execute the API to fetch content from remote server
    abstract fetchAPI() : Promise<any>;

    //TODO: implement this function in child DAO
    // This function is used to construct the default data in two situations:
    // (1) The app is online, and trying to fetch data from server, but the data is not available in the server due to
    // reasons such as for brand new users who do not have some certain information stored in server
    // (2) The app is offline, and trying to fetch data from local storage, but the data is not available in the local
    // storage yet
    abstract constructDefaultObj() : any;

    //TODO: implement this function in child DAO
    // This function is used in only one situation: The app is not online AND the user is saving the data;
    // in this situation the content object needs to be created (with the current time),
    // this content object is then being stored in the local database for offline usages as well as future online sync
    abstract constructContentObj(content : any) : any;

/*    pushDefault () {

        this.pushAPI(JSON.stringify(this.constructDefaultObj()))
            .then(responseObject => {
                alert ("FIX " + JSON.stringify(responseObject.json()));
            }).catch( (e) => {
                alert ('CANT FIX ' + e);
            }
        );
    }*/

    pushBase (content : any) {//WORKING ON pushBase
        //alert ('PUSH BASE');
        let promise : Promise<any>;
        if (Global.isOnline()) { // ONLINE
            //TODO: push the data to server
            promise = this.pushAPI(content).then(response => {
                return response.json();
            }).then(responseObject => {
                //console.log('responseObject', responseObject);
                this.responseObj = responseObject as IResponse;
                //TODO: sync if necessary
                if (this.needSync()) {
                    this.goSync();
                }
                return this.responseObj;
            }).catch( (e) => {
                return this.promiseNormalisedResponse('pushing data', e.message);
            });
            //return promise;
        } else { // OFFLINE
            //TODO: simply store data to local DB if there is no internet
            //this will need to be a promise too
            if (this.needSync()) {
                let contentObj : string = JSON.stringify(this.constructContentObj(content));
                this.setLocal(contentObj);
            }
            let responseObj : IResponse = this.responseObj;
            promise = new Promise<any>(function(resolve, reject) {
                return (responseObj);
            });
            //return promise;
        }

        return promise.then((responseObj : any) => {
            //alert ('pushBase responseObj return = ' + responseObj.toString());
            return this.promiseNormalisedResponse('pushing data', responseObj);
        }).catch( (e) => {
            return this.promiseNormalisedResponse('pushing data', e.message);
        });
    }

    fetchBase () {
        //alert ('Fetch Base');
        let promise : Promise<any>;
        if (Global.isOnline()) { // ONLINE
            //TODO: get the data from server
            promise = this.fetchAPI().then(response => {
                return response.json();
            }).then(responseObject => {

                //TODO: If the server does not have this record yet
                if (Global.isJsonObjEmpty(JSON.parse(responseObject.content))) {
                    responseObject.content = JSON.stringify(this.constructDefaultObj());
                }

                this.responseObj = responseObject as IResponse;
                //console.log('this.responseObj??????', this.responseObj);

                //TODO: sync if necessary
                if (this.needSync()) {
                    this.goSync();
                }
                return this.responseObj;
            }).catch( (e) => {
                return this.promiseNormalisedResponse('getting settings', e.message);
            });
            //return promise;
        } else { // OFFLINE
            let responseObj : IResponse = this.responseObj;
            //TODO: when offline get the data from local DB if available, if not use default settings
            //Constructing responseObj based on local storage data, this will need to be a promise too.
            //If the data is not required to be available offline,
            //responseObj remains its default values given in BaseDao constructor
            if (this.needSync()) { //OFFLINE
                this.getLocal();
            }
            promise = new Promise<any>(function(resolve, reject) {
                return (responseObj);
            });
            //return promise;
        }

        return promise.then((responseObj : any) => {
            //console.log('responseObj', responseObj);
            return this.promiseNormalisedResponse('getting data', responseObj);
        }).catch( (e) => {
            return this.promiseNormalisedResponse('getting data', e.message);
        });
    }

    // return true if local storage is required
    needSync() {
        if (this.syncObj.local_db_name === Global.NO_DB_NAME) {
            return false;
        }
        return true;
    }

    // if server data is newer, update local with server data
    // otherwise upload local data ot server
    // remember to change the responseObj when local data is newer
    // This goSync function is being called only when it is online, 2 things to notice are:
    // (1) if this function is called in pushBase, and nothing in local database: the pushed data
    //   will be stored in local database
    // (2) if this function is called in fetchBase, and nothing in local database: the new data
    //  fetched from server will be stored in local database
    goSync() {
        //alert ('GOSYNC');
        //console.log("goSync local_db_name = ", this.syncObj.local_db_name);
        LocalDatabase.table(this.syncObj.local_db_name)
            .get(1)
            .then((localObj) => { // RECORD IS IN LOCAL DB
                 if (localObj === undefined) {
                     //alert ('localObj Undefined');
                 }
                 //The record exist in local database, extract and use these
                 let localContentObj : any = JSON.parse(localObj.content);
                 let localModifiedTime : number = localContentObj.modifiedTime;


                 if (this.responseObj === undefined) {
                     //alert ('this.responseObj Undefined');
                 }

                 //(may need) force logout when response obj empty
                 let serverContentObj : any = JSON.parse(this.responseObj.content);
                 let serverModifiedTime : number = serverContentObj.modifiedTime;

                 //console.log("goSync localModifiedTime = ", localModifiedTime);
                 //console.log("goSync serverModifiedTime = ", serverModifiedTime);
                 if (localModifiedTime > serverModifiedTime) { // local newer
                     // update responseObj
                     this.responseObj = localObj;
                     // local data is newer, upload local data to server
                     let content = this.responseObj.content;
                     this.pushAPI(content)
                         .then(response => {
                         return response.json();
                     }).then(responseObject => {
                     }).catch( (e) => {
                     });
                 } else if (localModifiedTime < serverModifiedTime) { // local older
                     // local data is older, update local data
                     this.setLocal(this.responseObj.content);
                     /*LocalDatabase.table(this.syncObj.local_db_name)
                         .put(this.responseObj,1)
                         .then((id : any) => {
                             console.log("LOCAL IS OLDER: STORE NEW DATA TO LOCAL DATABASE DONE! ID = " + id);
                             LocalDatabase.table(this.syncObj.local_db_name)
                                 .get(1)
                                 .then((localObj) => {
                                     console.log("LOCAL IS OLDER: TEST IF DATA IS ACTUALLY STORED", localObj);
                                 }).catch((e) => {
                                    console.log('NOTHING IS STORED WHEN LOCAL IS OLDER', e);
                             });
                         });*/
                 } else { // same time
                     // DO NOTHING
                 }
             })
             .catch( (e) => { // RECORD NOT IN LOCAL DB
                 // When there is no local data no need to sync
                 //console.log('no local data found', e);
                 //alert ('No Local Data');
                 // in this situation, simply just write the online data to local database
                 this.setLocal(this.responseObj.content);
                 /*LocalDatabase.table(this.syncObj.local_db_name)
                     .put(this.responseObj,1)
                     .then((id : any) => {
                         console.log("NO LOCAL FOUND: STORE NEW DATA TO LOCAL DATABASE DONE! ID = " + id);
                         LocalDatabase.table(this.syncObj.local_db_name)
                             .get(1)
                             .then((localObj) => {
                                 console.log("NO LOCAL FOUND: TEST IF DATA IS ACTUALLY STORED", localObj);
                             }).catch((e) => {
                             console.log('NOTHING IS STORED WHEN NO LOCAL FOUND', e);
                         });
                     });*/
             })
    }

    //get data from local database when offline
    getLocal() {
        LocalDatabase.table(this.syncObj.local_db_name)
            .get(1)
            .then((localObj) => {
                //change the responseObj
                this.responseObj = localObj;
            })
            .catch( (e) => {
                //TODO: no local data found, just use default values (app offline & no local found).
                this.responseObj.content = JSON.stringify(this.constructDefaultObj());
            });
    }

    //save data to local database when offline
    setLocal(contentObj : any) {
        this.responseObj.content = contentObj;
        LocalDatabase.table(this.syncObj.local_db_name)
            .put(this.responseObj,1)
            .then((id : any) => {
                //console.log("STORE NEW DATA TO LOCAL DATABASE DONE! ID = " + id);
            });
    }

    normaliseResponse = (action: String, response: any)  => {
        let result: { message: String, errors: Array<String>, content: any } = {
            message: "",
            errors: [],
            content: {}
        };

        if(typeof(response) == 'string') {
            result.message = 'Internal Error'
            result.errors.push(response)
        } else if(response.error) {
            result.message = 'Error - ' + action + ': ' + response.error;
            result.errors.push(response.message)
        } else if(response.errors && response.errors.length > 0) {
            result = response;
        } else if(response instanceof Blob) {
            result.message = 'Success - ' + action;
            result.content = response
        } else if(response.constructor === Array) {
            result.message = 'Success - ' + action;
            result.content = response
        } else {
            result.message = 'Success - ' + action;
            //console.log('response in base dao', response);
            result.content = response.content;
        }
        return result;

    };

    promiseNormalisedResponse = (action: String, response: any) => {
        let result = this.normaliseResponse(action, response)
        let promise = new Promise(function(resolve, reject) {
            resolve(result)
        });

        return promise;

    };
}