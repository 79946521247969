import * as React from 'react';
import * as ReactStrap from 'reactstrap';
import * as MobX from "mobx-react";
import * as ReactRouter from "react-router-dom";
import {InterfaceStore} from "../store/InterfaceStore";

class ResponseMessage extends React.Component <any, any> {

    readonly ifStore : InterfaceStore;

    constructor(props : any) {

        super(props);

        this.ifStore = this.props.stores.ifStore;
        this.onDismiss = this.onDismiss.bind(this);
    }

    onDismiss() {
        this.ifStore.messageVisible = false;
    }

    render() {
        /*
         * We must have some content:
         *
         * Either:
         *  message:"foo" or
         *  error:"bar" or
         *  message:"foo", errors:["foo","bar",...]
         *
         */
        let content = this.ifStore.message
        if (!content ) {
            return null;
        }

        let messageBody : any;
        let messageType : any;

        if (content.error) {
            messageBody = content.error;
            messageType = 'danger';
        } else  if (content.errors && content.errors.length > 0) {
            messageBody = <div>
                            {content.message }
                            <ul>
                                {content.errors.map((value : any, index: any) => {
                                    return <li key={index}>{value}</li>
                                })}
                            </ul>
                        </div>
            messageType = 'danger';
        } else {
            messageBody = content.message;
            messageType = 'success';
        }

        /*isOpen={this.props.stores.ifStore.responseMessageVisible} toggle={this.onDismiss}*/

        return (
            <div>
                <ReactStrap.Alert color={messageType} isOpen={this.ifStore.messageVisible} toggle={this.onDismiss}>
                    {messageBody}
                </ReactStrap.Alert>
            </div>
        );
    }
}

export default MobX.inject('stores')(ReactRouter.withRouter(MobX.observer(ResponseMessage)));

