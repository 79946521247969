import * as React from 'react';
import * as ReactStrap from 'reactstrap';
import {Global} from "../global/Global";
import {MdArrowBack} from 'react-icons/md';
import * as ReactRouter from "react-router-dom";
import * as MobXR from "mobx-react";
import VersionListItem from '../components/VersionListItem'
import * as Emoji from "emoji-mart";
import moment from 'moment';
import {InterfaceStore} from "../store/InterfaceStore";
import {RecordkeepingStore} from "../store/RecordkeepingStore";
import {Label} from "reactstrap";

class ManageShares extends React.Component <any, any> {
    toggle : any

    readonly ifStore : InterfaceStore;
    readonly rkStore : RecordkeepingStore;

    constructor(props : any) {
        super(props);

        this.state = {
            goRecord: false,
            editModal: false,
            deleteModal: false,
            selectedVersion: null,
            expiryCode: '',
            dateExpired: null,
            allVersions: false,
        };

        this.ifStore = this.props.stores.ifStore;
        this.ifStore.message = null; // to prevent the message being shown when switching page

        this.rkStore = this.props.stores.rkStore;
    }


    /***********************************************************************
     * Edit methods
     ***********************************************************************/
    handleEdit = (version : any) => {
        this.setState({
            selectedVersion: version
        });

        this.toggleEditModal();
    }

    toggleEditModal = () => {
        this.setState((prevState : any) => ({
            editModal: !prevState.editModal,
            dateExpired: '',
            expiryCode: '',

        }));
    }

    handleFieldChange = (event : any) => {

        if (event.target.value && event.target.value.length > 1) {
            this.setState({
                dateExpired: event.target.value
            });
        } else {
            this.setState({
                expiryCode: event.target.value
            });
        }
    }

    getExpiryDate = (expiryString : any) => {
        let result : any = '';
        let amount : any = '1';
        let unit : any = expiryString;

        if(!unit || unit === "e") {
            unit = "years";
            amount = "900"
        }

        if(!unit || unit === "d") {
            unit = "days"
        }

        if(!unit || unit === "w") {
            unit = "weeks"
        }

        if(!unit || unit === "m") {
            unit = "months"
        }

        if(!unit || unit === "c") {
            result = this.state.dateExpired + " 23:59:59"
        } else {
            result = moment().add(amount, unit).format("YYYY-MM-DD HH:mm:ss");
        }

        return result;
    }

    submitEdit = (event : any) => {
        //THIS IS VERY IMPORTANT
        //https://stackoverflow.com/questions/39809943/react-preventing-form-submission
        event.preventDefault();

        this.setState({
            showSpinner : true,
        });

        let payload = JSON.stringify({
            dateExpired: this.getExpiryDate(this.state.expiryCode)
        });

        this.rkStore.updateRecordVersion(this.state.selectedVersion, payload);

        this.setState({
            showSpinner : false,
        });

        this.toggleEditModal();

        event.preventDefault();
    }
    
    /***********************************************************************
     * Delete methods
     ***********************************************************************/
    handleDelete = (version : any) => {
        this.setState({
            selectedVersion: version
        });

        this.toggleDeleteModal();
    }

    toggleDeleteModal = () => {
        this.setState((prevState : any) => ({
            deleteModal: !prevState.deleteModal,
        }));
    }

    submitDelete = (event : any) => {
        //THIS IS VERY IMPORTANT
        //https://stackoverflow.com/questions/39809943/react-preventing-form-submission
        event.preventDefault();

        this.setState({
            showSpinner : true,
        });

        this.rkStore.deleteRecordVersion(this.state.selectedVersion)

        this.setState({
            selectedVersion: null,
            showSpinner : false,
        });

        this.toggleDeleteModal();

        event.preventDefault();
    }

    handleAllVersionsChange = (event : any) => {
        this.setState({
            allVersions: event.target.checked
        })
    }

    render() {
        if (Global.getOfflineAuthCookie() === false && Global.getOnlineAuthCookie() === false) {
            //alert ('You are not login!!!');
            return <ReactRouter.Redirect to="/login" />;}

        if(this.state.goRecord) {
            if (this.state.allVersions) {
                return <ReactRouter.Redirect to="/records"/>;
            } else {
                return <ReactRouter.Redirect to="/record"/>;
            }
        }

        let recordId = Global.idFromUrl(this.rkStore.record.href ? this.rkStore.record.href  : '')

        let prompt = 'Show version shares of all records';
        if (this.state.allVersions) {
            prompt = 'Show only version shares of current record: (' + this.rkStore.record.label + ')';
        }

        let label = this.rkStore.record.label
        if (this.state.allVersions) {
            label = 'All records'
        }

        //filter out housekeeping records
        let recordSet = this.rkStore.records.filter((item:any) => {
            return item.recordcategory !== "Recordkeeping"
        });

        //...aaaand sort on label
        recordSet = recordSet.sort( (recA:any, recB:any) => {
            let a = recA.label.toLowerCase();
            let b = recB.label.toLowerCase();

            return a > b ? 1 : b > a ? -1 : 0;
        })


        return (
            <ReactStrap.Container fluid={true}>
                <ReactStrap.Row style={{padding: "10px"}}>
                    <ReactStrap.Col sm={10}>
                        <h2 className={'m-2'}>Manage Shared Versions: {label}</h2>
                    </ReactStrap.Col>
                    <ReactStrap.Col className="text-right">
                        <ReactStrap.Button outline color="primary" onClick={() => {
                            this.setState({
                                goRecord: true,
                            })
                        }}> <MdArrowBack /></ReactStrap.Button>
                    </ReactStrap.Col>

                </ReactStrap.Row>

                {this.rkStore.records && (this.rkStore.records.length > 0) &&
                    <div>
                        <ReactStrap.Row>
                            <ReactStrap.Col sm={12}>
                                <ReactStrap.CustomInput type="switch" id="allVersions" name="allVersions" label={prompt} value={this.state.allVersions} onChange={this.handleAllVersionsChange} />
                            </ReactStrap.Col>
                        </ReactStrap.Row>
                        <p/>
                    </div>
                }

                <ReactStrap.Row>
                    <ReactStrap.Col sm={12}>
                        Use the buttons on the right of each line to see, what the public view looks like, change the expiry date, or delete the share.
                        To change other aspects of the share (e.g. security code, permission statement, and so on) you will need to delete the view here and re-create it on the profile page.<br/>
                    </ReactStrap.Col>
                </ReactStrap.Row>

                <hr/>
                <ReactStrap.Row>
                    <ReactStrap.Col xs={3}>
                        <ReactStrap.Label><strong>Version</strong></ReactStrap.Label>
                    </ReactStrap.Col>
                    <ReactStrap.Col xs={2}>
                        <ReactStrap.Label><strong>Shared With</strong></ReactStrap.Label>
                    </ReactStrap.Col>
                    <ReactStrap.Col xs={2}>
                        <ReactStrap.Label><strong>View expires on</strong></ReactStrap.Label>
                    </ReactStrap.Col>
                    <ReactStrap.Col xs={2}>
                        <ReactStrap.Label><strong>Security Code</strong></ReactStrap.Label>
                    </ReactStrap.Col>
                    < ReactStrap.Col xs={2}>
                        <ReactStrap.Label><strong>Actions</strong></ReactStrap.Label>
                    </ReactStrap.Col>

                </ReactStrap.Row>
                {this.rkStore.versions && this.rkStore.versions[recordId] && !this.state.allVersions &&
                    <div>
                        {(this.rkStore.versions[recordId]).map((version : any) =>
                                <VersionListItem key={version.href} version={version} editVersion={this.handleEdit} deleteVersion={this.handleDelete} showButtons={true}/>
                            )
                        }
                    </div>
                }

                {this.rkStore.versions && this.state.allVersions &&
                <div>
                    {(recordSet).map((rec : any) =>
                        <div key={rec.label}>
                            {this.rkStore.versions[Global.idFromUrl(rec.href)] && (this.rkStore.versions[Global.idFromUrl(rec.href)]).length > 0 &&
                                <div>
                                    <Label style={{fontWeight: 'bold', textDecoration: 'underline'}}>{rec.label}</Label>
                                    {(this.rkStore.versions[Global.idFromUrl(rec.href)]).map((version : any) =>
                                        <VersionListItem key={version.href} version={version} editVersion={this.handleEdit} deleteVersion={this.handleDelete} showButtons={true}/>
                                    )}
                                </div>
                            }
                        </div>
                    )}
                </div>
                }

                {this.state.deleteModal && this.state.selectedVersion &&
                    <ReactStrap.Modal isOpen={this.state.deleteModal} toggle={this.toggleDeleteModal}>
                        <ReactStrap.ModalHeader toggle={this.toggle}>Delete version Share</ReactStrap.ModalHeader>
                        <ReactStrap.ModalBody>
                            Are you sure you want to delete this version share?
                            <hr/>
                                <ReactStrap.Row style={{paddingLeft: "10px"}}>
                                    <Emoji.Emoji emoji={this.state.selectedVersion.vdIcon} size={20}/>&nbsp;<ReactStrap.Label>{this.state.selectedVersion.vdLabel}</ReactStrap.Label>
                                </ReactStrap.Row>
                                <ReactStrap.Row style={{paddingLeft: "10px"}}>
                                    <ReactStrap.Label>Shared with: {this.state.selectedVersion.tag}</ReactStrap.Label>
                                </ReactStrap.Row>
                                <ReactStrap.Row style={{paddingLeft: "10px"}}>
                                    <ReactStrap.Label>Expires on: {moment(this.state.selectedVersion.expireDate).format("ddd, MMM Do YYYY")}</ReactStrap.Label>
                                </ReactStrap.Row>
                        </ReactStrap.ModalBody>
                        <ReactStrap.ModalFooter>
                            <ReactStrap.Button color="primary" onClick={this.submitDelete}>Delete</ReactStrap.Button>{' '}
                            <ReactStrap.Button color="secondary" onClick={this.toggleDeleteModal}>Cancel</ReactStrap.Button>
                        </ReactStrap.ModalFooter>
                    </ReactStrap.Modal>
                }

                {this.state.editModal &&
                <ReactStrap.Modal isOpen={this.state.editModal} toggle={this.toggleEditModal}>
                    <ReactStrap.ModalHeader toggle={this.toggle}>Update Share Expiry</ReactStrap.ModalHeader>
                    <ReactStrap.ModalBody>
                        <ReactStrap.Form >
                            <ReactStrap.Row>
                                <ReactStrap.Col sm={12}>
                                    <ReactStrap.Label>Viewing Time</ReactStrap.Label>
                                </ReactStrap.Col>
                            </ReactStrap.Row>
                            <ReactStrap.Row>
                                <ReactStrap.Col sm={12}>
                                    For how long should this share be available?
                                </ReactStrap.Col>
                            </ReactStrap.Row>
                            <ReactStrap.Row className={'m-2'}>
                                <ReactStrap.Col sm={8}>
                                    <ReactStrap.FormGroup check>
                                        <ReactStrap.Label check>
                                            <ReactStrap.Input type="radio" name="expiry" value="d"
                                                   checked={this.state.expiryCode === 'd'}
                                                   onChange={this.handleFieldChange}
                                            />
                                            1 Day
                                        </ReactStrap.Label>
                                    </ReactStrap.FormGroup>
                                    <ReactStrap.FormGroup check>
                                        <ReactStrap.Label check>
                                            <ReactStrap.Input type="radio" name="expiry" value="w"
                                                   checked={this.state.expiryCode === 'w'}
                                                   onChange={this.handleFieldChange}
                                            />
                                            1 Week
                                        </ReactStrap.Label>
                                    </ReactStrap.FormGroup>
                                    <ReactStrap.FormGroup check >
                                        <ReactStrap.Label check>
                                            <ReactStrap.Input type="radio" name="expiry" value="m"
                                                   checked={this.state.expiryCode === 'm'}
                                                   onChange={this.handleFieldChange}
                                            />
                                            1 Month
                                        </ReactStrap.Label>
                                    </ReactStrap.FormGroup>
                                    <ReactStrap.FormGroup check >
                                        <ReactStrap.Label check>
                                            <ReactStrap.Input type="radio" name="expiry" value="e"
                                                   checked={this.state.expiryCode === 'e'}
                                                   onChange={this.handleFieldChange}
                                            />
                                            For Ever
                                        </ReactStrap.Label>
                                    </ReactStrap.FormGroup>
                                    <ReactStrap.FormGroup check>
                                        <ReactStrap.Label check>
                                            <ReactStrap.Input type="radio" name="expiry" value="c"
                                                   checked={this.state.expiryCode === 'c'}
                                                   onChange={this.handleFieldChange}
                                            />
                                            Until Custom Date
                                        </ReactStrap.Label>
                                    </ReactStrap.FormGroup>
                                </ReactStrap.Col>

                                {this.state.expiryCode === 'c' &&
                                <ReactStrap.Input
                                    type="date"
                                    name="customViewDate"
                                    id="customViewDate"
                                    placeholder="1/1/2019"
                                    value={this.state.dateExpired} onChange={this.handleFieldChange}
                                />
                                }

                            </ReactStrap.Row>
                        </ReactStrap.Form>
                    </ReactStrap.ModalBody>
                    <ReactStrap.ModalFooter>
                        <ReactStrap.Button color="primary" onClick={this.submitEdit}>Save</ReactStrap.Button>{' '}
                        <ReactStrap.Button color="secondary" onClick={this.toggleEditModal}>Cancel</ReactStrap.Button>
                    </ReactStrap.ModalFooter>
                </ReactStrap.Modal>
                }

            </ReactStrap.Container>
        )
    }
}
export default MobXR.inject('stores')(ReactRouter.withRouter(MobXR.observer(ManageShares)));