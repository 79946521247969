import * as React from 'react';

import * as ReactRouter from 'react-router-dom';
import * as ReactStrap from 'reactstrap';

import * as MobX from "mobx-react";
import {InterfaceStore} from "../store/InterfaceStore";
import {RecordkeepingStore} from "../store/RecordkeepingStore";
import {Global} from "../global/Global";
import NoteRecord from "./NoteRecord";

class NoteRecordWrapper extends React.Component <any, any> {
    readonly ifStore : InterfaceStore;
    readonly rkStore : RecordkeepingStore;

    constructor(props : any) {
        super(props);

        this.ifStore = this.props.stores.ifStore;
        this.ifStore.message = null; // to prevent the message being shown when switching page

        this.rkStore = this.props.stores.rkStore;
    }

    render() {

        //Redirects
        if (Global.getOfflineAuthCookie() === false && Global.getOnlineAuthCookie() === false) {
            //alert ('You are not login!!!');
            return <ReactRouter.Redirect to="/login" />;
        }

        return (
            <ReactStrap.Container fluid={true}>

                <NoteRecord recordItem={this.rkStore.record}/>

            </ReactStrap.Container>
        );
    }
}
export default MobX.inject('stores')(ReactRouter.withRouter(MobX.observer(NoteRecordWrapper)));
