import * as React from 'react';

import * as ReactRouter from 'react-router-dom';
import * as ReactStrap from 'reactstrap';

import * as MobX from "mobx-react";
import {InterfaceStore} from "../store/InterfaceStore";
import {RecordkeepingStore} from "../store/RecordkeepingStore";

import Attachment from "./Attachment";
import TagWidget from "./TagWidget";
import LoadingSpinner from "./LoadingSpinner";

class PersonalRecord extends React.Component <any, any> {

    readonly ifStore : InterfaceStore;
    readonly rkStore : RecordkeepingStore;

    constructor(props : any) {
        super(props);

        this.ifStore = this.props.stores.ifStore;
        this.ifStore.message = null; // to prevent the message being shown when switching page

        this.rkStore = this.props.stores.rkStore;
    }

    render() {

        let content = this.rkStore.recordContent;

        let showField = (name : any) => {
            return (content && content[name] && content[name]['value'] && content[name]['versions'] && (content[name]['versions']).includes(this.props.versionSelected) )
        }

        return (
            <ReactStrap.Container fluid={true}>

                {content &&
                    <div>
                        <div>
                            <p>{this.rkStore.recordDescription} </p>
                        </div>

                        <hr/>


                        {showField('notes') &&
                            <div>
                                <h5>Notes</h5>
                                <p>{content.notes && content.notes.value ? content.notes.value : "No notes."} </p>
                            </div>
                        }

                        {showField('tags') &&
                            <div>
                                <h5>Tags</h5>
                                <TagWidget readOnly={true}/>
                            </div>
                        }

                        <p/>

                        {this.rkStore.record.recordAttachments && this.rkStore.record.recordAttachments[0] && !this.rkStore.recordAttachment &&
                            <LoadingSpinner shouldSpin={!this.rkStore.attachment } msg={'Fetching attachment (this may take some time)...'} />
                        }

                        <Attachment/>

                    </div>
                }

            </ReactStrap.Container>
        );
    }
}
export default MobX.inject('stores')(ReactRouter.withRouter(MobX.observer(PersonalRecord)));