import * as React from "react";
import * as ReactStrap from 'reactstrap';

export class File extends React.Component <any, any> {
    FileInfo: any;
    constructor(props:any) {
        super(props);
        this.FileInfo = props.FileInfo;
    }

    render() {
        return (
            <ReactStrap.Card body className={'m-3'} outline color={'info'}>
                <ReactStrap.CardTitle><strong>File Name:</strong> {this.FileInfo.FileName}</ReactStrap.CardTitle>
                <ReactStrap.CardText><strong>Description:</strong> {this.FileInfo.FileDesc}</ReactStrap.CardText>
                <ReactStrap.Button className={'m-1'}
                        onClick = {() => {
                            alert('Details of file: ' + this.FileInfo.FileName);
                        }}
                >
                    File Detail
                </ReactStrap.Button>
            </ReactStrap.Card>);
    }
}