import * as React from 'react';

import * as ReactRouter from 'react-router-dom';
import * as ReactStrap from 'reactstrap';
import cat from '../assets/cat.jpg';

import * as MobX from "mobx-react";
import {FaEnvelope, FaHome, FaUser, FaTransgenderAlt, FaPhone, FaChild,FaPaw, } from "react-icons/fa";
import {MdLocalHospital, MdPeople, MdCheck, MdClear} from 'react-icons/md';
import {GoAlert, GoCalendar} from 'react-icons/go';
import {TiThumbsUp, TiThumbsDown} from 'react-icons/ti';
import {IoIosTrendingUp, IoIosBulb, IoIosPeople, IoIosAlert, IoIosHappy} from 'react-icons/io';
import {InterfaceStore} from "../store/InterfaceStore";
import {RecordkeepingStore} from "../store/RecordkeepingStore";


class BioRecord extends React.Component <any, any> {

    readonly ifStore : InterfaceStore;
    readonly rkStore : RecordkeepingStore;

    constructor(props : any) {
        super(props);
        this.state = {
            pictureSrc: null,
        };
        this.ifStore = this.props.stores.ifStore;
        this.ifStore.message = null; // to prevent the message being shown when switching page

        this.rkStore = this.props.stores.rkStore;

        this.getGender = this.getGender.bind(this);
    }
    
    getGender(genderTag : any) {
        let gender = "";

        if (genderTag === "f") {
            gender = "Female";
        }

        if (genderTag === "m") {
            gender = "Male";
        }

        if (genderTag === "o") {
            gender = "Other";
        }

        if (genderTag === "p") {
            gender = "Prefer not to say";
        }

        return gender;
    }

    render() {

        let content = this.rkStore.recordContent;

        let pictureSrc = this.rkStore.attachment ? this.rkStore.attachment : cat;

/*        fetch(pictureUrl)
            .then(response => response.blob())
            .then(blob => new Promise((resolve, reject) => {
                const reader = new FileReader()
                reader.readAsDataURL(blob)
                reader.onloadend = () => {
                    if(reader.result) {
                        this.setState({
                            pictureSrc: reader.result
                        })
                    }
                }
            }))*/


        let showField = (name : any) => {
            return (content && content[name] && content[name]['value'] && content[name]['versions'] && (content[name]['versions']).includes(this.props.versionSelected) )
        }
        
        return (
            <ReactStrap.Container fluid={true}>

                {content && this.rkStore.record.label &&
                    <div>
                        <ReactStrap.Row className={'m-2'}>
                            <ReactStrap.Col xs={'auto'}>

                                {showField('image') &&
                                    <img src={pictureSrc} alt="avatar" className="rounded-circle" width="120" height="120"/>
                                }
                            </ReactStrap.Col>
                            <ReactStrap.Col xs={'auto'}>
                                <h5>
                                    {(showField('fullname') || showField('prefname')) &&
                                    <FaUser/>
                                    }
                                    {showField('fullname') &&
                                    <span> {content['fullname'].value} </span>
                                    }
                                    {showField('prefname') &&
                                    <span>({content['prefname'].value}) </span>
                                    }
                                </h5>
                                {showField('gender') &&
                                <h5><FaTransgenderAlt/> <span>{this.getGender(content['gender'].value)} </span></h5>
                                }
                                {showField('age') &&
                                <h5><GoCalendar/> <span>{content['age'].value} </span></h5>
                                }
                            </ReactStrap.Col>

                        </ReactStrap.Row>

                        {(showField('contactme') || showField('phone') || showField('email') || showField('Address') || showField('emergcontact')) &&
                        <div>
                            <hr/>
                            <h3 className={'m-2'}>Contact me</h3>
                        </div>
                        }

                        {showField('contactme') &&
                        <span>{content['contactme'].value} </span>
                        }
                        {showField('phone') &&
                        <h5><FaPhone/> <span>{content['phone'].value} </span></h5>
                        }
                        {showField('email') &&
                        <h5><FaEnvelope/> <span>{content['email'].value} </span></h5>
                        }
                        {showField('address') &&
                        <h5><FaHome/> <span>{content['address'].value} </span></h5>
                        }
                        {showField('emergcontact') &&
                        <h5><GoAlert/> <span>{content['emergcontact'].value}</span></h5>
                        }

                        {(showField('story') || showField('goals') || showField('health') || showField('interests')) &&
                        <div>
                            <hr/>
                            <h3 className={'m-2'}>About me</h3>
                        </div>
                        }

                        {showField('story') &&
                        <div>
                            <h5><FaChild/> My Story</h5>
                            <p>{content['story'].value} </p>
                        </div>
                        }
                        {showField('goals') &&
                        <div>
                            <h5><IoIosTrendingUp/> My Goals</h5>
                            <p>{content['goals'].value} </p>
                        </div>
                        }
                        {showField('health') &&
                        <div>
                            <h5><MdLocalHospital/> My Health </h5>
                            <p>{content['health'].value} </p>
                        </div>
                        }
                        {showField('interests') &&
                        <div>
                            <h5><IoIosBulb/> My Interests</h5>
                            <p>{content['interests'].value} </p>
                        </div>
                        }

                        {(showField('family') || showField('friends') || showField('pets')) &&
                        <div>
                            <hr/>
                            <h3 className={'m-2'}>My Network</h3>
                        </div>
                        }

                        {showField('family') &&
                        <div>
                            <h5><IoIosPeople/> Family </h5>
                            <p>{content['family'].value} </p>
                        </div>
                        }
                        {showField('friends') &&
                        <div>
                            <h5><MdPeople/> Friends </h5>
                            <p>{content['friends'].value} </p>
                        </div>
                        }
                        {showField('pets') &&
                        <div>
                            <h5><FaPaw/> Pets </h5>
                            <p>{content['pets'].value} </p>
                        </div>
                        }

                        {(showField('likes') || showField('dislikes') || showField('triggers') || showField('calms') || showField('dos') || showField('donts')) &&
                        <div>
                            <hr/>
                            <h3 className={'m-2'}>Interacting With Me</h3>
                        </div>
                    }

                    {showField('likes') &&
                    <div>
                        <h5><TiThumbsUp/> My Likes</h5>
                        <p>{content['likes'].value} </p>
                    </div>
                    }
                    {showField('dislikes') &&
                    <div>
                        <h5><TiThumbsDown/> My Dislikes</h5>
                        <p>{content['dislikes'].value} </p>
                    </div>
                    }
                    {showField('triggers') &&
                    <div>
                        <h5><IoIosAlert/> Things that trigger me</h5>
                        <p>{content['triggers'].value} </p>
                    </div>
                    }
                    {showField('calms') &&
                    <div>
                        <h5><IoIosHappy/> Things that calm me down</h5>
                        <p>{content['calms'].value} </p>
                    </div>
                    }
                    {showField('dos') &&
                    <div>
                        <h5><MdCheck/> Do these things</h5>
                        <p>{content['dos'].value} </p>
                    </div>
                    }
                    {showField('donts') &&
                    <div>
                        <h5><MdClear/> Don't do these things</h5>
                        <p>{content['donts'].value} </p>
                    </div>
                    }
                    <hr/>
                </div>
                }
            </ReactStrap.Container>
        );
    }
}
export default MobX.inject('stores')(ReactRouter.withRouter(MobX.observer(BioRecord)));