import * as React from 'react';

import * as ReactRouter from 'react-router-dom';
import * as ReactStrap from 'reactstrap';

import * as MobX from "mobx-react";
import {InterfaceStore} from "../store/InterfaceStore";
import {RecordkeepingStore} from "../store/RecordkeepingStore";

//@ts-ignore
import { WithContext as ReactTags } from 'react-tag-input';

import { MdDeleteForever, MdEdit, MdHistory} from "react-icons/all";
import {Global} from "../global/Global";

class NoteRecord extends React.Component <any, any> {
    toggle : any;

    readonly ifStore : InterfaceStore;
    readonly rkStore : RecordkeepingStore;

    constructor(props : any) {
        super(props);

        this.state = {
            goEditRecord: false,
            goManageShares: false,
            goHistory: false,
            goDelete: false,
            goNotes: false,

            deleteModal: false,

        };

        this.ifStore = this.props.stores.ifStore;
        this.ifStore.message = null; // to prevent the message being shown when switching page

        this.rkStore = this.props.stores.rkStore;
    }

    toggleDeleteModal = () => {
        this.setState((prevState : any) => ({
            deleteModal: !prevState.deleteModal,
        }));
    }

    doDelete = () => {
        this.rkStore.deleteRecord()
        this.toggleDeleteModal()

        this.setState({
            goNotes: true,
        })

    }

    render() {

        //Redirects
        if (Global.getOfflineAuthCookie() === false && Global.getOnlineAuthCookie() === false) {
            //alert ('You are not login!!!');
            return <ReactRouter.Redirect to="/login" />;
        }

        if(this.state.goEditRecord) {
            this.rkStore.getRecord(this.props.recordItem.href)
            return <ReactRouter.Redirect to="/noteedit" />;
        }

        if(this.state.goManageShares) {
            this.rkStore.getRecord(this.props.recordItem.href)
            return <ReactRouter.Redirect to="/manageshares"/>
        }

        if(this.state.goHistory) {
            this.rkStore.getRecord(this.props.recordItem.href)
            return <ReactRouter.Redirect to="/history"/>
        }

        if(this.state.goNotes) {
            this.rkStore.getRecords()
            return <ReactRouter.Redirect to="/notes" />;
        }

        let content = this.props.recordItem.content;

        return (
            <ReactStrap.Container fluid={true}>

                {content &&
                    <ReactStrap.Card>
                        <ReactStrap.CardHeader>
                            <ReactStrap.Row>
                                <ReactStrap.Col sm={2}>
                                    <ReactStrap.Label>{Global.getDateString(this.props.recordItem.citeDate, 1)}</ReactStrap.Label>
                                </ReactStrap.Col>
                                <ReactStrap.Col className="text-right" sm={10}>
                                    <ReactStrap.Button outline color="primary" className="float-right" onClick={() => {
                                        this.setState({
                                            goEditRecord: true,
                                        })
                                    }}> <MdEdit/></ReactStrap.Button>
                                    <ReactStrap.Button outline color="primary" className="float-right" onClick={() => {
                                        this.setState({
                                            goHistory: true,
                                        })
                                    }}> <MdHistory/></ReactStrap.Button>

                                    <ReactStrap.Button outline color="primary" className="float-right" onClick={() => {
                                        this.setState({
                                            deleteModal: true,
                                        })
                                    }}> <MdDeleteForever color={'red'}/></ReactStrap.Button>
                                </ReactStrap.Col>
                            </ReactStrap.Row>
                        </ReactStrap.CardHeader>
                        <ReactStrap.CardBody>
                            <ReactStrap.CardText>
                                {this.props.recordItem.description}
                            </ReactStrap.CardText>

                            {content.tags &&
                                <div>
                                    <h5>Tags</h5>
                                    <ReactTags tags={content.tags.value}
                                               suggestions={this.rkStore.allTags}
                                               inputFieldPosition="inline"
                                               autocomplete={1}
                                               readOnly={this.props.readOnly}
                                    />
                                </div>
                            }


                        </ReactStrap.CardBody>
                    </ReactStrap.Card>
                }

                <ReactStrap.Modal isOpen={this.state.deleteModal} toggle={this.toggleDeleteModal}>
                    <ReactStrap.ModalHeader toggle={this.toggle}>Delete Record</ReactStrap.ModalHeader>
                    <ReactStrap.ModalBody>
                        Permanently delete this note from the system? This cannot be undone.
                    </ReactStrap.ModalBody>
                    <ReactStrap.ModalFooter>
                        <ReactStrap.Button color="primary" onClick={this.doDelete}>Delete</ReactStrap.Button>{' '}
                        <ReactStrap.Button color="secondary" onClick={this.toggleDeleteModal}>Cancel</ReactStrap.Button>
                    </ReactStrap.ModalFooter>
                </ReactStrap.Modal>

            </ReactStrap.Container>
        );
    }
}
export default MobX.inject('stores')(ReactRouter.withRouter(MobX.observer(NoteRecord)));
