import * as React from 'react';
import {Global} from "../../global/Global";
import * as ReactStrap from 'reactstrap';
import * as ReactRouter from 'react-router-dom';
import * as MobX from "mobx-react";

class Logout extends React.Component <any, any> {

    updateLoginStatus : any;

    constructor(props : any) {
        super(props);

        this.state = {
            showSpinner : false,
            allDone: false,
            isLogin : true
        };

        this.handleLogout = this.handleLogout.bind(this);

        this.updateLoginStatus = props.loginStatusCallback;
    }

    handleLogout(event : any) {
        //THIS IS VERY IMPORTANT
        //https://stackoverflow.com/questions/39809943/react-preventing-form-submission
        event.preventDefault();

        this.setState({
            showSpinner : true,
        });

        //Unset authentication Cookies
        Global.setOnlineAuthCookie(false);
        Global.setOfflineAuthCookie(false);


        //Change state of this component for re-rendering
        this.setState({
            isLogin: false,
            showSpinner : false,
            allDone: true
        });

        //Still leave the hashed username and password in cookies for further login such as offline login

        //Update login status back to app bar
        this.updateLoginStatus(false);

        //TODO: remove token
        //Global.setAuthTokenCookie(null);

        //TODO: do stuff related to data and sync

        event.preventDefault();
    }

    componentDidMount() {
        let loginStatus = true;
        if (Global.getOfflineAuthCookie() === false && Global.getOnlineAuthCookie() === false) {
            loginStatus = false;
        }
        this.setState({
            isLogin : loginStatus
        });
    }

    render() {
        if (Global.getOfflineAuthCookie() === false && Global.getOnlineAuthCookie() === false) {
            //alert ('You are not login!!!');
            return <ReactRouter.Redirect to="/login" />;
        }
        if (this.state.allDone) {
            return(<ReactRouter.Redirect to="/login" />);
        } else {
            let message;
            let button;
            if (this.state.isLogin) {
                message =
                    <div className={'ml-5'}>Are you sure you want to log out? Logging out will clear all of the records stored on this
                        device.<br/>Click the <b>Logout</b> button to continue, or choose another page from the top menu.
                    </div>;
                button = <div className={'mr-5'}><ReactStrap.Button onClick={this.handleLogout} className={'float-right'}>Logout</ReactStrap.Button></div>;
            } else {
                return(<ReactRouter.Redirect to="/login" />);
            }
            return (
                <div>
                    <hr/>
                    {message}
                    <hr/>
                    {button}
                    <br/>
                </div>
            );
        }
    }
}

export default MobX.inject('stores')(ReactRouter.withRouter(MobX.observer(Logout)));