import Stores from "./Stores";
import AppDao from "../DataAccess/AppDao";
import {Global} from "../global/Global";
import * as MobX from "mobx";

export interface ISettings {
    idletime : number, //seconds
    countdowntime: number, //seconds
    modifiedTime : number, // the number of milliseconds elapsed since January 1, 1970 00:00:00
}

export class AppStore {
    storesInstance: Stores;
    appDao: AppDao;
    settings : ISettings;
    temp_settings : ISettings;//this is temp value for the form in SystemPreferences page

    constructor(storesInstance: Stores) {
        this.storesInstance = storesInstance;
        this.appDao = new AppDao();
        this.settings = {
                idletime : Global.DEFAULT_IDLE_TIME,
                countdowntime : Global.DEFAULT_COUNTDOWN_TIME,
                modifiedTime : -1,
        };
        //deep copy settings to temp_settings
        this.temp_settings = {
            idletime : this.settings.idletime,
            countdowntime : this.settings.countdowntime,
            modifiedTime : this.settings.modifiedTime,
        };
    }

    // Getting settings from server if online, local database if offline, default otherwise
    GetSettings() {
        //this.appDao.pushDefault();
        this.appDao.fetchSettings().then((result : any) => {
            console.log('returned result content', result);
            this.settings = JSON.parse(result.content) as ISettings;
            //deep copy settings to temp_settings
            this.temp_settings = {
                idletime : this.settings.idletime,
                countdowntime : this.settings.countdowntime,
                modifiedTime : this.settings.modifiedTime,
            };
            console.log("settings in appStore", this.settings);
        })
        .catch( (e:any) =>{
            this.storesInstance.ifStore.message = this.appDao.normaliseResponse('fetching settings: ', e.message);
        });
    }

    SetSettings() {
        this.appDao.pushSettings(JSON.stringify(this.temp_settings)).then( (responseObject) => {
            //So we can alert any success/error messages
            //alert( 'Set Settings Response Object = ' + JSON.stringify(responseObject));
            this.storesInstance.ifStore.message = 'App settings successfully stored in server';
            //as this record is setting, update the information to app
            //deep copy temp_settings to settings
            this.settings = {
                idletime : this.temp_settings.idletime,
                countdowntime : this.temp_settings.countdowntime,
                modifiedTime : this.temp_settings.modifiedTime,
            };
         }).catch(function (this : AppStore, e : any) {
             this.storesInstance.ifStore.message = {error:'System error when saving settings to server.'};
             console.log("SOMETHING IS WRONG!", e);
         });
    }
}

MobX.decorate(
    AppStore,
    {
        settings: MobX.observable,
        temp_settings: MobX.observable,
        GetSettings: MobX.action,
    }
);