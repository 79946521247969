import * as React from "react";
import * as ReactRouter from "react-router-dom";
import * as ReactStrap from 'reactstrap';
import * as MobX from "mobx-react";
import {Global} from "../global/Global";
import {MdDeleteForever, MdFileDownload} from "react-icons/md";
import pdf from '../assets/pdf.png';
import docx from '../assets/docx.png';
import {UploadsStore} from "../store/UploadsStore";

class Upload extends React.Component <any, any> {

    readonly uplStore : UploadsStore;

    constructor(props:any) {
        super(props);

        this.uplStore = this.props.stores.uplStore;
        this.uplStore.getUploadBlob(this.props.upload.href);

    }

    handleView = () => {
        const link = document.createElement('a');
        // create a blobURI pointing to our Blob
        link.href = URL.createObjectURL(this.uplStore.blobs.get(this.props.upload.href));
        link.download = this.props.upload.fileName;
        // some browsers need the anchor to be in the doc
        document.body.append(link);
        link.click();
        link.remove();
    }

    render() {
        if (Global.getOfflineAuthCookie() === false && Global.getOnlineAuthCookie() === false) {
            return <ReactRouter.Redirect to="/login"/>;
        }

        let uploadSrc: any = null;

        if ( this.props.upload.fileName.endsWith('.pdf')){
                uploadSrc = pdf;
        } else  if ( this.props.upload.fileName.endsWith('.docx')){
                uploadSrc=  docx;
        } else {
            if (this.uplStore.blobs.get(this.props.upload.href)) {
                uploadSrc = URL.createObjectURL(this.uplStore.blobs.get(this.props.upload.href))
            }
        }

        return (

                <ReactStrap.Card body outline color={'info'}>
                        <ReactStrap.Row>
                            <ReactStrap.Col xs={12}>
                        {this.props.upload.name}
                            </ReactStrap.Col>
                        </ReactStrap.Row>
                    <ReactStrap.Row>
                        <ReactStrap.Col xs={12}>
                            <small>{this.props.upload.fileName}</small>
                        </ReactStrap.Col>
                    </ReactStrap.Row>
                        {this.props.selectCallback && uploadSrc &&
                        <ReactStrap.Button color="link"
                                           onClick={() => this.props.selectCallback({href: this.props.upload.href, filename: this.props.upload.fileName})}><ReactStrap.CardImg
                            src={uploadSrc}/></ReactStrap.Button>
                        }
                        <ReactStrap.Row>
                            <ReactStrap.Col  xs={{ size: 1}} className="text-right">
                                <MdDeleteForever
                                    color='red' size={20} onClick={()=>this.props.deleteCallback(this.props.upload.href)}/>
                            </ReactStrap.Col>
                            <ReactStrap.Col  xs={{ size: 1}} className="text-right">
                                <MdFileDownload
                                    size={20} onClick={()=>this.handleView()}/>
                            </ReactStrap.Col>
                        </ReactStrap.Row>
                </ReactStrap.Card>

        )
    }
}

export default MobX.inject('stores')(ReactRouter.withRouter(MobX.observer(Upload)));