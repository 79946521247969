import * as React from "react";
import * as ReactStrap from 'reactstrap';
import {Global} from "../global/Global";
import * as ReactRouter from "react-router-dom";
import {InterfaceStore} from "../store/InterfaceStore";
import {RecordkeepingStore} from "../store/RecordkeepingStore";
import * as MobX from "mobx-react";
import {FiFilePlus} from "react-icons/all";
import NoteRecord from "../components/NoteRecord";

class Notes extends React.Component <any, any> {
    readonly ifStore: InterfaceStore;
    readonly rkStore: RecordkeepingStore;

    constructor(props: any) {
        super(props);

        this.state = {
            goCreateNote: false,
        };

        this.ifStore = this.props.stores.ifStore;
        this.ifStore.message = null; // to prevent the message being shown when switching page

        this.rkStore = this.props.stores.rkStore;
    }

    handleCreate = () => {
        this.rkStore.blankRecord()
            this.setState({
                goCreateNote: true,
            });
    }

    render() {
        if (Global.getOfflineAuthCookie() === false && Global.getOnlineAuthCookie() === false) {
            //alert ('You are not login!!!');
            return <ReactRouter.Redirect to="/login"/>;
        }

        if(this.state.goCreateNote) {
            //new blank record
            return <ReactRouter.Redirect to="/noteedit" />;
        }

        let records:any = this.rkStore.records.filter((rec:any ) => {
            return rec.recordcategory === 'Personal' && rec.recordtype === 'Note'
        });

        return (
            <ReactStrap.Container fluid={true}>
                <ReactStrap.Row style={{padding: "10px"}}>
                    <ReactStrap.Col sm={8}>
                        <h2 className={'m-2'}>My Notes </h2>
                    </ReactStrap.Col>
                    <ReactStrap.Col className="text-right" sm={4}>
                        <ReactStrap.Button outline color="primary" className="float-right" onClick={this.handleCreate}> <FiFilePlus/></ReactStrap.Button>
                    </ReactStrap.Col>
                </ReactStrap.Row>

                {records &&
                <div>
                    {records.map((rec: any) =>
                        <div key={rec.href}>
                            <NoteRecord  recordItem={rec}/>
                            <p/>
                        </div>
                    )
                    }
                </div>
                }
            </ReactStrap.Container>

        );
    }
}
export default MobX.inject('stores')(ReactRouter.withRouter(MobX.observer(Notes)))