import { RecordkeepingStore } from "./RecordkeepingStore";
import {OrgDataStore} from "./OrgDataStore";
import {InterfaceStore} from "./InterfaceStore";
import {UploadsStore} from "./UploadsStore";
import {AppStore} from "./AppStore";

export default class Stores {
    rkStore : RecordkeepingStore;
    odStore : OrgDataStore;
    ifStore : InterfaceStore;
    uplStore : UploadsStore;
    appStore : AppStore;

    constructor(){
        this.rkStore = new RecordkeepingStore(this);
        this.odStore = new OrgDataStore(this);
        this.ifStore = new InterfaceStore(this);
        this.uplStore = new UploadsStore(this);
        this.appStore = new AppStore(this);
    }
}

let storesInstance = new Stores();
export { storesInstance };