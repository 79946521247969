import * as React from 'react';
import * as MobX from "mobx-react";
import * as ReactRouter from "react-router-dom";
import {InterfaceStore} from "../store/InterfaceStore";
import {OrgDataStore} from "../store/OrgDataStore";

class Preview extends React.Component <any, any> {
    readonly ifStore : InterfaceStore;
    readonly odStore : OrgDataStore;

    constructor(props : any) {
        super(props);

        this.ifStore = this.props.stores.ifStore;
        this.ifStore.message = null; // to prevent the message being shown when switching page

        this.odStore = this.props.stores.odStore;
    }

    render() {

        let component:any  = <img alt={'Document that serves as the content for this record'} src={this.odStore.previewAttachment} id={'attachmentImage'} style={{border:'1px solid'}}/>;

        return (
            <div>
                {this.odStore.previewAttachment &&
                    <div>
                        {component}
                    </div>
                }
            </div>
        );
    }
}

export default MobX.inject('stores')(ReactRouter.withRouter(MobX.observer(Preview)));