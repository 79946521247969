import * as React from 'react';
import * as ReactStrap from 'reactstrap';
import * as ReactRouter from "react-router-dom";
import * as MobX from "mobx-react";
import {InterfaceStore} from "../store/InterfaceStore";
import {RecordkeepingStore} from "../store/RecordkeepingStore";
import moment from 'moment';

//@ts-ignore
import CreatableSelect from 'react-select/creatable';
//@ts-ignore
import Select from 'react-select';

import { Slider, Rail, Handles, Tracks, Ticks } from "react-compound-slider";

import {OrgDataStore} from "../store/OrgDataStore";
import {Global} from "../global/Global";

class RecordsFilterWidget extends React.Component <any, any> {
    readonly ifStore : InterfaceStore;
    readonly rkStore : RecordkeepingStore;
    readonly odStore : OrgDataStore;

    constructor(props : any) {
        super(props);

        this.ifStore = this.props.stores.ifStore;
        this.ifStore.message = null; // to prevent the message being shown when switching page

        this.rkStore = this.props.stores.rkStore;
        this.odStore = this.props.stores.odStore;

        this.state = {
            includeWords: [],
            includeSites: [],
            includeTypes:  [],
            includeDates: [],
        };

    }

    doFilter = () => {
        let filteredList = this.props.records.filter( (rec:any) => {
            let filterInclude = false
            let filterSites = false
            let filterTypes = false
            let filterDates = false

            let keepInclude = false
            let keepSites = false
            let keepTypes = false
            let keepDates = false

            //include words
            this.state.includeWords.forEach((item: any, index: any) => {
                filterInclude = true;
                if (rec.label.toLowerCase().indexOf(item.value.toLowerCase()) > -1) {
                    keepInclude = true
                }

                if (rec.description.toLowerCase().indexOf(item.value.toLowerCase()) > -1) {
                    keepInclude = true
                }
            })

            //include sites
            this.state.includeSites.forEach((item: any, index: any) => {
                filterSites = true;
                if (rec.site && rec.site.tag === item.value) {
                    keepSites = true
                }
            })

            //include types
            this.state.includeTypes.forEach((item: any, index: any) => {
                filterTypes = true;

                let parts = item.value.split('/')

                //Different names for cat/typ if site record
                if(this.props.hideSite) {
                    if (rec.category ===  parts[0] && rec.type === parts[1]) {
                        keepTypes = true
                    }
                } else {
                    if (rec.recordcategory === parts[0] && rec.recordtype === parts[1]) {
                        keepTypes = true
                    }
                }
            })

            //include dates

            //Different date structures for cat/typ if site record
            let displayDate:string = ''
            if(this.props.hideSite) {
                displayDate = rec.date
            } else {
                displayDate = Global.determineRecordDate(rec)
            }

            let recDate = -1
            if (!displayDate.startsWith('N')) {
               recDate = Number(moment(displayDate).format('YYYYY'))
            }


            if (this.state.includeDates.length > 0) {
                filterDates = true
            }

            if (recDate >= this.state.includeDates[0] && recDate <= this.state.includeDates[1]) {
                keepDates = true
            }

            let result = true

            //if a filter has run, then AND the results
            if (filterInclude) {
                result = result && keepInclude
            }

            if (filterSites) {
                result = result && keepSites
            }

            if (filterTypes) {
                result = result && keepTypes
            }

            if (filterDates) {
                result = result && keepDates
            }

            return result
        });

        this.props.onChange(filteredList)
    }

    handleIncludeChange = (value: any, actionMeta: any) => {
        //call filter synchronously after setState
        this.setState({
            includeWords: value ? value : []
        },
        this.doFilter
        );
    };

    handleSitesChange = (value: any, actionMeta: any) => {
        //call filter synchronously after setState
        this.setState({
                includeSites: value ? value : []
            },
            this.doFilter
        );
    };

    handleTypesChange = (value: any, actionMeta: any) => {
        //call filter synchronously after setState
        this.setState({
                includeTypes: value ? value : []
            },
            this.doFilter
        );
    };

    handleDatesChange = (values: any) => {
        //slider does some wierd initialisation thing
        if (isNaN(values[0])) {
            return
        }

        //call filter synchronously after setState
        this.setState({
                includeDates: values ? values : []
            },
            this.doFilter
        );
    };

    render() {
        let sites:any = []
        this.odStore.selectedSites.forEach( (item:any, index:any) => {
            let site = {label: item.label, value: item.tag}
            sites.push(site)
        });

        let types = [
            {value:"Care/Case Plan", label: "Care - Case Plan"},
            {value:"Care/Incident Report", label: "Care - Incident report"},
            {value:"Care/Leaving Care Plan", label: "Care - Leaving care plan"},
            {value:"Care/Placement Referral", label: "Care - Placement referral"},
            {value:"Care/Other", label: "Care - Other"},
            {value:"Civic/Birth Certificate", label: "Civic - Birth certificate"},
            {value:"Civic/Driver's License", label: "Civic - Driver's licence"},
            {value:"Civic/Other", label: "Civic - Other"},
            {value:"Court/Report", label: "Court - Report"},
            {value:"Court/Other", label: "Court - Other"},
            {value:"Education/Education Plan", label: "Education - Education plan"},
            {value:"Education/School Report", label: "Education - School report"},
            {value:"Education/Other", label: "Education - Other"},
            {value:"Education/Form", label: "Education - Form"},
            {value:"Health/Plan", label: "Health - Plan"},
            {value:"Health/health Care Card", label: "Health - Heath care card"},
            {value:"Health/Test results", label: "Health - Test results"},
            {value:"Health/Medicare Card", label: "Health - Medicare card"},
            {value:"Health/Checkup", label: "Health - Check up"},
            {value:"Health/Procedure", label: "Health - Procedure"},
            {value:"Health/Assessment", label: "Health - Assessment"},
            {value:"Health/Referral", label: "Health - Referral"},
            {value:"Health/Certificate", label: "Health - Certificate"},
            {value:"Health/Other", label: "Health - Other"},
            {value:"Personal/Bio", label: "Personal - Bio"},
            {value:"Personal/Meeting Note", label: "Personal - Meeting note"},
            {value:"Personal/Other", label: "Personal - Other"},
        ]

        /*-----------------------------------------------------------------------------------
         * Constructs for the date slider component
         *---------------------------------------------------------------------------------*/
        let sliderStyle:any = {  // Give the slider some width
            position: 'relative',
            width: '100%',
            height:60,
        }

        let railStyle:any = {
            position: 'absolute',
            width: '100%',
            height: 5,
            marginTop: 20,
            borderRadius: 5,
            backgroundColor: '#8B9CB6',
        }

        interface IHandlePayload {
            handle: {
                id: string;
                value: number;
                percent: number;
            },
            getHandleProps: any
        }

        let Handle = (arg: IHandlePayload)  => {
            return (
                <div
                    style={{
                        left: `${arg.handle.percent}%`,
                        position: 'absolute',
                        marginLeft: -15,
                        marginTop: 12,
                        zIndex: 2,
                        width: 20,
                        height: 20,
                        border: 0,
                        textAlign: 'center',
                        cursor: 'pointer',
                        borderRadius: '50%',
                        backgroundColor: '#007BFF',
                        color: '#007BFF',
                    }}
                    {...arg.getHandleProps(arg.handle.id)}
                >

                </div>
            )
        }

        interface ITrackPayload  {
            source:any,
            target:any,
            getTrackProps:any
        }

        function Track(arg:ITrackPayload) {
            return (
                <div
                    style={{
                        position: 'absolute',
                        height: 10,
                        zIndex: 1,
                        marginTop: 18,
                        backgroundColor: '#1A3665',
                        borderRadius: 4,
                        cursor: 'pointer',
                        left: `${arg.source.percent}%`,
                        width: `${arg.target.percent - arg.source.percent}%`,
                    }}
                    {...arg.getTrackProps() /* this will set up events if you want it to be clickeable (optional) */}
                />
            )
        }

        interface ITickPayload {
            tick :any,
            count: number
        }

        let Tick = (arg:ITickPayload) => {
            return (
                <div>
                    <div
                        style={{
                            position: 'absolute',
                            marginTop: 32,
                            marginLeft: -0.5,
                            width: 1,
                            height: 8,
                            backgroundColor: 'silver',
                            left: `${arg.tick.percent}%`,
                        }}
                    />
                    <div
                        style={{
                            position: 'absolute',
                            marginTop: 40,
                            fontSize: 10,
                            textAlign: 'center',
                            marginLeft: `${-(100 / arg.count) / 2}%`,
                            width: `${100 / arg.count}%`,
                            left: `${arg.tick.percent}%`,
                        }}
                    >
                        {arg.tick.value}
                    </div>
                </div>
            )
        }

        /*-----------------------------------------------------------------------------------
         * Calculate max/min dates from records
         *---------------------------------------------------------------------------------*/
        let maxDate:number = 0
        let minDate:number = 0

        this.props.records.forEach( (rec:any) => {
            let displayDate:string = ''
            if(this.props.hideSite) {
                displayDate = rec.date
            } else {
                displayDate = Global.determineRecordDate(rec)
            }

            if(displayDate && !displayDate.startsWith('N') ) {
                let recDate = Number(moment(displayDate).format('YYYYY'))

                if (maxDate === 0) {
                    maxDate = recDate
                    minDate = recDate
                } else {
                    maxDate = recDate > maxDate ? recDate : maxDate
                    minDate = recDate < minDate ? recDate : minDate
                }
            }
        })

         return (
             <ReactStrap.Container fluid={true} style={{border: "1px solid #007BFF"}}>
                 <h4>Filters</h4>

                 <ReactStrap.Row className={'m-2'}>
                     <ReactStrap.Col sm={12}>
                         <Slider
                             rootStyle={sliderStyle /* inline styles for the outer div. Can also use className prop. */}
                             domain={[minDate, maxDate]}
                             step={1}
                             mode={2}
                             values={[minDate, maxDate]}
                             onChange={this.handleDatesChange}
                         >
                             <Rail>
                                 {({ getRailProps }) => (
                                     <div style={railStyle} {...getRailProps()} />
                                 )}
                             </Rail>
                             <Handles>
                                 {({ handles, getHandleProps }) => (
                                     <div className="slider-handles">
                                         {handles.map(handle => (
                                             <Handle
                                                 key={handle.id}
                                                 handle={handle}
                                                 getHandleProps={getHandleProps}
                                             />
                                         ))}
                                     </div>
                                 )}
                             </Handles>
                             <Tracks left={false} right={false}>
                                 {({ tracks, getTrackProps }) => (
                                     <div className="slider-tracks">
                                         {tracks.map(({ id, source, target }) => (
                                             <Track
                                                 key={id}
                                                 source={source}
                                                 target={target}
                                                 getTrackProps={getTrackProps}
                                             />
                                         ))}
                                     </div>
                                 )}
                             </Tracks>
                             <Ticks count={12 /* generate approximately 15 ticks within the domain */}>
                                 {({ ticks }) => (
                                     <div className="slider-ticks">
                                         {ticks.map(tick => (
                                             <Tick key={tick.id} tick={tick} count={ticks.length} />
                                         ))}
                                     </div>
                                 )}
                             </Ticks>
                         </Slider>
                     </ReactStrap.Col>
                 </ReactStrap.Row>

                 <ReactStrap.Row className={'m-2'}>
                     <ReactStrap.Col sm={12}>
                         <CreatableSelect
                             components={{
                                 DropdownIndicator: null,
                             }}
                             isMulti
                             onChange={this.handleIncludeChange}
                             placeholder="Only show records with these words"
                             options={[]}
                         />
                     </ReactStrap.Col>
                 </ReactStrap.Row>

                 {!this.props.hideSite &&
                     <ReactStrap.Row className={'m-2'}>
                         <ReactStrap.Col sm={12}>
                             <Select
                                 isMulti
                                 onChange={this.handleSitesChange}
                                 placeholder="Only show records from these sites"
                                 options={sites}
                             />
                         </ReactStrap.Col>
                     </ReactStrap.Row>
                 }

                <ReactStrap.Row className={'m-2'}>
                    <ReactStrap.Col sm={12}>
                        <Select
                            isMulti
                            onChange={this.handleTypesChange}
                            placeholder="Only show records of these types"
                            options={types}
                        />
                    </ReactStrap.Col>
                </ReactStrap.Row>

             </ReactStrap.Container>
        );
    }
}
export default MobX.inject('stores')(ReactRouter.withRouter(MobX.observer(RecordsFilterWidget)));