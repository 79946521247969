import * as React from 'react';
import * as ReactStrap from "reactstrap";
import * as MobX from "mobx-react";
import * as ReactRouter from "react-router-dom";
import {Global} from "../global/Global";
import {Button} from "reactstrap";
import {InterfaceStore} from "../store/InterfaceStore";
import {RecordkeepingStore} from "../store/RecordkeepingStore";

class RecordListItem extends React.Component <any, any> {
    readonly ifStore: InterfaceStore;
    readonly rkStore: RecordkeepingStore;

    constructor(props : any) {
        super(props);
        this.state = {
            goRecord: false,
        };

        this.ifStore = this.props.stores.ifStore;
        this.ifStore.message = null; // to prevent the message being shown when switching page

        this.rkStore = this.props.stores.rkStore;
    }

    goRecord = () => {
        this.setState({
            goRecord:true
        })
    }


    render() {

        if(this.state.goRecord) {
            this.rkStore.getRecord(this.props.recordItem.href)

            if (this.props.recordItem && this.props.recordItem.recordcategory === 'Personal' && this.props.recordItem.recordtype === 'Note') {
                return <ReactRouter.Redirect to="/noteview"/>;
            } else {
                return <ReactRouter.Redirect to="/record"/>;
            }
        }

        let label:string = this.props.recordItem.myLabel ? this.props.recordItem.myLabel : this.props.recordItem.label
        let date:any = this.props.recordItem.myDate ? this.props.recordItem.myDate : this.props.recordItem.citeDate
        let dateAccuracy:any = this.props.recordItem.myDateAccuracy ? this.props.recordItem.myDateAccuracy : this.props.recordItem.citeDateAccuracy

        return (

            <div>
                <ReactStrap.Row>
                    <ReactStrap.Col xs={12}>
                        <ReactStrap.Label> {Global.getDateString(date, dateAccuracy) + " - " } <Button color="link" onClick={this.goRecord}>{label}</Button></ReactStrap.Label>
                    </ReactStrap.Col>
                </ReactStrap.Row>
                <ReactStrap.Row>
                    <ReactStrap.Col xs={12}>
                        {'[' + this.props.recordItem.recordcategory + " record, type = " + this.props.recordItem.recordtype + ']'}
                    </ReactStrap.Col>
                </ReactStrap.Row>
                <hr/>

            </div>
        )
    }
}
export default MobX.inject('stores')(ReactRouter.withRouter(MobX.observer(RecordListItem)))