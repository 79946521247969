import * as React from "react";
import * as ReactStrap from 'reactstrap';
import {Global} from "../global/Global";
import * as ReactRouter from "react-router-dom";
import RecordListItem from "../components/RecordListItem";
import * as MobX from "mobx-react";

class RecordList extends React.Component <any, any> {

    constructor(props: any) {
        super(props);

        this.state = {
            bySite: false,
            byType: false,
        }

    }

    handleOptionChange = (event : any) => {
        this.setState({
            [event.target.name]: event.target.checked
        });
    }

    doOne = () => {
        let component:any =  <RecordListItem key={this.props.records[0].href} recordItem={this.props.records[0]}/>

        return component
    }

    doByNone = () => {
        let component:any = (
            <div>
                {this.props.records.map((rec: any) =>
                    <RecordListItem key={rec.href} recordItem={rec}/>
                )}
            </div>
        );

        return component

    }

    doBySite = () => {
        let siteBuckets:any = {}

        this.props.records.forEach( (rec:any, index:any) => {
            //Find the site label for this record
            let siteLabel = 'Personal'
            let matchedSite = this.props.stores.odStore.selectedSites.find( (site:any) => {
                return rec.site && (site.tag === rec.site.tag)
            })

            if (matchedSite) {
                siteLabel = matchedSite.label
            }

            //if the site bucket doesn't exist, create it
            if (!siteBuckets[siteLabel]) {
                siteBuckets[siteLabel] = {"label":siteLabel , "recs":[] };
            }

            //push the record into the appropriate site bucket
            siteBuckets[siteLabel]["recs"].push(rec);
        })

        //Now, construct our component
        let component:any = (
            <div>
                {Object.keys(siteBuckets).sort().map((key: any) =>
                    <div key={key}>
                        <h4>{siteBuckets[key].label}</h4>
                        {siteBuckets[key].recs.map((rec: any) =>
                            <RecordListItem key={rec.href} recordItem={rec}/>
                        )}
                    </div>
                )}
            </div>
        );

        return component

    }

    doByType = () => {
        let typeBuckets:any = {}

        this.props.records.forEach( (rec:any, index:any) => {
            //Find the type label for this record
            let typeLabel = rec.recordcategory + '/' + rec.recordtype

            //if the type bucket doesn't exist, create it
            if (!typeBuckets[typeLabel]) {
                typeBuckets[typeLabel] = {"label":typeLabel , "recs":[] };
            }

            //push the record into the appropriate type bucket
            typeBuckets[typeLabel]["recs"].push(rec);
        })

        //Now, construct our component
        let component:any = (
            <div>
                {Object.keys(typeBuckets).sort().map((key: any) =>
                    <div key={key}>
                        <h4>{typeBuckets[key].label}</h4>
                        {typeBuckets[key].recs.map((rec: any) =>
                            <RecordListItem key={rec.href} recordItem={rec}/>
                        )}
                    </div>
                )}
            </div>
        );

        return component
    }

    doBySiteAndType = () => {
        let siteBuckets:any = {}

        this.props.records.forEach( (rec:any, index:any) => {
            //Find the site label for this record
            let siteLabel = 'Personal'
            let matchedSite = this.props.stores.odStore.selectedSites.find( (site:any) => {
                return rec.site && (site.tag === rec.site.tag)
            })

            if (matchedSite) {
                siteLabel = matchedSite.label
            }

            //if the site bucket doesn't exist, create it
            if (!siteBuckets[siteLabel]) {
                siteBuckets[siteLabel] = {"label":siteLabel , "typeBuckets":{} };
            }

            //Now do the type thing inside the site bucket
            let typeBuckets:any = siteBuckets[siteLabel]["typeBuckets"]

            //Find the type label for this record
            let typeLabel = rec.recordcategory + '/' + rec.recordtype

            //if the type bucket doesn't exist, create it
            if (!typeBuckets[typeLabel]) {
                typeBuckets[typeLabel] = {"label":typeLabel , "recs":[] };
            }

            //push the record into the appropriate type bucket
            typeBuckets[typeLabel]["recs"].push(rec);

            siteBuckets[siteLabel]["typeBuckets"] = typeBuckets
        })

        //Now, construct our component
        let component:any = (
            <div>
                {Object.keys(siteBuckets).sort().map((siteBucketsKey: any) =>
                    <div key={siteBucketsKey}>
                        <h4>{siteBuckets[siteBucketsKey].label}</h4>
                        {Object.keys(siteBuckets[siteBucketsKey].typeBuckets).sort().map((typeBucketsKey: any) =>
                            <div key={typeBucketsKey}>
                                <h5>{siteBuckets[siteBucketsKey].typeBuckets[typeBucketsKey].label}</h5>
                                {siteBuckets[siteBucketsKey].typeBuckets[typeBucketsKey].recs.map((rec: any) =>
                                    <RecordListItem key={rec.href} recordItem={rec}/>
                                )}
                            </div>
                        )}
                    </div>
                )}
            </div>
        );

        return component
    }

    render() {
        if (Global.getOfflineAuthCookie() === false && Global.getOnlineAuthCookie() === false) {
            return <ReactRouter.Redirect to="/login"/>;
        }

        let showGroupingOptions = true

        let rendering:any = null

        if(this.props.records.length === 0 ) {
            showGroupingOptions = false
        } else if (this.props.records.length === 1 ) {
            showGroupingOptions = false
            rendering = this.doOne();
        } else {
            if (this.state.bySite && this.state.byType) {
                rendering = this.doBySiteAndType();
            } else if (this.state.bySite && !this.state.byType) {
                rendering = this.doBySite();
            } else if (!this.state.bySite && this.state.byType) {
                rendering = this.doByType();
            } else {
                rendering = this.doByNone();
            }
        }

        return (
            <ReactStrap.Container fluid={true}>
                {showGroupingOptions &&
                    <ReactStrap.Row >
                        <ReactStrap.Col xs={6}>
                            <ReactStrap.Label> Group by: </ReactStrap.Label>
                        </ReactStrap.Col>
                        <ReactStrap.Col xs={3}>
                            <ReactStrap.CustomInput type="switch" id="bySite" name="bySite" label="Site" checked={this.state.bySite} onChange={this.handleOptionChange}/>
                        </ReactStrap.Col>
                        <ReactStrap.Col xs={3}>
                            <ReactStrap.CustomInput type="switch" id="byType" name="byType" label="Type" checked={this.state.byType} onChange={this.handleOptionChange}/>
                        </ReactStrap.Col>
                    </ReactStrap.Row>
                }

                {rendering}

            </ReactStrap.Container>
        )
    }
}
export default MobX.inject('stores')(ReactRouter.withRouter(MobX.observer(RecordList)))