import * as React from "react";
import * as ReactRouter from "react-router-dom";
import * as MobX from "mobx-react";
import {FaRegBuilding} from 'react-icons/fa';
import * as ReactStrap from "reactstrap";
import {Global} from "../global/Global";
import {IKeyword, ISite, OrgDataStore} from "../store/OrgDataStore";
import OrgSiteRecords from './OrgSiteRecords';
import {MdHighlightOff, MdOfflinePin, MdRemoveRedEye} from "react-icons/all";

//@ts-ignore
import { WithContext as ReactTags } from 'react-tag-input';

class OrgDataManager extends React.Component <any, any> {
    setMessage: any;
    readonly odStore : OrgDataStore;

    readonly KeyCodes:any  = {
        comma: 188,
        enter: 13,
    };

    readonly delimiters: any = [this.KeyCodes.comma, this.KeyCodes.enter];

    constructor(props: any) {
        super(props);

        this.odStore = this.props.stores.odStore;

        this.state = {
            activeTab: '1',//1 = selected, 2 = all
            siteRecordsModal: false
        };

        this.toggle = this.toggle.bind(this);
        this.selectSite = this.selectSite.bind(this);
        this.unSelectSite = this.unSelectSite.bind(this);
    }

    showSiteRecords = (site : ISite) => {
        this.odStore.currentSite = site
        this.odStore.getSiteRecordsFromServer()

        this.toggleSiteRecordsModal()
    }

    toggleSiteRecordsModal = () => {
        this.setState((prevState : any) => ({
            siteRecordsModal: !prevState.siteRecordsModal,
        }));
    }

    selectSite(site : ISite) {
        this.odStore.AddSelectedSite(site);
    }

    unSelectSite(site : ISite) {
        this.odStore.RemoveSelectedSite(site);
    }

    //Change current tab
    toggle(tab : any) {
        //alert('Load Sites From Server');
        this.odStore.GetAllSitesFromServer();//also refresh when tab changed, or select/unselect buttons clicked
        this.odStore.GetSelectedSitesFromServer();//also refresh when tab changed, or select/unselect buttons clicked
        if (this.state.activeTab !== tab) {
            this.setState({
                activeTab: tab
            });
        }
    }

    componentDidMount() {
        //alert('Load Sites From Server');
        this.odStore.GetAllSitesFromServer();//also refresh when tab changed, or select/unselect buttons clicked
        this.odStore.GetSelectedSitesFromServer();//also refresh when tab changed, or select/unselect buttons clicked
    }

    handleChange = (e : any) => {
        this.odStore.siteSearchTerm = e.target.value;
    }

    handleDeleteKeyword = (i: number) => {
        this.odStore.AvoidKeywords = this.odStore.avoidKeywords.filter( (thing: IKeyword, index: number ) => index !== i)
    }

    handleAddKeyword = (tag: IKeyword)  => {
        this.odStore.AvoidKeywords.push(tag)
    }

    render() {
        if (Global.getOfflineAuthCookie() === false && Global.getOnlineAuthCookie() === false) {
            //alert ('You are not login!!!');
            return <ReactRouter.Redirect to="/login" />;
        }
        return (
            <div className={'m-2'}>
                <h2> <FaRegBuilding/>Find records </h2>
                <ReactStrap.Row>
                    <ReactStrap.Col sm={12}>
                        <p>Search for record holding sites or see a list of all sites. Register sites of interest. Locate records, and register those.</p>
                    </ReactStrap.Col>
                </ReactStrap.Row>

                <h4>Registered sites</h4>
                    {this.odStore.SelectedSites.map( (item : ISite) => (
                        <div key={item.tag}>
                            <ReactStrap.Row className={'m-3'} >
                                <ReactStrap.Col sm={10}>
                                    <ReactStrap.Label><strong>{item.label}</strong></ReactStrap.Label> &nbsp; <ReactStrap.Label>{item.description}</ReactStrap.Label>
                                </ReactStrap.Col>
                                <ReactStrap.Col sm={2} className="text-right">
                                    <ReactStrap.Button  outline color="primary" onClick={() => this.showSiteRecords(item)}><MdRemoveRedEye/></ReactStrap.Button>
                                    <ReactStrap.Button outline color="primary"  onClick={() => this.unSelectSite(item)}><MdHighlightOff/></ReactStrap.Button>
                                </ReactStrap.Col>
                            </ReactStrap.Row>
                        </div>

                    ))}

                <ReactStrap.Nav tabs>
                    <ReactStrap.NavItem>
                        <ReactStrap.NavLink
                            active={this.state.activeTab === '1' ? true : false}
                            onClick={() => { this.toggle('1'); }}
                        >
                            Search Sites
                        </ReactStrap.NavLink>
                    </ReactStrap.NavItem>
                    <ReactStrap.NavItem>
                        <ReactStrap.NavLink
                            active={this.state.activeTab === '2' ? true : false}
                            onClick={() => { this.toggle('2'); }}
                        >
                            All Sites
                        </ReactStrap.NavLink>
                    </ReactStrap.NavItem>
                </ReactStrap.Nav>

                {/*{this.state.showRecords &&*/}
                {/*    <OrgSiteRecords siteID = {this.state.showRecordsSiteID}/>*/}
                {/*}*/}

                <ReactStrap.TabContent activeTab={this.state.activeTab}>
                    <ReactStrap.TabPane tabId="1">
                        <br/>
                        <h4>Search unregistered site(s)</h4>
                        <br/>
                        Number of unregistered sites: {this.odStore.unregisteredSites.length}
                        <br/>
                        <ReactStrap.Label> Ignore sites that use these words: </ReactStrap.Label>
                        <ReactTags tags={this.odStore.avoidKeywords}
                                   handleDelete={this.handleDeleteKeyword}
                                   handleAddition={this.handleAddKeyword}
                                   delimiters={this.delimiters}
                                   inputFieldPosition="inline"
                                   placeholder = "Enter words and press enter"
                        />
                        <br/>
                        <ReactStrap.Label> Find sites: </ReactStrap.Label>
                        <ReactStrap.Input placeholder="Type search words and press enter" onChange={this.handleChange}/>
                        <br/>
                            {this.odStore.SearchSites.map( (item : ISite) => (
                                <div key={item.tag}>
                                    <ReactStrap.Row className={'m-3'}>
                                        <ReactStrap.Col sm={10}>
                                            <ReactStrap.Label><strong>{item.label}</strong></ReactStrap.Label> &nbsp; <ReactStrap.Label>{item.description}</ReactStrap.Label>
                                        </ReactStrap.Col>
                                        <ReactStrap.Col sm={2} className="text-right">
                                            <ReactStrap.Button outline color="primary" onClick={() => this.selectSite(item)}><MdOfflinePin/></ReactStrap.Button>
                                        </ReactStrap.Col>
                                    </ReactStrap.Row>
                                </div>
                            ))}

                    </ReactStrap.TabPane>

                    <ReactStrap.TabPane tabId="2">
                        <br/>
                        <h4>All Unregistered Sites</h4>
                        <br/>
                        Number of unregistered sites: {this.odStore.unregisteredSites.length}
                        <ul>
                            {this.odStore.unregisteredSites.map( (item : ISite) => (
                                <div key={item.tag}>
                                    <ReactStrap.Row className={'m-3'}>
                                        <ReactStrap.Col sm={10}>
                                            <ReactStrap.Label><strong>{item.label}</strong></ReactStrap.Label> &nbsp; <ReactStrap.Label>{item.description}</ReactStrap.Label>
                                        </ReactStrap.Col>
                                        <ReactStrap.Col sm={2} className="text-right">
                                            <ReactStrap.Button outline color="primary" onClick={() => this.selectSite(item)}><MdOfflinePin/></ReactStrap.Button>
                                        </ReactStrap.Col>
                                    </ReactStrap.Row>
                                </div>
                            ))}
                        </ul>
                    </ReactStrap.TabPane>
                </ReactStrap.TabContent>

                    <ReactStrap.Modal isOpen={this.state.siteRecordsModal} toggle={this.toggleSiteRecordsModal} size='lg'>
                        <ReactStrap.ModalHeader toggle={this.toggleSiteRecordsModal}>
                            Records held by: {this.odStore.currentSite.label}
                        </ReactStrap.ModalHeader>

                        <OrgSiteRecords></OrgSiteRecords>

                    </ReactStrap.Modal>
            </div>
        );
    }
}

export default MobX.inject('stores')(ReactRouter.withRouter(MobX.observer(OrgDataManager)));