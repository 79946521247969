import * as React from 'react';
import * as ReactStrap from 'reactstrap';
import * as ReactRouter from "react-router-dom";
import {FaTags} from "react-icons/fa";
import * as MobX from "mobx-react";
import {InterfaceStore} from "../store/InterfaceStore";
import {RecordkeepingStore} from "../store/RecordkeepingStore";

import {MdRemoveRedEye} from "react-icons/all";
import TagWidget from "./TagWidget";

import moment from "moment";
import {Global} from "../global/Global";

class NoteRecordEdit extends React.Component <any, any> {

    toggle : any;

    readonly ifStore : InterfaceStore;
    readonly rkStore : RecordkeepingStore;

    constructor(props : any) {
        super(props);

        this.state = {
            //for re-direct navigation
            goNotes: false,
            pendingLeave: '',
            leaveModal: false,
            pageDirty: false,
        };

        this.ifStore = this.props.stores.ifStore;
        this.ifStore.message = null; // to prevent the message being shown when switching page

        this.rkStore = this.props.stores.rkStore;
    }

    handleFieldChange = (event : any) => {
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;

        if (typeof(this.rkStore.recordContent[name]) == "undefined") {
            //default new items to be in the profile version
            this.rkStore.recordContent[name] = {
                versions: ['set0']
            }
        }
        this.rkStore.recordContent[name]['value'] = value;

        this.setState({
            pageDirty: true
        });
    }

    handleSubmit = (event : any) => {
        //THIS IS VERY IMPORTANT
        //https://stackoverflow.com/questions/39809943/react-preventing-form-submission
        event.preventDefault();

        //set up for validating record
        let errors:string[] = []

        if (!this.rkStore.recordDescription){
            errors.push('You need to enter details in the note')
        }

        if (errors.length > 0) {
            this.ifStore.message = {
                message: "Incomplete note",
                errors: errors
            }
        } else {
            this.setState({
                showSpinner: true,
            });

            if (this.rkStore.record.label) {
                this.rkStore.saveRecord()
            } else {
                this.rkStore.recordContent.label = {value: this.rkStore.recordContent.description.value.substring(0, 20) + '...' };
                this.rkStore.recordContent.citeDate = {value: moment().format("YYYY-MM-DD hh:mm:ss")};
                this.rkStore.recordContent.citeDateAccuracy = {value: 1};
                this.rkStore.recordContent.recordCategory = {value: 'Personal'};
                this.rkStore.recordContent.recordType = {value: 'Note'};
                this.rkStore.recordContent.isLocal = {value: 'true'}

                this.rkStore.createRecord()
            }

            this.setState({
                showSpinner: false,
                pageDirty: false,

            });
        }

        event.preventDefault();

        window.scrollTo(0, 0);
    }

    getFieldValue = (name : any) => {
        return this.rkStore.recordContent[name]? this.rkStore.recordContent[name]['value'] : ""
    }

    toggleLeaveModal = () => {
        this.setState((prevState : any) => ({
            leaveModal: !prevState.leaveModal,
        }));
    }

    leavePage = () => {
        this.setState({
            goNotes: true,
        })
    }

    render() {
        if (Global.getOfflineAuthCookie() === false && Global.getOnlineAuthCookie() === false) {
            //alert ('You are not login!!!');
            return <ReactRouter.Redirect to="/login" />;
        }

        if(this.state.goNotes) {
            this.rkStore.getRecords()
            return <ReactRouter.Redirect to="/notes" />;
        }

         return (
             <ReactStrap.Container fluid={true}>

                 {!this.rkStore.record.label &&
                 <ReactStrap.Row style={{padding: "10px"}}>
                     <ReactStrap.Col sm={8}>
                         <h2 className={'m-2'}>Create Note </h2>
                     </ReactStrap.Col>
                 </ReactStrap.Row>
                 }
                 {this.rkStore.record.label &&
                 <ReactStrap.Row style={{padding: "10px"}}>
                     <ReactStrap.Col sm={8}>
                         <h2 className={'m-2'}>Update Note </h2>
                     </ReactStrap.Col>
                     <ReactStrap.Col className="text-right">
                         <ReactStrap.Button outline color="primary" onClick={() => {
                             if (this.state.pageDirty) {
                                 this.toggleLeaveModal()
                             } else {
                                 this.setState({
                                     goNotes: true,
                                 })
                             }
                         }}> <MdRemoveRedEye/></ReactStrap.Button>
                     </ReactStrap.Col>
                 </ReactStrap.Row>
                 }

                 <ReactStrap.Form onSubmit={this.handleSubmit}>

                     <ReactStrap.FormGroup row className={'m-2'}>
                         <ReactStrap.Col sm={12}>
                             <ReactStrap.Input style={{height: '100px'}} type="textarea" id="description" name="description" placeholder="Note details" value={this.getFieldValue('description')} onChange={this.handleFieldChange}/>
                         </ReactStrap.Col>
                     </ReactStrap.FormGroup>

                     <ReactStrap.FormGroup row className={'m-2'}>
                         <ReactStrap.Label sm={2}>
                             <FaTags/> Tags:
                         </ReactStrap.Label>
                         <ReactStrap.Col sm={8}>
                             <TagWidget handleFieldChange={this.handleFieldChange}/>
                         </ReactStrap.Col>
                     </ReactStrap.FormGroup>

                     <ReactStrap.Row>
                         <ReactStrap.Col sm={8}>
                             <ReactStrap.Button color="primary" type="submit" className={'ml-4'}>Save</ReactStrap.Button>
                         </ReactStrap.Col>
                         <ReactStrap.Col className="text-right" sm={4}>
                            <ReactStrap.Button className={'ml-4'}  onClick={this.leavePage}>Cancel</ReactStrap.Button>
                         </ReactStrap.Col>
                     </ReactStrap.Row>
                 </ReactStrap.Form>

                 <ReactStrap.Modal isOpen={this.state.leaveModal} toggle={this.toggleLeaveModal}>
                     <ReactStrap.ModalHeader toggle={this.toggle}>Unsaved Changes</ReactStrap.ModalHeader>
                     <ReactStrap.ModalBody>
                         You have unsaved changes on this page.  Leave page?
                     </ReactStrap.ModalBody>
                     <ReactStrap.ModalFooter>
                         <ReactStrap.Button color="primary" onClick={this.leavePage}>Leave</ReactStrap.Button>{' '}
                         <ReactStrap.Button color="secondary" onClick={this.toggleLeaveModal}>Cancel</ReactStrap.Button>
                     </ReactStrap.ModalFooter>
                 </ReactStrap.Modal>

              </ReactStrap.Container>
        );
    }
}
export default MobX.inject('stores')(ReactRouter.withRouter(MobX.observer(NoteRecordEdit)));