import * as Cookies from 'js-cookie';
import * as Crypto from 'crypto-js';
import moment from 'moment';

export let Global = {

    DEFAULT_IDLE_TIME : 30,
    DEFAULT_COUNTDOWN_TIME : 20,
    NO_DB_NAME : 'N/A',

    // api URL prefix
    getUrlPrefix : function() {
        return this.getUrlHost() + "api/"
    },

    getUrlHost : function() {
        //return "https://localhost:8443/"
        return "https://server.rirproject.info:8443/";
    },


    // Cookie for authentication
    setOnlineAuthCookie : function(authStatus : any) {
        Cookies.set('online-auth', authStatus);
    },
    setOfflineAuthCookie : function (authStatus : any) {
        Cookies.set('offline-auth', authStatus);
    },
    getOnlineAuthCookie : function () {
        let authStatus = Cookies.get('online-auth');
        //alert('online-auth = ' + authStatus);
        if (typeof authStatus == 'undefined') {
            return false;
        } else {
            if (authStatus === false.toString()) {
                return false
            } else {
                return true;
            }
        }
    },
    getOfflineAuthCookie : function () {
        let authStatus = Cookies.get('offline-auth');
        //alert('offline-auth = ' + authStatus);
        if (typeof authStatus == 'undefined') {
            return false;
        } else {
            if (authStatus === false.toString()) {
                return false
            } else {
                return true;
            }
        }
    },

    // Local hashed username and password
    setLocalUsernamePasswordCookie : function (username : any, password : any) {
        let hashedUsername = Crypto.SHA256(username).toString();
        let hashedPassword = Crypto.SHA256(password).toString();
        Cookies.set('hashed-username', hashedUsername);
        Cookies.set('hashed-password', hashedPassword);
    },
    checkLocalUsernamePasswordCookie : function (username : any, password : any) {
        let storedHashedUsername = Cookies.get('hashed-username');
        let storedHashedPassword = Cookies.get('hashed-password');
        if (typeof storedHashedUsername == 'undefined' || typeof storedHashedPassword == 'undefined') {
            return false;
        }
        let hashedUsername = Crypto.SHA256(username).toString();
        let hashedPassword = Crypto.SHA256(password).toString();
        if (storedHashedUsername === hashedUsername && storedHashedPassword === hashedPassword) {
            return true;
        }
        return false;
    },
    setAuthTokenCookie : function(token : any) {
        Cookies.set('online-auth-token', token);
    },
    getAuthTokenCookie : function () {
        return Cookies.get('online-auth-token');
    },


    // Online Offline
    isOnline : function () {
        return navigator.onLine;
    },

    // Records
    isJsonObjEmpty(jsonObj : any) {
        //console.log('Object.entries(jsonObj).length????', Object.entries(jsonObj).length);
        if (Object.entries(jsonObj).length === 0 && jsonObj.constructor === Object) {
            return true;
        }
        return false;
        //return JSON.stringify(jsonObj) === JSON.stringify({});
    },

    isObjectEmpty(obj:any) {
        for (var key in obj) {
            if (obj.hasOwnProperty(key))
                return false;
        }
        return true;
    },

    idFromUrl(url:string){
        return url.substring(url.lastIndexOf('/')+1)
    },

    getDateString(date: string, accuracy: number) {
        let result:string = 'No Date'

        //If we've already determined that there's no date, then let it through
        if (date === result) {
            return result
        }

        let acc = Number(accuracy)

        if (date) {
            if (acc === 1 || acc === 0) {
                result = moment(date).format("DD MMM YYYY")
            } else if (acc === 2) {
                result = moment(date).format("MMM YYYY")
            } else if (acc === 3) {
                result = moment(date).format("YYYY")
            }
        }

        return result;
    },

    determineRecordDate (rec:any){
        let result:string = ''
        let content:any = {}

        if (rec.content) {
            content = JSON.parse(rec.content)
        }

        if (content && content["mydate"] && content["mydate"]['value'] ) {
            result = content["mydate"]['value']
        } else if (content && content["citeDate"] && content["citeDate"]['value'] ) {
            result = content["citeDate"]['value']
        } else {
            result = rec.citeDate
        }

        if(!result) {
            result = 'No Date'
        }

        return result
    },

//    getFuncName() {
//        return this.getFuncName.caller.name.toString();
//    },
};

/*
///////Scenarios////////

* Within the app:
- Login (T), Online (T) => Login (F), Online (T): this happens when user clicks lock out button
 -> remove token, set online and offline authentication to false.
- Login (T), Online(T) => Login (T), Online (F): this happens when internet is gone
 -> set online authentication to false.
- Login (T), Online (T) => Login (F), Online (F): this happens when user clicks lock out button
  then the internet is gone or the other way
  -> remove token, set online and offline authentication to false

- Login (T), Online (F) => Login (F), Online (T): this happens when user clicks lock out button
when offline then internet is on again
 -> remove token, set online and offline authentication to false.
- Login (T), Online(F) => Login (F), Online (F): this happens when user clicks lock out button
when offline
 -> remove token, set online and offline authentication to false.
- Login (T), Online (F) => Login (T), Online (T): this happens when internet is on again
  -> set online authentication to true

- Login (F), Online (T) => Login (T), Online (T): this happens when user clicks lock in button
 -> store token, set online and offline authentication to true.
- Login (F), Online(T) => Login (T), Online (F): this happens when internet is gone after or
before user login
 -> set online authentication to false.
- Login (F), Online (T) => Login (F), Online (F): this happens when internet is gone after user already
logout
  -> do nothing

- Login (F), Online (F) => Login (F), Online (T): this happens when internet is on again after user already
logout
 -> do nothing
- Login (F), Online(F) => Login (T), Online (F): this happens when user clicks log in button
when offline
 -> set offline authentication to true.
- Login (F), Online (F) => Login (T), Online (T): this happens when internet is on again after or before
 user logging in
  -> set online and offline authentication to true

* Restarting the app:
-blblbabalb


++++  WE DONT REALLY NEED TO CARE ABOUT THE TRANSITIONS BETWEEN STATES, AS LONG AS WE KNOW WHAT IS THE CURRENT STATE
WE JUST NEED TO DO WHATEVER REQUIRED FOR THAT CURRENT STATE +++++

 */