import * as React from 'react';
import {Global} from "../../global/Global";
import * as RectRouter from "react-router-dom";

export default class ResetPasswordSuccess extends React.Component <any, any>{

    constructor(props : any) {
        super(props);
        this.state = {};
    }

    render() {
        if (Global.getOfflineAuthCookie() === false && Global.getOnlineAuthCookie() === false) {
            //alert ('You are not login!!!');
            return <RectRouter.Redirect to="/login" />;
        }
        return (
            <div>
                You have successfully changed your password.  Please <a href={"/login"}>log in</a>.
            </div>
        );
    }
}