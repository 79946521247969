import * as React from 'react';
import {Global} from '../global/Global';
import * as ReactRouter from 'react-router-dom';
import * as ReactStrap from 'reactstrap';
import {MdDeleteForever, MdEdit, MdHistory, MdPhonelink, MdPhonelinkOff} from 'react-icons/md';
import {FaShareAlt} from 'react-icons/fa';
import * as Emoji from "emoji-mart";
import * as MobX from "mobx-react";
import {InterfaceStore} from "../store/InterfaceStore";
import {RecordkeepingStore} from "../store/RecordkeepingStore";
import RecordSharer from "../components/RecordSharer";
import BioRecord from "../components/BioRecord";
import RegisteredRecord from "../components/RegisteredRecord";
import PersonalRecord from "../components/PersonalRecord";

class Record extends React.Component <any, any> {
    toggle : any;

    readonly ifStore : InterfaceStore;
    readonly rkStore : RecordkeepingStore;

    constructor(props : any) {
        super(props);
        this.state = {
            goEditRecord: false,
            goManageShares: false,
            goHistory: false,
            goDelete: false,
            goRecords: false,

            deleteModal: false,
            versionSelected: 'set0',
        };

        this.ifStore = this.props.stores.ifStore;
        this.ifStore.message = null; // to prevent the message being shown when switching page

        this.rkStore = this.props.stores.rkStore;
    }

    isValidVersion = (set : string) => {
        return this.rkStore.versionDefs[set] && this.rkStore.versionDefs[set].label && this.rkStore.versionDefs[set].icon !== "question";
    }

    onVersionSelectionClick = (set : any) => {
        this.setState({
            versionSelected: set
        });
    }

    toggleDeleteModal = () => {
        this.setState((prevState : any) => ({
            deleteModal: !prevState.deleteModal,
        }));
    }

    doDelete = () => {
        this.rkStore.deleteRecord()
        this.toggleDeleteModal()

        this.setState({
            goRecords: true,
        })

    }

    setLocal = (local:boolean) => {
        this.rkStore.recordInstanceContent['isLocal'] = {value : local}
        this.rkStore.saveRecord()
    }

    render() {

        //Redirects
        if (Global.getOfflineAuthCookie() === false && Global.getOnlineAuthCookie() === false) {
            //alert ('You are not login!!!');
            return <ReactRouter.Redirect to="/login" />;
        }

        if(this.state.goEditRecord) {
            return <ReactRouter.Redirect to="/recordedit" />;
        }

        if(this.state.goManageShares) {
            return <ReactRouter.Redirect to="/manageshares"/>
        }

        if(this.state.goHistory) {
            return <ReactRouter.Redirect to="/history"/>
        }

        if(this.state.goRecords) {
            return <ReactRouter.Redirect to="/records"/>
        }

        let rec : any = this.rkStore.record;

        let versions : { [key: string]:{icon: string, label: string }} = this.rkStore.versionDefs;
        let versionsRetrieved : boolean = (Object.keys(versions).length > 0)

        //Decide which sort of record to display
        let recordComponent: any = null;
        let canDelete = false

        if(rec && rec.recordcategory === 'Personal' && rec.recordtype === 'Bio') {
            recordComponent = <BioRecord versionSelected={this.state.versionSelected} />
            canDelete = false;
        } else if (rec && rec.originid) {
            recordComponent = <RegisteredRecord versionSelected={this.state.versionSelected} />
            canDelete = false;
        } else {
            recordComponent = <PersonalRecord versionSelected={this.state.versionSelected} />
            canDelete = true;
        }

        let content: any = this.rkStore.recordContent

        let isLocal = false;
        if (content && content['isLocal'] && content['isLocal']['value'] && content['isLocal']['value'] === true) {
            isLocal = true;
        }

        return (
            <ReactStrap.Container fluid={true}>
                {rec && content && versionsRetrieved &&
                <React.Fragment>
                    <ReactStrap.Row id={"Versions"} style={{padding: "10px"}}>
                        <ReactStrap.Col sm={7}>
                            <div>
                                <ReactStrap.ButtonGroup>
                                    {this.isValidVersion("set0") &&
                                    <ReactStrap.Button outline color="success" size="sm"
                                                       onClick={() => this.onVersionSelectionClick('set0')}
                                                       active={this.state.versionSelected === 'set0'}><Emoji.Emoji
                                        emoji={versions.set0.icon} size={20}/></ReactStrap.Button>
                                    }
                                    {this.isValidVersion("set1") &&
                                    <ReactStrap.Button outline color="success" size="sm"
                                                       onClick={() => this.onVersionSelectionClick('set1')}
                                                       active={this.state.versionSelected === 'set1'}><Emoji.Emoji
                                        emoji={versions.set1.icon} size={20}/></ReactStrap.Button>
                                    }
                                    {this.isValidVersion("set2") &&
                                    <ReactStrap.Button outline color="success" size="sm"
                                                       onClick={() => this.onVersionSelectionClick('set2')}
                                                       active={this.state.versionSelected === 'set2'}><Emoji.Emoji
                                        emoji={versions.set2.icon} size={20}/></ReactStrap.Button>
                                    }
                                    {this.isValidVersion("set3") &&
                                    <ReactStrap.Button outline color="success" size="sm"
                                                       onClick={() => this.onVersionSelectionClick('set3')}
                                                       active={this.state.versionSelected === 'set3'}><Emoji.Emoji
                                        emoji={versions.set3.icon} size={20}/></ReactStrap.Button>
                                    }
                                    {this.isValidVersion("set4") &&
                                    <ReactStrap.Button outline color="success" size="sm"
                                                       onClick={() => this.onVersionSelectionClick('set4')}
                                                       active={this.state.versionSelected === 'set4'}><Emoji.Emoji
                                        emoji={versions.set4.icon} size={20}/></ReactStrap.Button>
                                    }
                                    {this.isValidVersion("set5") &&
                                    <ReactStrap.Button outline color="success" size="sm"
                                                       onClick={() => this.onVersionSelectionClick('set5')}
                                                       active={this.state.versionselected === 'set5'}><Emoji.Emoji
                                        emoji={versions.set5.icon} size={20}/></ReactStrap.Button>
                                    }
                                </ReactStrap.ButtonGroup>
                                <ReactStrap.Label style={{paddingLeft: '5px'}}>
                                    {versions[this.state.versionSelected].label}
                                </ReactStrap.Label>

                            </div>

                        </ReactStrap.Col>
                        <ReactStrap.Col className="text-right" sm={5}>
                            <ReactStrap.Button outline color="primary" className="float-right" onClick={() => {
                                this.setState({
                                    goEditRecord: true,
                                })
                            }}> <MdEdit/></ReactStrap.Button>
                            <ReactStrap.Button outline color="primary" className="float-right" onClick={() => {
                                this.setState({
                                    goHistory: true,
                                })
                            }}> <MdHistory/></ReactStrap.Button>
                            <ReactStrap.Button outline color="primary" className="float-right" onClick={() => {
                                this.setState({
                                    goManageShares: true,
                                })
                            }}> <FaShareAlt/></ReactStrap.Button>
                            {canDelete &&
                                <ReactStrap.Button outline color="primary" className="float-right" onClick={() => {
                                    this.setState({
                                        deleteModal: true,
                                    })
                                }}> <MdDeleteForever color={'red'}/></ReactStrap.Button>
                            }
                            {isLocal && rec.recordtype !== 'Bio' &&
                                <ReactStrap.Button outline color="primary" className="float-right" onClick={() => {
                                this.setLocal(false)
                            }}> <MdPhonelinkOff /></ReactStrap.Button>
                            }
                            {!isLocal && rec.recordtype !== 'Bio' &&
                            <ReactStrap.Button outline color="primary" className="float-right" onClick={() => {
                                this.setLocal(true)
                            }}> <MdPhonelink /></ReactStrap.Button>
                            }
                        </ReactStrap.Col>
                    </ReactStrap.Row>

                    <div id={"containerDiv"}>
                        <ReactStrap.Row>
                            <ReactStrap.Col sm={12}>
                                { this.rkStore.recordLabel === "Personal details"
                                && <h4 className={'m-2'}> My Personal Information </h4>}
                                { this.rkStore.recordLabel !== "Personal details"
                                && <h4 className={'m-2'}> {this.rkStore.recordLabel} </h4>
                                }
                            </ReactStrap.Col>
                        </ReactStrap.Row>

                        <ReactStrap.Row>
                            <ReactStrap.Col sm={12}>
                                <h5 className={'m-2'}> {'[' + rec.recordcategory + " record, type = " + rec.recordtype + '] Created: ' + Global.getDateString(this.rkStore.recordDate, this.rkStore.recordDateAccuracy) } </h5>
                            </ReactStrap.Col>
                        </ReactStrap.Row>

                        {recordComponent}
                    </div>

                    <RecordSharer selected={this.state.versionSelected} />

                </React.Fragment>
                }

                    <ReactStrap.Modal isOpen={this.state.deleteModal} toggle={this.toggleDeleteModal}>
                        <ReactStrap.ModalHeader toggle={this.toggle}>Delete Record</ReactStrap.ModalHeader>
                        <ReactStrap.ModalBody>
                            Permanently delete this label, notes etc. from the system (uploaded attachment will not be deleted)? This cannot be undone.
                        </ReactStrap.ModalBody>
                        <ReactStrap.ModalFooter>
                            <ReactStrap.Button color="primary" onClick={this.doDelete}>Delete</ReactStrap.Button>{' '}
                            <ReactStrap.Button color="secondary" onClick={this.toggleDeleteModal}>Cancel</ReactStrap.Button>
                        </ReactStrap.ModalFooter>
                    </ReactStrap.Modal>

            </ReactStrap.Container>

        );
    }
}
export default MobX.inject('stores')(ReactRouter.withRouter(MobX.observer(Record)));