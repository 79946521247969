import BaseDao from "./BaseDao";
import {Global} from '../global/Global';
import {ISettings} from "../store/AppStore";
//import LocalDatabase from './LocalDatabase';

export default class AppDao extends BaseDao {

    constructor () {
        super();
        this.syncObj = {
            local_db_name : 'settings',
        }
    }

    //implement the abstract function fetchAPI from BaseDao
    fetchAPI(): Promise<any> {
        let url = Global.getUrlPrefix() + 'getsettings';
        return fetch(url, {
            method: 'GET',
            mode: "cors",
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + Global.getAuthTokenCookie()
            }
        });
    }

    //implement the abstract function constructDefaultObj from BaseDao
    constructDefaultObj(): any {
        return {
            idletime : Global.DEFAULT_IDLE_TIME,
            countdowntime : Global.DEFAULT_COUNTDOWN_TIME,
            modifiedTime : Date.now(),
        };
    }

    //implement the abstraction function constructContentObj from BaseDao
    constructContentObj(content: any): any {
        let contentObj : any = JSON.parse(content);
        let settings : ISettings = contentObj as ISettings;
        return {
            idletime : settings.idletime,
            countdowntime: settings.countdowntime,
            modifiedTime : Date.now()
        };
    }

    //implement the abstract function pushAPI from BaseDao
    pushAPI(content : any): Promise<any> {
        let contentObj : any = JSON.parse(content);
        let settings : ISettings = contentObj as ISettings;

        let url = Global.getUrlPrefix() + 'putsettings';

        let jsonObj = {
            content: {
                idletime : settings.idletime,
                countdowntime: settings.countdowntime,
                modifiedTime : Date.now()
            }
        };
        let jsonData = JSON.stringify(jsonObj);

        return fetch(url, {
            method: 'PUT',
            mode: "cors",
            body: jsonData,
            headers: {
                'Authorization': 'Bearer ' + Global.getAuthTokenCookie()
            }
        });
    }

    fetchSettings () {
        return this.fetchBase();
    }

    pushSettings (settings : string) {
        return this.pushBase(settings);
    }
}