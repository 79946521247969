import * as React from "react";
import * as ReactStrap from 'reactstrap';
import {Global} from "../global/Global";
import * as ReactRouter from "react-router-dom";
import {InterfaceStore} from "../store/InterfaceStore";
import {RecordkeepingStore} from "../store/RecordkeepingStore";
import * as MobX from "mobx-react";
import {FiFilePlus} from "react-icons/all";
import RecordsFilterWidget from "../components/RecordsFilterWidget";
import RecordList from "../components/RecordList";

class Records extends React.Component <any, any> {
    readonly ifStore: InterfaceStore;
    readonly rkStore: RecordkeepingStore;

    constructor(props: any) {
        super(props);

        this.ifStore = this.props.stores.ifStore;
        this.ifStore.message = null; // to prevent the message being shown when switching page

        this.rkStore = this.props.stores.rkStore;

        this.state = {
            goCreateRecord: false,
            recordsDisplayList: null
        };

    }

    handleCreate = () => {
        this.rkStore.blankRecord()
            this.setState({
                goCreateRecord: true,
            });
    }

    onRecordListChanged = (list:any) => {
        this.setState({
            recordsDisplayList: list,
        });
    }

    render() {
        if (Global.getOfflineAuthCookie() === false && Global.getOnlineAuthCookie() === false) {
            //alert ('You are not login!!!');
            return <ReactRouter.Redirect to="/login"/>;
        }

        if(this.state.goCreateRecord) {
            //new blank record
            return <ReactRouter.Redirect to="/recordedit" />;
        }

        let title = 'My Records'
        let filter = (rec:any ) => {
            return rec.recordcategory !== "Recordkeeping" && !(rec.recordcategory === 'Personal' && rec.recordtype === 'Note')  &&  !(rec.label.startsWith('Version of'))
        };

        //If we've com from a serch, then change the title and let notes through the filter
        if (this.rkStore.filterString) {
            title = 'Search Results'
            filter = (rec:any ) => {
                return rec.recordcategory !== "Recordkeeping" && !(rec.label.startsWith('Version of'))
            };
        }

        let preFilteredRecords:any = this.rkStore.records.filter(filter)

        let displayRecords:any = []

        if(this.state.recordsDisplayList == null)  {
            displayRecords = preFilteredRecords
        } else {
            displayRecords = this.state.recordsDisplayList
        }

        return (
            <ReactStrap.Container fluid={true}>
                <ReactStrap.Row style={{padding: "10px"}}>
                    <ReactStrap.Col sm={8}>
                        <h2 className={'m-2'}>{title}</h2>
                    </ReactStrap.Col>
                    <ReactStrap.Col className="text-right" sm={4}>
                        <ReactStrap.Button outline color="primary" className="float-right" onClick={this.handleCreate}> <FiFilePlus/></ReactStrap.Button>
                    </ReactStrap.Col>
                </ReactStrap.Row>

                <ReactStrap.Row style={{padding: "10px"}}>
                    <RecordsFilterWidget records={preFilteredRecords} onChange={this.onRecordListChanged} />
                </ReactStrap.Row>

                <RecordList records={displayRecords}/>

            </ReactStrap.Container>

        );
    }
}
export default MobX.inject('stores')(ReactRouter.withRouter(MobX.observer(Records)))