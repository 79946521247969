import * as React from "react";
import * as Emoji from "emoji-mart";
import * as ReactStrap from 'reactstrap';
import {MdEdit} from "react-icons/md";

export default class VersionsLegend extends React.Component <any, any> {
    handleNav : any;
    constructor(props : any) {
        super(props);
        this.state = {
            versions: {
                set0: {
                    label:"Profile",
                    icon:"bust_in_silhouette"
                },
                set1: {
                    label:null,
                    icon:null
                },
                set2: {
                    label:null,
                    icon:null
                },
                set3: {
                    label:null,
                    icon:null
                },
                set4: {
                    label:null,
                    icon:null
                },
                set5: {
                    label:null,
                    icon:null
                },
            },
        };

        this.handleNav = props.goVersionsEdit;

        this.isValidVersion = this.isValidVersion.bind(this)
    }

    componentWillMount() {
        this.setState({
            versions: this.props.versions,
        });
    }

    isValidVersion(set : any) {
        return this.state.versions[set] && this.state.versions[set].label && this.state.versions[set].icon !== "question"
    }

    render() {
        return (
            <div>
                {this.isValidVersion("set0") &&
                        <ReactStrap.Label><Emoji.Emoji emoji={this.state.versions.set0.icon} size={20}/> &nbsp; {this.state.versions.set0.label} &nbsp; </ReactStrap.Label>
                }
                {this.isValidVersion("set1") &&
                    <ReactStrap.Label><Emoji.Emoji emoji={this.state.versions.set1.icon} size={20}/> &nbsp; {this.state.versions.set1.label} &nbsp; </ReactStrap.Label>
                }
                {this.isValidVersion("set2") &&
                    <ReactStrap.Label><Emoji.Emoji emoji={this.state.versions.set2.icon} size={20}/> &nbsp; {this.state.versions.set2.label} &nbsp; </ReactStrap.Label>
                }
                {this.isValidVersion("set3") &&
                    <ReactStrap.Label><Emoji.Emoji emoji={this.state.versions.set3.icon} size={20}/> &nbsp; {this.state.versions.set3.label} &nbsp; </ReactStrap.Label>
                }
                {this.isValidVersion("set4") &&
                    <ReactStrap.Label><Emoji.Emoji emoji={this.state.versions.set4.icon} size={20}/> &nbsp; {this.state.versions.set4.label} &nbsp; </ReactStrap.Label>
                }
                {this.isValidVersion("set5") &&
                    <ReactStrap.Label><Emoji.Emoji emoji={this.state.versions.set0.icon} size={20}/> &nbsp; {this.state.versions.set0.label} &nbsp; </ReactStrap.Label>
                }

                <ReactStrap.Button outline color="primary" size="sm" style={{marginTop: '-5px'}} onClick={this.handleNav}> <MdEdit /></ReactStrap.Button>

            </div>
        );
    }
}