import {Global} from "../global/Global";

import BaseDao from "./BaseDao";

export default class RecordVersionsDao  extends BaseDao {

    recVersionsHref : string = '';

    constructContentObj(content: any): any {
    }
    constructDefaultObj(): any {
    }
    fetchAPI(): Promise<any> {
        return new Promise<any>(function () {
        });
    }
    pushAPI(content: any): Promise<any> {
        return new Promise<any>(function () {
        });
    }

    fetchRecordVersionDefs() {
        //todo: sync with local version
        /**********************************************************************************
         * Go get it
         **********************************************************************************/
        let url = Global.getUrlPrefix() + 'record?c=Recordkeeping&t=Versiondefs';

        return fetch(url, {
            method: 'GET',
            mode: "cors",
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + Global.getAuthTokenCookie()
            }
        }).then(response => {
            return response.json();
        }).then(responseObject => {
            //save the href away for later
            if(responseObject && responseObject.content && responseObject.content[0]) {
                this.recVersionsHref = responseObject.content[0].href;
            }

            return this.promiseNormalisedResponse('loading record version defs', responseObject)
        }).catch( (e) => {
            return this.promiseNormalisedResponse('loading record version defs', e.message)
        });

    }

    saveRecordVersionDefs(versions: any) {
        //todo: sync with local version

        let contentPart : any = {};
        contentPart.content = JSON.stringify(versions);
        let payload = JSON.stringify(contentPart);

        return fetch(this.recVersionsHref, {
            method: 'PUT',
            mode: "cors",
            body: payload,
            headers: {
                'Authorization': 'Bearer ' + Global.getAuthTokenCookie()
            }
        }).then(response => {
            return response.json();
        }).then(responseObject => {
            return this.promiseNormalisedResponse('saving record version defs', responseObject)
        }).catch( (e) => {
            return this.promiseNormalisedResponse('saving record version defs', e.message)
        });

    }

    createSharedVersion( blob: Blob, versionShare : any) {

        /*---------------------------------------------------
         * First, create the attachment
         *---------------------------------------------------*/
        let content = {
            name: "Version",
            filename: versionShare.filename
        };

        //Create a multipart payload, starting with the whole record
        var payload = new FormData()
        payload.append("content", JSON.stringify(content));
        payload.append("Version", blob);

        let url = Global.getUrlPrefix() + 'attachment';

        return fetch(url, {
            method: 'POST',
            mode: "cors",
            body: payload,
            headers: {
                'Authorization': 'Bearer ' + Global.getAuthTokenCookie()
            }
        }).then(response => {
            return response.json();
        }).then(responseObject => {
            /*---------------------------------------------------
             * Next, create the version record
             *---------------------------------------------------*/
            let respContent = responseObject.content;
            versionShare["attachmentId"] = respContent.href.substring(respContent.href.lastIndexOf('/') + 1);
            versionShare["attachmentHref"] = respContent.externalHref;

            let content : any = {};
            content['tag'] = versionShare.tag ? versionShare.tag : "Not Specified";
            content['versionId'] = versionShare.versionSelected;
            content['label'] = 'Version of Record';
            content['description'] = 'PDF/Image Version of Record';
            content['recordId'] = versionShare.recordId;
            content['dateExpired'] = versionShare.expiryDate;
            content['content'] = {
                code: versionShare.code,
                includeHeader: versionShare.includeHeader
            };

            let jsonData = JSON.stringify(content);

            let url = Global.getUrlPrefix() + 'recordversion';

            return fetch(url, {
                method: 'POST',
                mode: "cors",
                body: jsonData,
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + Global.getAuthTokenCookie()
                }
            })
        }).then(response => {
            return response.json();
        }).then(responseObject => {
            /*---------------------------------------------------
             * And link the attachment to the version record
             *---------------------------------------------------*/
            let responseContent = responseObject.content;

            let content : any = {};
            content['label'] = 'Version of Record';
            content['description'] = 'PDF Version of Record';
            content['recordId'] = responseContent.href.substring(responseContent.href.lastIndexOf('/') + 1);
            content['attachmentId'] = versionShare.attachmentId;

            let jsonData = JSON.stringify(content);

            let url = Global.getUrlPrefix() + 'linkattachment';

            return fetch(url, {
                method: 'POST',
                mode: "cors",
                body: jsonData,
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + Global.getAuthTokenCookie()
                }
            })
        }).then(response => {
            return response.json();
        }).then(responseObject => {
            return this.promiseNormalisedResponse('saving share version', responseObject)
        }).catch( (e) => {
            return this.promiseNormalisedResponse('saving share version', e.message)
        });
    }

    fetchRecordVersions(id: string){
        let url = Global.getUrlPrefix() + 'recordversion?id=' + id;

        return fetch(url, {
            method: 'GET',
            mode: "cors",
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + Global.getAuthTokenCookie()
            }
        }).then(response => response.json()).then(responseObject => {

            //go get all of the individual versions
            let promises = responseObject.content.map((item : any) => fetch(item.href, {
                method: 'GET',
                mode: "cors",
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + Global.getAuthTokenCookie()
                }
            }).then(response => response.json()));

            //wait for them all to complete
            return Promise.all(promises)
        }).then(results => {
            return this.promiseNormalisedResponse('loading record versions', results)
        }).catch( (e) => {
            return this.promiseNormalisedResponse('loading record versions', e.message)
        });
    }

/*    deleteRecordVersion(version: any) {
        let url = version.attachments[0].deleteHref;

        return fetch(url, {
            method: 'DELETE',
            mode: "cors",
            headers: {
                'Authorization': 'Bearer ' + Global.getAuthTokenCookie()
            }
        }).then(response => response.json()
        ).then(responseObject => {
            let url = version.attachments[0].attachment.href;
            return fetch(url, {
                method: 'DELETE',
                mode: "cors",
                headers: {
                    'Authorization': 'Bearer ' + Global.getAuthTokenCookie()
                }
            })
        }).then(response => response.json()
        ).then(responseObject => {
            let url = version.href;
            return fetch(url, {
                method: 'DELETE',
                mode: "cors",
                headers: {
                    'Authorization': 'Bearer ' + Global.getAuthTokenCookie()
                }
            })
        }).then(results => {
            return this.promiseNormalisedResponse('deleting record version', results)
        }).catch( (e) => {
            return this.promiseNormalisedResponse('deleting record version', e.message)
        });
    }*/

    deleteRecordVersion(version: any) {
        if (version.attachments && version.attachments[0] && version.attachments[0].attachment) {
            let attUrl =version.attachments[0].attachment.href;
            fetch(attUrl, {
                method: 'DELETE',
                mode: "cors",
                headers: {
                    'Authorization': 'Bearer ' + Global.getAuthTokenCookie()
                }
            });
        }

        let url = version.href;

        return fetch(url, {
            method: 'DELETE',
            mode: "cors",
            headers: {
                'Authorization': 'Bearer ' + Global.getAuthTokenCookie()
            }
        }).then(response => response.json()
        ).then(results => {
            return this.promiseNormalisedResponse('deleting record version', results)
        }).catch( (e) => {
            return this.promiseNormalisedResponse('deleting record version', e.message)
        });
    }

    updateRecordVersion(version: any, payload: any) {
        let url = version.href;

        return fetch(url, {
            method: 'PUT',
            mode: "cors",
            body: payload,
            headers: {
                'Authorization': 'Bearer ' + Global.getAuthTokenCookie()
            }
        }).then(response => {
            return response.json();
        }).then(results => {
            return this.promiseNormalisedResponse('updating record version', results)
        }).catch( (e) => {
            return this.promiseNormalisedResponse('updfating record version', e.message)
        });
    }

}
