import * as React from 'react';
import * as ReactStrap from 'reactstrap';
import * as ReactRouter from "react-router-dom";
import { Global }  from "../../global/Global";
import {InterfaceStore} from "../../store/InterfaceStore";
import * as MobX from "mobx-react";

class ResetPassword extends React.Component <any, any> {
    readonly ifStore : InterfaceStore;

    constructor(props : any) {
        super(props);
        this.state = {
            requestStatus: null,
            showSpinner : false,
            allDone: false
        };

        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleReset = this.handleReset.bind(this);

        this.ifStore = this.props.stores.ifStore;
        this.ifStore.message = null; // to prevent the message being shown when switching page
    }

    handleReset(event : any) {
        //THIS IS VERY IMPORTANT
        //https://stackoverflow.com/questions/39809943/react-preventing-form-submission
        event.preventDefault();

        const formData : any = new FormData(event.target);
        let jsonObject : any = {};
        for (const [key, value]  of formData.entries()) {
            jsonObject[key] = value;
        }
        let jsonData = JSON.stringify(jsonObject);

        let url = Global.getUrlPrefix() + 'anon/resetpassword';

        fetch(url, {
            method: 'POST',
            mode: "cors",
            body: jsonData,
            headers: {
                'Content-Type': 'application/json'
            }
        }).then(response => {
            return response.json();
        }).then(responseObject => {
            if (responseObject.errors.length === 0) {
                this.setState({
                    allDone: true
                });
            } else {
                //So we can alert any error messages
                this.ifStore.message = responseObject;

                //so we can populate the question
                this.setState({
                    requestStatus: responseObject
                });
            }
        }).catch(function (this : ResetPassword, e : any) {
            this.ifStore.message = {error:'Server error when resetting password.'};
            console.log("SOMETHING IS WRONG!", e);
        });
        event.preventDefault();
    }

    handleSubmit(event : any) {
        //THIS IS VERY IMPORTANT
        //https://stackoverflow.com/questions/39809943/react-preventing-form-submission
        event.preventDefault();

        this.setState({
            showSpinner : true,
        });

        const formData : any = new FormData(event.target);
        let jsonObject : any = {};
        for (const [key, value]  of formData.entries()) {
            jsonObject[key] = value;
        }
        let jsonData = JSON.stringify(jsonObject);

        let url = Global.getUrlPrefix() + 'anon/forgotpassword';

        fetch(url, {
            method: 'POST',
            mode: "cors",
            body: jsonData,
            headers: {
                'Content-Type': 'application/json'
            }
        }).then(response => {
            return response.json();
        }).then(responseObject => {
            //So we can alert any success/error messages
            this.ifStore.message = responseObject;

            //so we can populate the question
            this.setState({
                requestStatus: responseObject,
                showSpinner : false
            });

        }).catch(function (this : ResetPassword, e : any) {
            console.log("SOMETHING IS WRONG!", e);
            this.setState({
                showSpinner : false,
            });
        });
        event.preventDefault();
    }


    render() {
        /*if (Global.getOfflineAuthCookie() === false && Global.getOnlineAuthCookie() === false) {
            //alert ('You are not login!!!');
            return <ReactRouter.Redirect to="/login" />;
        }*/
        if (this.state.allDone) {
            return(<ReactRouter.Redirect to="/resetpasswordsuccess" />);
        } else return (
            <div>
                {this.state.showSpinner ? <ReactStrap.Spinner className={'m-4'} color="info"/> : null}
                <h2>Reset Password</h2>
                <ReactStrap.Form onSubmit={this.handleSubmit}>
                    <ReactStrap.FormGroup row className={'m-2'}>
                        <ReactStrap.Label sm={2}>
                            Email
                        </ReactStrap.Label>
                        <ReactStrap.Col sm={10}>
                            <ReactStrap.Input type="email" id="email" name="email" placeholder="Email"/>
                        </ReactStrap.Col>
                    </ReactStrap.FormGroup>
                    <ReactStrap.FormGroup row className={'m-2'}>
                        <ReactStrap.Label sm={3}>
                            Send a security code to your email address
                        </ReactStrap.Label>
                        <ReactStrap.Col sm={2}>
                            <ReactStrap.Button type="submit" className={'ml-4'}>Get Code</ReactStrap.Button>
                        </ReactStrap.Col>
                    </ReactStrap.FormGroup>

                </ReactStrap.Form>

                <ReactStrap.Form onSubmit={this.handleReset}>
                    <ReactStrap.FormGroup row className={'m-2'}>
                        <ReactStrap.Label sm={2}>
                            Security Code
                        </ReactStrap.Label>
                        <ReactStrap.Col sm={10}>
                            <ReactStrap.Input type="text" id="token" name="token" placeholder="Code"/>
                        </ReactStrap.Col>
                    </ReactStrap.FormGroup>

                    <ReactStrap.FormGroup row className={'m-2'}>
                        <ReactStrap.Label sm={2}> New Password </ReactStrap.Label>
                        <ReactStrap.Col sm={10}>
                            <ReactStrap.Input type="password" id="password" name="password" placeholder="Password"/>
                        </ReactStrap.Col>
                    </ReactStrap.FormGroup>

                    <ReactStrap.FormGroup row className={'m-2'}>
                        <ReactStrap.Label sm={2}> Repeat New Password </ReactStrap.Label>
                        <ReactStrap.Col sm={10}>
                            <ReactStrap.Input type="password" id="password2" name="password2" placeholder="Repeat Password"/>
                        </ReactStrap.Col>
                    </ReactStrap.FormGroup>

                    <ReactStrap.FormGroup row className={'m-2'}>
                        <ReactStrap.Label sm={2}> Security Question </ReactStrap.Label>
                        <ReactStrap.Col sm={10}>
                            <ReactStrap.Input type="text" id="question" name="question" readOnly
                                   placeholder={this.state.requestStatus && this.state.requestStatus.question ? this.state.requestStatus.question : ""}/>
                        </ReactStrap.Col>
                    </ReactStrap.FormGroup>

                    <ReactStrap.FormGroup row className={'m-2'}>
                        <ReactStrap.Label sm={2}> Answer </ReactStrap.Label>
                        <ReactStrap.Col sm={10}>
                            <ReactStrap.Input type="text" id="answer1" name="answer1" placeholder="Answer"/>
                        </ReactStrap.Col>
                    </ReactStrap.FormGroup>

                    <ReactStrap.Button type="submit" className={'ml-4'}>Reset Password</ReactStrap.Button>
                </ReactStrap.Form>

            </div>
        );
    }
}

export default MobX.inject('stores')(ReactRouter.withRouter(MobX.observer(ResetPassword)));
