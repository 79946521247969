import * as React from "react";
import * as ReactRouter from "react-router-dom";
import * as ReactStrap from 'reactstrap';
import { Global } from '../global/Global';
import * as MobX from "mobx-react";
import {FaPlus} from "react-icons/fa";
import {InterfaceStore} from "../store/InterfaceStore";
import {UploadsStore} from "../store/UploadsStore";

class Uploader extends React.Component <any, any> {

    pictureInput : any;

    readonly ifStore : InterfaceStore;
    readonly uplStore : UploadsStore;

    constructor(props:any) {
        super(props);
        this.ifStore = this.props.stores.ifStore;
        this.ifStore.message = null; // to prevent the message being shown when switching page

        this.uplStore = this.props.stores.uplStore;

        this.state = {
            addModal: false,
            pictureSrc:'',
            file: null,
            label: '',
        }

        this.toggleAddModal = this.toggleAddModal.bind(this);
        this.handlePictureChange = this.handlePictureChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleFieldChange = this.handleFieldChange.bind(this);
    }

    toggleAddModal() {
        this.setState((prevState : any) => ({
            addModal: !prevState.addModal,
            file: null,
            label: '',
            pictureSrc: null
        }));
    }

    handlePictureChange(event : any) {
        let file : any = event.target.files[0];

        this.setState({file: file});

        let reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onloadend = function (this : Uploader, e : any) {
            this.setState({
                pictureSrc: reader.result
            })
        }.bind(this);
    }

    handleFieldChange(event : any) {
        const target = event.target;

        this.setState({
            label: target.value
        });
    }

    handleSubmit = (event : any) => {
        //THIS IS VERY IMPORTANT
        //https://stackoverflow.com/questions/39809943/react-preventing-form-submission
        event.preventDefault();

        this.setState({
            showSpinner : true,
        });

        let contentPart : any = {};
        contentPart = {
            name: this.state.label ? this.state.label : 'Image',
            filename : this.state.file.name
        };

        //Create a multipart payload, starting with the whole record
        let payload : FormData = new FormData();
        payload.append("content", JSON.stringify(contentPart) );
        payload.append(contentPart.name, this.state.file );

        this.uplStore.createUpload(payload);

        this.toggleAddModal()

        this.setState({
            showSpinner : false,

        });

        window.scrollTo(0, 0);

        event.preventDefault();
    }

    render() {
        if (Global.getOfflineAuthCookie() === false && Global.getOnlineAuthCookie() === false) {
            //alert ('You are not login!!!');
            return <ReactRouter.Redirect to="/login"/>;
        }
        return (
            <ReactStrap.Container >
                <ReactStrap.Button outline color="primary" onClick={() => {
                        this.toggleAddModal()
                }}> <FaPlus /></ReactStrap.Button>

                <ReactStrap.Modal isOpen={this.state.addModal} toggle={this.toggleAddModal}>
                    <ReactStrap.Form onSubmit={this.handleSubmit}>
                        <ReactStrap.ModalHeader toggle={this.toggleAddModal}>Upload File</ReactStrap.ModalHeader>
                        <ReactStrap.ModalBody>

                                <ReactStrap.FormGroup>
                                    <ReactStrap.Row>
                                        <ReactStrap.Col xs={12}>
                                            <ReactStrap.Input type="file" id="upload" name="upload" placeholder="filename" innerRef={picture => (this.pictureInput = picture)} onChange={this.handlePictureChange} />
                                        </ReactStrap.Col>
                                    </ReactStrap.Row>
                                    <p/>
                                    <ReactStrap.Row>
                                        <ReactStrap.Col xs={2}>
                                            <ReactStrap.Label>
                                                Label
                                            </ReactStrap.Label>
                                        </ReactStrap.Col>
                                        <ReactStrap.Col xs={8}>
                                            <ReactStrap.Input type="text" id="label" name="label" placeholder="label" value={this.state.label} onChange={this.handleFieldChange}/>
                                        </ReactStrap.Col>
                                    </ReactStrap.Row>

                                    <ReactStrap.Row>
                                        <ReactStrap.Col xs={12}>
                                        {this.state.pictureSrc &&
                                            <img src={this.state.pictureSrc} alt="thumbnail representation" width="120" height="120"/>
                                        }
                                        </ReactStrap.Col>
                                    </ReactStrap.Row>


                                </ReactStrap.FormGroup>

                        </ReactStrap.ModalBody>
                        <ReactStrap.ModalFooter>
                            <ReactStrap.Button type="submit" className={'ml-4'} color="primary">Upload</ReactStrap.Button>{' '}
                            <ReactStrap.Button color="secondary" className={'ml-4'} onClick={this.toggleAddModal}>Cancel</ReactStrap.Button>
                        </ReactStrap.ModalFooter>
                    </ReactStrap.Form>
                </ReactStrap.Modal>

            </ReactStrap.Container>
        );
    }
}

export default MobX.inject('stores')(ReactRouter.withRouter(MobX.observer(Uploader)));