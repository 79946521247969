import * as React from "react";
import * as ReactStrap from "reactstrap";
import * as MobX from "mobx-react";
import * as ReactRouter from "react-router-dom";
import {IRecord, OrgDataStore} from "../store/OrgDataStore";
import {MdHighlightOff, MdOfflinePin, MdRemoveRedEye} from "react-icons/all";
import Preview from "../components/Preview";
import RecordsFilterWidget from "../components/RecordsFilterWidget";

class OrgSiteRecords extends React.Component <any, any> {
    readonly odStore : OrgDataStore;
    constructor(props : any) {
        super(props);

        this.state = {
            showPreviewModal: false,
            previewItem: null,
            allRegistered: false,
            selected: {},
            recordsDisplayList: null
        };

        this.odStore = this.props.stores.odStore;
    }

    unSelectRecord = (aRecord : IRecord) => {
        this.odStore.deRegisterSiteRecordFromServer(aRecord);
    }

    selectRecord = (aRecord : IRecord) => {
        let selected = this.state.selected

        if (selected[aRecord._id]) {
            delete selected[aRecord._id]
        } else {
            selected[aRecord._id] = true
        }

        this.setState ({
            selected: selected
        })
    }

    handleAllChange = () => {
        let selected:any = {}

        let newValue = !this.state.allRegistered

        if (newValue) {
                this.odStore.NonSelectedSiteRecords.forEach((item: any, index: any) => {
                    selected[item._id] = true
                })
        }

        this.setState({
            allRegistered: newValue,
            selected: selected
        })
    }

    registerAllSelected = () => {
        let records:any = []
        this.odStore.NonSelectedSiteRecords.forEach((item: any, index: any) => {
            let found:number = Object.keys(this.state.selected).indexOf(item._id)
            if (found > -1) {
                records.push(item)
            }
        })

        this.odStore.registerSiteRecordsToServer(records);

        this.setState({
            selected: []
        })
    }

    previewRecord = (item: IRecord) => {
        this.setState({
            previewItem: item
        });

        this.odStore.getPreviewAttachment(this.odStore.currentSite.href, item)

        this.togglePreviewModal()
    }

    togglePreviewModal = () => {
        this.setState((prevState : any) => ({
            showPreviewModal: !prevState.showPreviewModal,
        }));
    }

    onRecordListChanged = (list:any) => {
        this.setState({
            recordsDisplayList: list,
        });
    }

    render() {

        let displayRecords:any = []

        if(this.state.recordsDisplayList == null)  {
            displayRecords = this.odStore.NonSelectedSiteRecords
        } else {
            displayRecords = this.state.recordsDisplayList
        }


        return (
            <div>
            <ReactStrap.ModalBody>
                <h5>Registered Records</h5>
                    {this.odStore.SelectedSiteRecords.map( (item : IRecord) => (
                        <div key={'reg'+item._id}>
                            <ReactStrap.Row className={'m-3'}>
                                <ReactStrap.Col sm={8}>
                                    <ReactStrap.Label><strong>{item.label}</strong></ReactStrap.Label>
                                </ReactStrap.Col>
                                <ReactStrap.Col sm={4} className="text-right">
                                    <ReactStrap.Button outline color="primary" onClick={() => this.previewRecord(item)}><MdRemoveRedEye/></ReactStrap.Button>
                                    <ReactStrap.Button outline color="primary" onClick={() => this.unSelectRecord(item)}><MdHighlightOff/></ReactStrap.Button>
                                </ReactStrap.Col>
                            </ReactStrap.Row>
                        </div>
                    ))}
                <hr/>
                <h5>Unregistered records</h5>
                <ReactStrap.Row style={{padding: "10px"}}>
                    <RecordsFilterWidget records={this.odStore.NonSelectedSiteRecords} onChange={this.onRecordListChanged} hideSite={true}/>
                </ReactStrap.Row>

                <ReactStrap.Row className={'m-3'}>
                        <ReactStrap.Col xs={4}>
                            <ReactStrap.Button outline color="primary" onClick={this.registerAllSelected} disabled={Object.keys(this.state.selected).length === 0}>Register Selected</ReactStrap.Button>
                        </ReactStrap.Col>
                        <ReactStrap.Col xs={7} className="text-right">
                            <ReactStrap.Label>Select all records</ReactStrap.Label>
                        </ReactStrap.Col>
                        <ReactStrap.Col xs={1} className="text-right">
                            <ReactStrap.Input type="checkbox" id="allRegistered" name="allRegistered"  checked={this.state.allRegistered} onChange={this.handleAllChange}/>
                        </ReactStrap.Col>
                    </ReactStrap.Row>
                    {displayRecords.map( (item : IRecord) => (
                        <div key={'unreg'+item._id}>
                            <ReactStrap.Row className={'m-3'}>
                                <ReactStrap.Col sm={8}>
                                    <ReactStrap.Label><strong>{item.label}</strong></ReactStrap.Label> &nbsp; <ReactStrap.Label>{item.description.substr(0, 100)}</ReactStrap.Label>
                                </ReactStrap.Col>
                                <ReactStrap.Col sm={3} className="text-right">
                                    <ReactStrap.Button outline color="primary" onClick={() => this.previewRecord(item)}><MdRemoveRedEye/></ReactStrap.Button>
                                </ReactStrap.Col>
                                <ReactStrap.Col sm={1} className="text-right">
                                    <ReactStrap.Input type="checkbox" id={'select'+item._id} name={'select'+item._id} color="primary" checked={this.state.selected[item._id]} onChange={() => this.selectRecord(item)}/>
                                </ReactStrap.Col>
                            </ReactStrap.Row>
                        </div>
                    ))}

                {this.state.previewItem &&
                    <ReactStrap.Modal isOpen={this.state.showPreviewModal} toggle={this.togglePreviewModal} size={'lg'}>
                        <ReactStrap.ModalHeader toggle={this.togglePreviewModal}>
                            <strong>Preview record</strong>
                        </ReactStrap.ModalHeader>

                        <ReactStrap.Row className={'m-3'}>
                            <ReactStrap.Col sm={10}>
                                <ReactStrap.Label><strong> {this.state.previewItem.label}</strong></ReactStrap.Label>
                            </ReactStrap.Col>
                            {!this.state.previewItem.registered &&
                                <ReactStrap.Col sm={2} className="text-right">
                                    <ReactStrap.Button outline color="primary"
                                                       onClick={() => this.selectRecord(this.state.previewItem)}><MdOfflinePin/></ReactStrap.Button>
                                </ReactStrap.Col>
                            }
                        </ReactStrap.Row>

                        <ReactStrap.Row className={'m-3'}>
                            <ReactStrap.Col sm={12}>
                                {this.state.previewItem.description}
                            </ReactStrap.Col>
                        </ReactStrap.Row>
                        <br/>
                        <Preview/>

                    </ReactStrap.Modal>
                }

            </ReactStrap.ModalBody>


            </div>
        );
    }
}

export default MobX.inject('stores')(ReactRouter.withRouter(MobX.observer(OrgSiteRecords)));

