import * as React from 'react';
import * as ReactStrap from "reactstrap";
import * as ReactRouter from "react-router-dom";

export default class RegisterSuccess extends React.Component <any, any> {
    constructor(props : any) {
        super(props);
        this.state = {
            goLogin: false,
        };
    }

    render() {
        if (this.state.goLogin) {
            return (<ReactRouter.Redirect to={'/login'}/>);
        } else {
            return (
                <div className={'ml-3'}>
                    You have successfully registered. Please
                    <ReactStrap.Button outline color="primary" className={'m-2'} onClick={() => {
                        this.setState({
                            goLogin: true,
                        })
                    }}> Login </ReactStrap.Button>
                </div>
            );
        }
    }
}