import * as React from 'react';
import * as ReactStrap from 'reactstrap';
import {Global} from "../global/Global";
import {MdArrowBack} from 'react-icons/md';
import * as ReactRouter from "react-router-dom";
import * as MobX from "mobx-react";

import HistoryListItem from '../components/HistoryListItem'
import {InterfaceStore} from "../store/InterfaceStore";
import {RecordkeepingStore} from "../store/RecordkeepingStore";
import {Label} from "reactstrap";

class History extends React.Component <any, any> {
    readonly ifStore : InterfaceStore;
    readonly rkStore : RecordkeepingStore;

    constructor(props : any) {
        super(props);

        this.state = {
            goRecord: false,
            allHistory: false,
        };

        this.ifStore = this.props.stores.ifStore;
        this.ifStore.message = null; // to prevent the message being shown when switching page

        this.rkStore = this.props.stores.rkStore;
    }

    handleAllHistoryChange = (event : any) => {
        this.setState({
            allHistory: event.target.checked
        })
    }

    render() {
        if (Global.getOfflineAuthCookie() === false && Global.getOnlineAuthCookie() === false) {
            //alert ('You are not login!!!');
            return <ReactRouter.Redirect to="/login" />;
        }

        if(this.state.goRecord) {
            if (this.state.allHistory ) {
                return <ReactRouter.Redirect to="/records"/>;
            } else if (this.rkStore.record && this.rkStore.record.recordcategory === 'Personal' && this.rkStore.record.recordtype === 'Note') {
                return <ReactRouter.Redirect to="/noteview"/>;
            } else {
                return <ReactRouter.Redirect to="/record"/>;
            }
        }

        let recordId = Global.idFromUrl(this.rkStore.record.href ? this.rkStore.record.href: '')

        let prompt = 'Show history of all records';
        if (this.state.allHistory) {
            prompt = 'Show only history of current record: (' + this.rkStore.record.label + ')';
        }

        let label = this.rkStore.record.label
        if (this.state.allHistory) {
            label = 'All records'
        }

        //filter out housekeeping records
        let recordSet = this.rkStore.records.filter((item:any) => {
            return item.recordcategory !== "Recordkeeping"
        });

        //...aaaand sort on label
        recordSet = recordSet.sort( (recA:any, recB:any) => {
            let a = recA.label.toLowerCase();
            let b = recB.label.toLowerCase();

            return a > b ? 1 : b > a ? -1 : 0;
        })

        return (
            <ReactStrap.Container fluid={true}>
                <ReactStrap.Row style={{padding: "10px"}}>
                    <ReactStrap.Col sm={10}>
                        <h2 className={'m-2'}>Activity History: {label}</h2>
                    </ReactStrap.Col>
                    <ReactStrap.Col className="text-right">
                        <ReactStrap.Button outline color="primary" onClick={() => {
                            this.setState({
                                goRecord: true,
                            })
                        }}> <MdArrowBack /></ReactStrap.Button>
                    </ReactStrap.Col>
                </ReactStrap.Row>

                {this.rkStore.records && (this.rkStore.records.length > 0) &&
                <div>
                    <ReactStrap.Row>
                        <ReactStrap.Col sm={12}>
                            <ReactStrap.CustomInput type="switch" id="allHistory" name="allHistory" label={prompt} value={this.state.allHistory} onChange={this.handleAllHistoryChange} />
                        </ReactStrap.Col>
                    </ReactStrap.Row>
                    <p/>
                </div>
                }

                <ReactStrap.Row>
                    <ReactStrap.Col sm={12}>
                        In the case of an update, use the button on the right to see the record at that time.
                    </ReactStrap.Col>
                </ReactStrap.Row>

                <hr/>

                <ReactStrap.Row>
                    <ReactStrap.Col xs={2}>
                        <ReactStrap.Label><strong>When</strong></ReactStrap.Label>
                    </ReactStrap.Col>
                    <ReactStrap.Col xs={1}>
                        <ReactStrap.Label><strong>Who</strong></ReactStrap.Label>
                    </ReactStrap.Col>
                    <ReactStrap.Col xs={3}>
                        <ReactStrap.Label><strong>Activity</strong></ReactStrap.Label>
                    </ReactStrap.Col>

                    <ReactStrap.Col xs={2}>
                        <ReactStrap.Label><strong>Actions</strong></ReactStrap.Label>
                    </ReactStrap.Col>
                </ReactStrap.Row>

                {this.rkStore.history && this.rkStore.history[recordId] && !this.state.allHistory &&
                <div>
                    {(this.rkStore.history[recordId]).map((recHistory : any) =>
                        <HistoryListItem key={recHistory.id} historyItem={recHistory} />
                    )
                    }
                </div>
                }

                {this.rkStore.history && this.state.allHistory &&
                <div>
                    {(recordSet).map((rec : any) =>
                        <div key={rec.label}>
                            {this.rkStore.history[Global.idFromUrl(rec.href)] && (this.rkStore.history[Global.idFromUrl(rec.href)]).length > 0 &&
                            <div>
                                <Label style={{fontWeight: 'bold', textDecoration: 'underline'}}>{rec.label}</Label>
                                {(this.rkStore.history[Global.idFromUrl(rec.href)]).map((recHistory : any) =>
                                    <HistoryListItem key={recHistory.id} historyItem={recHistory} />
                                )}
                            </div>
                            }
                        </div>
                    )}
                </div>
                }
            </ReactStrap.Container>
        )
    }
}
export default MobX.inject('stores')(ReactRouter.withRouter(MobX.observer(History)));