import * as MobX from 'mobx';
import Stores from "./Stores";

export class InterfaceStore {
    storesInstance : Stores

    /***********************************************************************
     * Report the success/failure of operations via the Message component
     ***********************************************************************/
    responseMessage = null;
    responseMessageVisible = false;

    constructor(storesInstance: Stores) {
        this.storesInstance = storesInstance
    }

    get message() {
        return this.responseMessage;
    }

    set message(msg: any ) {
        if (msg) {
            let objMsg = msg;

            if (typeof msg === 'string' || msg instanceof String) {
                let txtMsg = msg;
                objMsg = {
                    message: txtMsg,
                    errors: []
                };
            }

            this.responseMessage = objMsg;
            this.responseMessageVisible = true;
        } else {
            this.responseMessageVisible = false;
        }
    }

    get messageVisible() {
        return this.responseMessageVisible;
    }

    set messageVisible(vis: boolean) {
        this.responseMessageVisible = vis;
    }

}

MobX.decorate(
    InterfaceStore,
    {
        responseMessage: MobX.observable,
        responseMessageVisible: MobX.observable,

    }
);