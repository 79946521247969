import * as React from 'react';
import * as MobX from "mobx-react";
import * as ReactRouter from "react-router-dom";
import {InterfaceStore} from "../store/InterfaceStore";
import {RecordkeepingStore} from "../store/RecordkeepingStore";

class Attachment extends React.Component <any, any> {
    readonly ifStore : InterfaceStore;
    readonly rkStore : RecordkeepingStore;

    constructor(props : any) {
        super(props);

        this.ifStore = this.props.stores.ifStore;
        this.ifStore.message = null; // to prevent the message being shown when switching page

        this.rkStore = this.props.stores.rkStore;

    }

    render() {

/*        let filename:string = this.rkStore.attachmentFilename;

        let component:any = <object data={this.rkStore.attachment} height={"500px"} width={"100%"} type="application/pdf"><Button color={'link'} onClick={() => {window.open(this.rkStore.attachment, '_blank')}}>
            View record.
        </Button></object>;

        if (filename) {
            if (filename.endsWith('.png') || filename.endsWith('.jpg') || filename.endsWith('.jpeg') || filename.endsWith('.svg')) {
                component = <img alt={'Document that serves as the content for this record'} src={this.rkStore.attachment} />;
            }
        }*/

        let component:any  = <img alt={'Document that serves as the content for this record'} src={this.rkStore.attachment} id={'attachmentImage'} style={{border:'1px solid'}}/>;

        return (
            <div>
                {this.rkStore.attachment &&
                    <div >
                        {component}
                    </div>
                }
            </div>
        );
    }
}

export default MobX.inject('stores')(ReactRouter.withRouter(MobX.observer(Attachment)));