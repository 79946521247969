import * as MobX from 'mobx';
import Stores from "./Stores";
import RecordVersionsDao from "../DataAccess/RecordVersionsDao";
import RecordDao from "../DataAccess/RecordDao";
import {Global} from "../global/Global";
import {IRecord} from "./OrgDataStore";
import OrgDao from "../DataAccess/OrgDao";

export class RecordkeepingStore {
    storesInstance : Stores

    recordVersionsDao : RecordVersionsDao
    recordDao : RecordDao
    orgDao : OrgDao

    /***********************************************************************
     * Store Fields
     ***********************************************************************/
    recVersionDefs : { [key: string]:{icon: string, label: string }} = {}
    recordSet: any = null
    recordInstance :any = null
    recordInstanceContent :any = null
    recordAttachment :any = null
    recordAttachmentHref :any = null
    recordAttachmentFilename :any = null
    recordHistory :any = null
    recordVersions :any = null
    createdVersionHref:any = null
    allRecordTags:any = []
    recordsFilterString:string = ''

    constructor(storesInstance: Stores) {
        this.storesInstance = storesInstance

        this.recordVersionsDao = new RecordVersionsDao();
        this.recordDao = new RecordDao();
        this.orgDao = new OrgDao();
    }

    /***********************************************************************
     * Probably good practice to enforce access through getters/setters
     ***********************************************************************/

    set versionDefs(defs) {
        this.recVersionDefs = defs
    }

    get versionDefs() {
        return this.recVersionDefs
    }

    //-----------------------------------------------------------------------

    //Don't need setter

    get records() {
        return this.recordSet
    }

    //-----------------------------------------------------------------------

    set history(history) {
        this.recordHistory = history
    }

    get history() {
        return this.recordHistory
    }

    //-----------------------------------------------------------------------

    set record(record) {
        this.recordInstance = record;
    }

    get record() {
        return this.recordInstance;
    }

    //-----------------------------------------------------------------------

    set recordContent(content) {
        this.recordInstanceContent = content;
    }

    get recordContent() {
        return this.recordInstanceContent;
    }

    //-----------------------------------------------------------------------

    set attachment(attachment) {
        this.recordAttachment = attachment;
    }

    get attachment() {
        return this.recordAttachment;
    }

    //-----------------------------------------------------------------------

    set attachmentHref(href) {
        this.recordAttachmentHref = href;
    }

    get attachmentHref() {
        return this.recordAttachmentHref;
    }

    //-----------------------------------------------------------------------

    set attachmentFilename(filename) {
        this.recordAttachmentFilename = filename;
    }

    get attachmentFilename() {
        return this.recordAttachmentFilename;
    }

    //-----------------------------------------------------------------------
    get versions() {
        return this.recordVersions;
    }

    //-----------------------------------------------------------------------
    get versionHref() {
        return this.createdVersionHref;
    }

    set versionHref(href: string) {
        this.createdVersionHref = href;
    }

    //-----------------------------------------------------------------------
    get allTags() {
        return this.allRecordTags;
    }

    //-----------------------------------------------------------------------
    get filterString() {
        return this.recordsFilterString;
    }

    set filterString(filter: string) {
        this.recordsFilterString = filter;
    }

    /***********************************************************************
     * Computed values
     ***********************************************************************/
     get recordLabel() {
         if (this.recordInstanceContent && this.recordInstanceContent["mylabel"] && this.recordInstanceContent["mylabel"]['value'] ) {
             return this.recordInstanceContent["mylabel"]['value']
         } else if (this.recordInstanceContent && this.recordInstanceContent["label"] && this.recordInstanceContent["label"]['value'] ) {
             return this.recordInstanceContent["label"]['value']
         } else {
             return this.recordInstance.label
         }
    }

    get recordDescription() {
        if (this.recordInstanceContent && this.recordInstanceContent["mydescription"] && this.recordInstanceContent["mydescription"]['value'] ) {
            return this.recordInstanceContent["mydescription"]['value']
        } else if (this.recordInstanceContent && this.recordInstanceContent["description"] && this.recordInstanceContent["description"]['value'] ) {
            return this.recordInstanceContent["description"]['value']
        } else {
            return this.recordInstance.description
        }
    }

    get recordDate() {
        if (this.recordInstanceContent && this.recordInstanceContent["mydate"] && this.recordInstanceContent["mydate"]['value'] ) {
            return this.recordInstanceContent["mydate"]['value']
        } else if (this.recordInstanceContent && this.recordInstanceContent["citeDate"] && this.recordInstanceContent["citeDate"]['value'] ) {
            return this.recordInstanceContent["citeDate"]['value']
        } else {
            return this.recordInstance.citeDate
        }
    }

    get recordDateAccuracy() {
        if (this.recordInstanceContent && this.recordInstanceContent["mydateaccuracy"] && this.recordInstanceContent["mydateaccuracy"]['value'] ) {
            return this.recordInstanceContent["mydateaccuracy"]['value']
        } else if (this.recordInstanceContent && this.recordInstanceContent["citeDateAccuracy"] && this.recordInstanceContent["citeDateAccuracy"]['value'] ) {
            return this.recordInstanceContent["citeDateAccuracy"]['value']
        } else {
            return this.recordInstance.citeDateAccuracy
        }
    }

    get recordType() {
        if (this.recordInstanceContent && this.recordInstanceContent["recordType"] && this.recordInstanceContent["recordType"]['value'] ) {
            return this.recordInstanceContent["recordType"]['value']
        } else {
            return this.recordInstance.recordtype
        }
    }

    get recordCatType() {
        let cattype: string = '';

        if(this.recordInstanceContent.recordCategory) {
            cattype = this.recordInstanceContent.recordCategory.value + '/' + this.recordInstanceContent.recordType.value
        } else if(this.recordInstance) {
            cattype = this.recordInstance.recordcategory + '/' + this.recordInstance.recordtype
        }

        return cattype;
    }

    get isLocal() {
        return this.recordInstanceContent && this.recordInstanceContent['isLocal'] ? this.recordInstanceContent['isLocal']['value'] : false;
    }

    /***********************************************************************
     * Other actions
     ***********************************************************************/
    getVersionsForItem (name : any) {
        let result : any = [];
        let content : any = this.recordInstanceContent[name];

        if (content){
            result = content.versions
        }

        return result;
    }

    loadRecordVersionDefs = () => {
        this.recordVersionsDao.fetchRecordVersionDefs().then( (recordVersionDefs:any) => {
            if (recordVersionDefs && recordVersionDefs.errors.length > 0) {
                this.storesInstance.ifStore.message = recordVersionDefs;
            } else {
                MobX.runInAction(() => {
                    //make sure any missing ones are changed to blanks
                    this.recVersionDefs = JSON.parse(recordVersionDefs.content[0].content)
                    for (var i = 1; i < 6; i++) {
                        if (!this.recVersionDefs['set' + i]) {
                            this.recVersionDefs['set' + i] =  {
                                label:"",
                                icon:"question"
                            }
                        }
                    }
                })

            }
        }).catch( (e:any) =>{
            this.storesInstance.ifStore.message = this.recordVersionsDao.normaliseResponse('loading record versions', e.message);
    });
    }

    saveRecordVersionDefs = () => {
        this.recordVersionsDao.saveRecordVersionDefs(this.recVersionDefs).then( (result:any) => {
            this.storesInstance.ifStore.message = result;
        }).catch( (e:any) =>{
            this.storesInstance.ifStore.message = this.recordVersionsDao.normaliseResponse('saving record versions', e.message);
        });
    }

    //Get a list of records and retrieve the first one as the chosen record.
    getRecords = (filterString: string = '', get = false, getVersions = false, getHistory = false) => {
            this.filterString = filterString
        //encodeURIComponent(filterString)
            this.recordDao.fetchRecords(filterString ).then( (recs:any) => {
            if (recs && recs.errors.length > 0) {
                this.storesInstance.ifStore.message = recs;
            } else {
                this.recordSet = recs.content.sort ((a:any, b:any) => {
                    let result = 0;
                    if (Global.determineRecordDate(a) < Global.determineRecordDate(b) ) {
                        return -1;
                    }
                    if (Global.determineRecordDate(a) > Global.determineRecordDate(b) ) {
                        return 1;
                    }
                    return result;
                });

                if (get) {
                    this.getRecord(recs.content[0].href);
                }

                if(getVersions) {
                    this.getAllVersions()
                }

                if(getVersions) {
                    this.getAllHistory()
                }

                this.loadTags()

            }
        }).catch( (e:any) =>{
            this.storesInstance.ifStore.message = this.recordVersionsDao.normaliseResponse('loading records', e.message);
        });
    }

    getRecord = (href: string) => {
        this.blankRecord();
        this.recordDao.fetchRecord(href).then( (record:any) => {
            if (record && record.errors.length > 0) {
                this.storesInstance.ifStore.message = record;
            } else {
                this.record = record.content;

                if (record.content.content) {
                    this.recordContent = JSON.parse(record.content.content);
                }

                if(this.record.recordAttachments.length > 0) {
                    this.recordAttachmentFilename = this.record.recordAttachments[0].attachment.fileName;
                    this.getAttachment(this.record.recordAttachments[0].attachment.href);
                } else {
                    this.recordAttachment = null;
                    this.recordAttachmentHref = null;

                     if(this.record.site) {
                         let rec: any = {
                             _id: this.record.originid
                         }
                         this.storesInstance.odStore.getPreviewAttachment(this.record.site.href, rec as IRecord)
                     }
                }

                this.getHistory(Global.idFromUrl(this.record.href))
                this.loadRecordVersions(Global.idFromUrl(this.record.href))
            }
        }).catch( (e:any) =>{
            this.storesInstance.ifStore.message = this.recordVersionsDao.normaliseResponse('loading record', e.message);
        });
    }

    saveRecord = () => {
        this.prepareRecord();
        this.recordDao.saveRecord(this.recordInstance, this.attachmentHref).then( (result:any) => {
            this.storesInstance.ifStore.message = result;
        }).catch( (e:any) =>{
            this.storesInstance.ifStore.message = this.recordVersionsDao.normaliseResponse('saving record', e.message);
        });
    }

    createRecord = () => {
        this.prepareRecord();

        let performanceId:string = '';

        let perf = {
            startDate: this.record.citeDate,
            endDate: this.record.citeDate,
            label: this.record.label,
            description: this.record.description,
            performanceType:  "PROCESS",
            participationType: "ENACTMENT"
        }

        this.recordDao.createPerformance(perf).then( (result:any) => {

            if (result.content) {
                performanceId = Global.idFromUrl(result.content.href);

                if(performanceId) {
                    this.recordDao.createRecord(this.recordInstance, performanceId, this.attachmentHref).then((result: any) => {
                        this.storesInstance.ifStore.message = result;
                        this.getRecord(result.content.href)

                    }).catch((e: any) => {
                        this.storesInstance.ifStore.message = this.recordVersionsDao.normaliseResponse('creating record', e.message);
                    });
                }
            } else {
                this.storesInstance.ifStore.message = result;
            }
        }).catch( (e:any) =>{
            this.storesInstance.ifStore.message = this.recordVersionsDao.normaliseResponse('creating record', e.message);
        });
    }

    prepareRecord = () => {
        if (this.recordContent.citeDate) {
            this.recordInstance.citeDate = this.recordContent.citeDate.value

            if (this.recordInstance.citeDate.length < 12) {
                this.recordInstance.citeDate = this.recordInstance.citeDate + " 00:00:01";
            }

            if (!this.recordContent.citeDateAccuracy) {
                this.recordInstance.citeDateAccuracy = 1;
            } else {
                this.recordInstance.citeDateAccuracy = this.recordContent.citeDateAccuracy.value;
            }

            this.recordInstance.label = this.recordContent.label.value;
            this.recordInstance.description = this.recordContent.description.value;
            this.recordInstance.recordCategory = this.recordContent.recordCategory.value;
            this.recordInstance.recordType = this.recordContent.recordType.value;
        }

        this.recordInstance.content = JSON.stringify(this.recordContent)
    }

    getAttachment = (href: string) => {
        this.recordDao.fetchAttachment(href).then( (attachment:any) => {
            if (attachment && attachment.errors.length > 0) {
                this.storesInstance.ifStore.message = attachment;
            } else {
                if (this.recordAttachment) {
                    URL.revokeObjectURL(this.recordAttachment);
                }
                this.recordAttachment = URL.createObjectURL(attachment.content);
                this.recordAttachmentHref = href;
            }

        }).catch( (e:any) =>{
            this.storesInstance.ifStore.message = this.recordVersionsDao.normaliseResponse('loading record', e.message);
        });
    }

    getHistory = (id: string) => {
        this.recordDao.fetchRecordHistory(id).then( (history:any) => {
            if (history && history.errors.length > 0) {
                this.storesInstance.ifStore.message = history;
            } else {
                this.recordHistory[id] = history.content;
            }
        }).catch( (e:any) =>{
            this.storesInstance.ifStore.message = this.recordVersionsDao.normaliseResponse('loading record history', e.message);
        });
    }

    getAllHistory = () => {
        this.recordSet.forEach( (item:any) => {
            this.getHistory(Global.idFromUrl(item.href))
        });
    }

    createSharedVersion = (blob: any, recordParms: any ) => {
        this.createdVersionHref = null;
        this.recordVersionsDao.createSharedVersion(blob, recordParms).then( (response: any) => {
            this.storesInstance.ifStore.message = response;
            this.createdVersionHref = response.content.attachment.externalHref;
        }).catch( (e:any) =>{
            this.storesInstance.ifStore.message = this.recordVersionsDao.normaliseResponse('loading share version', e.message);
        });
    }

    loadRecordVersions = (id: string ) => {
        this.recordVersionsDao.fetchRecordVersions(id).then( (response: any) => {
            if (response && response.errors.length > 0) {
                this.storesInstance.ifStore.message = response;
            } else {
                let tempVersions:any[] = [];

                if (!Global.isObjectEmpty(this.recVersionDefs)) {
                    //now augment each version structure with the versiondef label and icon
                    response.content.forEach((item: any) => {
                        let decorations: any = {
                            vdLabel: this.recVersionDefs[item.content.version].label,
                            vdIcon: this.recVersionDefs[item.content.version].icon,
                        };

                        let cont = JSON.parse(item.content.content);
                        if (cont) {
                            decorations.code = cont.code;
                        }

                        let clone = Object.assign({}, item.content, decorations);
                        tempVersions.push(clone);

                    });
                }
                this.recordVersions[id] = tempVersions
            }
        }).catch( (e:any) =>{
            this.storesInstance.ifStore.message = this.recordVersionsDao.normaliseResponse('loading share versions', e.message);
        });
    }

    getAllVersions = () => {
        this.recordSet.forEach( (item:any) => {
            this.loadRecordVersions(Global.idFromUrl(item.href))
        });
    }

    deleteRecordVersion = (version: any ) => {
        this.recordVersionsDao.deleteRecordVersion(version).then( (response: any) => {
            this.storesInstance.ifStore.message = response;
            this.getRecords('', false, true)
        }).catch( (e:any) =>{
            this.storesInstance.ifStore.message = this.recordVersionsDao.normaliseResponse('deleting share version', e.message);
        });
    }

    updateRecordVersion = (version: any, payload: any ) => {
        this.recordVersionsDao.updateRecordVersion(version, payload).then( (response: any) => {
            this.storesInstance.ifStore.message = response;
            this.loadRecordVersions(Global.idFromUrl(this.record.href))
        }).catch( (e:any) =>{
            this.storesInstance.ifStore.message = this.recordVersionsDao.normaliseResponse('deleting share version', e.message);
        });
    }

    deleteRecord = () => {
        this.recordDao.deleteItem(this.record.representations[0].performanceHref).then((result: any) => {
            this.storesInstance.ifStore.message = result;
        }).catch((e: any) => {
            this.storesInstance.ifStore.message = this.recordVersionsDao.normaliseResponse('deleting record', e.message);
        });

        this.recordDao.deleteItem(this.record.href).then((result: any) => {
            this.storesInstance.ifStore.message = result;
            this.getRecords()
        }).catch((e: any) => {
            this.storesInstance.ifStore.message = this.recordVersionsDao.normaliseResponse('deleting record', e.message);
        });
    }

    loadTags() {
        this.recordDao.fetchAllTags().then( (tags:any) => {
            if (tags && tags.errors.length > 0) {
                this.storesInstance.ifStore.message = tags;
            } else {
                this.allRecordTags = tags.content;
            }
        }).catch( (e:any) =>{
            this.storesInstance.ifStore.message = this.recordVersionsDao.normaliseResponse('loading record tags', e.message);
        });
    }

    registerSiteAttachment = () => {
        this.storesInstance.odStore.previewAttachment = null
        this.orgDao.registerAttachment(Global.idFromUrl(this.record.site.href), this.record.originid).then( (result:any) => {
            if (result && result.errors.length > 0) {
                this.storesInstance.ifStore.message = result;
            } else {
                this.getRecord(this.record.href)
            }
        }).catch( (e:any) =>{
            this.storesInstance.ifStore.message = this.recordVersionsDao.normaliseResponse('registering site record attachment', e.message);
        });
    }

    deregisterSiteAttachment = () => {
        this.recordAttachment = null
        this.orgDao.deregisterAttachment(Global.idFromUrl(this.record.site.href), this.record.originid).then( (result:any) => {
            if (result && result.errors.length > 0) {
                this.storesInstance.ifStore.message = result;
            } else {
                this.getRecord(this.record.href)
            }
        }).catch( (e:any) =>{
            this.storesInstance.ifStore.message = this.recordVersionsDao.normaliseResponse('deregistering site record attachment', e.message);
        });
    }

    blankRecord = () => {
        this.recordSet = []
        this.recordInstance  = {};
        this.recordInstanceContent  = {};
        this.recordAttachment  = ''
        this.recordAttachmentHref  = ''
        this.recordAttachmentFilename  = ''
        this.recordHistory = {}
        this.createdVersionHref = ''
        this.recordVersions = {}
    }

    setLocal = (local:boolean) => {
        if (this.recordInstanceContent) {
            this.recordInstanceContent['isLocal'] = local
        }
    }

}

MobX.decorate(
    RecordkeepingStore,
    {
        recVersionDefs: MobX.observable,
        recordSet: MobX.observable,
        recordInstance: MobX.observable,
        recordInstanceContent: MobX.observable,
        recordAttachment: MobX.observable,
        recordAttachmentHref: MobX.observable,
        recordAttachmentFilename: MobX.observable,
        recordHistory: MobX.observable,
        recordVersions: MobX.observable,
        createdVersionHref: MobX.observable,
        allRecordTags: MobX.observable,
        recordsFilterString: MobX.observable,

        recordLabel: MobX.computed,
        recordDescription: MobX.computed,
        recordDate: MobX.computed,
        recordDateAccuracy: MobX.computed,
        recordCatType: MobX.computed,
        isLocal: MobX.computed,

        loadRecordVersionDefs: MobX.action,
        saveRecordVersionDefs: MobX.action,
        getRecords: MobX.action,
        getRecord: MobX.action,
        createRecord: MobX.action,
        saveRecord: MobX.action,
        deleteRecord: MobX.action,
        getAttachment: MobX.action,
        getHistory: MobX.action,
        loadRecordVersions:  MobX.action,
        createSharedVersion: MobX.action,
        deleteRecordVersion: MobX.action,
        updateRecordVersion: MobX.action,
        getVersionsForItem: MobX.action,
        registerSiteAttachment: MobX.action,
        deregisterSiteAttachment: MobX.action,
        setLocal: MobX.action,
    }
);