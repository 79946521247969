import * as React from 'react';
import * as ReactStrap from 'reactstrap';
import * as ReactRouter from "react-router-dom";
import VersionsEdit from './VersionsEdit';
import {FaFolder, FaRegCalendarAlt, FaRegCalendarCheck, FaStickyNote, FaTags} from "react-icons/fa";
import * as MobX from "mobx-react";
import {InterfaceStore} from "../store/InterfaceStore";
import {RecordkeepingStore} from "../store/RecordkeepingStore";

import RecCatTypWidget from "./RecCatTypWidget";
import Uploads from "./Uploads";
import {IoIosMenu} from "react-icons/all";
import TagWidget from "./TagWidget";

class PersonalRecordEdit extends React.Component <any, any> {
    toggle : any;

    readonly ifStore : InterfaceStore;
    readonly rkStore : RecordkeepingStore;

    constructor(props : any) {
        super(props);

        this.state = {
            pickerModal: false,
        };

        this.ifStore = this.props.stores.ifStore;
        this.ifStore.message = null; // to prevent the message being shown when switching page

        this.rkStore = this.props.stores.rkStore;
    }

    togglePickerModal = () => {
        this.setState((prevState : any) => ({
            pickerModal: !prevState.pickerModal,
        }));
    }

    handlePictureSelect = (href:any) => {
        this.props.handleAttachmentSelect(href);
        this.togglePickerModal();
    }

    clearPicture = () => {
        this.props.handleAttachmentSelect('clear')
    }

    render() {

         return (
             <ReactStrap.Container fluid={true}>
                
                <ReactStrap.Row>
                    <ReactStrap.Col sm={12}>
                        <p>Enter the details of this record. Items marked with <span style={{ color: 'red' }}>*</span> are required.</p>
                    </ReactStrap.Col>
                </ReactStrap.Row>

                <ReactStrap.FormGroup row className={'m-2'}>
                    <ReactStrap.Label sm={2}>
                        <FaFolder /> Label <span style={{ color: 'red' }}>*</span>
                    </ReactStrap.Label>
                    <ReactStrap.Col sm={8}>
                        <ReactStrap.Input type="text" id="label" name="label" placeholder="My label" value={this.props.getFieldValue('label')} onChange={this.props.handleFieldChange}/>
                    </ReactStrap.Col>

                </ReactStrap.FormGroup>

                 <RecCatTypWidget handleFieldChange={this.props.handleFieldChange}/>

                 <ReactStrap.FormGroup row className={'m-2'}>
                     <ReactStrap.Label sm={2}>
                         <FaRegCalendarAlt /> My Date  <span style={{ color: 'red' }}>*</span>
                     </ReactStrap.Label>
                     <ReactStrap.Col sm={8}>
                         <ReactStrap.Input type="date" id="citeDate" name="citeDate" placeholder="My date" value={this.props.getFieldValue('citeDate')} onChange={this.props.handleFieldChange}/>
                     </ReactStrap.Col>
                 </ReactStrap.FormGroup>

                 <ReactStrap.FormGroup row className={'m-2'}>
                     <ReactStrap.Label sm={2}>
                         <FaRegCalendarCheck /> My Date Accuracy <span style={{ color: 'red' }}>*</span>
                     </ReactStrap.Label>
                     <ReactStrap.Col sm={8}>
                         <ReactStrap.CustomInput type="select" id="citeDateAccuracy" name="citeDateAccuracy" placeholder="Accuracy" value={this.props.getFieldValue('citeDateAccuracy')} onChange={this.props.handleFieldChange}>
                             <option value="1">This exact day</option>
                             <option value="2">This month</option>
                             <option value="3">This year</option>
                         </ReactStrap.CustomInput>
                     </ReactStrap.Col>
                 </ReactStrap.FormGroup>

                 <ReactStrap.FormGroup row className={'m-2'}>
                     <ReactStrap.Label sm={2}>
                         <IoIosMenu /> My Description
                     </ReactStrap.Label>
                     <ReactStrap.Col sm={8}>
                         <ReactStrap.Input type="textarea" id="description" name="description" placeholder="My description" value={this.props.getFieldValue('description')} onChange={this.props.handleFieldChange}/>
                     </ReactStrap.Col>
                 </ReactStrap.FormGroup>

                 <ReactStrap.FormGroup row className={'m-2'}>
                     <ReactStrap.Label sm={2}>
                         <FaStickyNote /> My Notes
                     </ReactStrap.Label>
                     <ReactStrap.Col sm={8}>
                         <ReactStrap.Input type="textarea" id="notes" name="notes" placeholder="My notes" value={this.props.getFieldValue('notes')} onChange={this.props.handleFieldChange}/>
                     </ReactStrap.Col>
                     <ReactStrap.Col sm={2}>
                         <VersionsEdit name={'notes'} onChange={this.props.handleVersionsChange}/>
                     </ReactStrap.Col>
                 </ReactStrap.FormGroup>

                 <ReactStrap.FormGroup row className={'m-2'}>
                     <ReactStrap.Label sm={2}>
                         <FaTags/> Tags:
                     </ReactStrap.Label>
                     <ReactStrap.Col sm={8}>
                         <TagWidget handleFieldChange={this.props.handleFieldChange}/>
                     </ReactStrap.Col>
                     <ReactStrap.Col sm={2}>
                         <VersionsEdit name={'tags'} onChange={this.props.handleVersionsChange}/>
                     </ReactStrap.Col>
                 </ReactStrap.FormGroup>

                 <ReactStrap.FormGroup row className={'m-2'}>
                     <ReactStrap.Col xs={'auto'}>
                         <ReactStrap.Button onClick={this.togglePickerModal}>Choose Attachment</ReactStrap.Button>
                         {this.rkStore.attachment &&
                             <ReactStrap.Button color={'link'} onClick={this.clearPicture}>Remove
                                 Attachment</ReactStrap.Button>
                         }
                     </ReactStrap.Col>
                 </ReactStrap.FormGroup>

                 <ReactStrap.Modal isOpen={this.state.pickerModal} toggle={this.togglePickerModal}>
                     <ReactStrap.ModalHeader toggle={this.toggle}>Choose Attachment</ReactStrap.ModalHeader>
                     <ReactStrap.ModalBody>
                         <Uploads callback={this.props.callback} selectCallback={this.handlePictureSelect}/>
                     </ReactStrap.ModalBody>
                     <ReactStrap.ModalFooter>
                         <ReactStrap.Button color="secondary" onClick={this.togglePickerModal}>Cancel</ReactStrap.Button>
                     </ReactStrap.ModalFooter>
                 </ReactStrap.Modal>

              </ReactStrap.Container>
        );
    }
}
export default MobX.inject('stores')(ReactRouter.withRouter(MobX.observer(PersonalRecordEdit)));