import * as MobX from 'mobx';
import Stores from "./Stores";
import UploadsDao from "../DataAccess/UploadsDao";
import {observable} from "mobx";

export class UploadsStore {
    storesInstance : Stores

    uploadsDao : UploadsDao

    /***********************************************************************
     * Store Fields
     ***********************************************************************/
    uploadsArray : any = observable([])
    uploadsBlobs: any = observable.map()

    constructor(storesInstance: Stores) {
        this.storesInstance = storesInstance

        this.uploadsDao = new UploadsDao();
    }

    /***********************************************************************
     * Probably good practice to enforce access through getters/setters
     ***********************************************************************/

    set uploads(ups) {
        this.uploadsArray = ups
    }

    get uploads() {
        return this.uploadsArray
    }

    //-----------------------------------------------------------------------


    set blobs(blbs) {
        this.uploadsBlobs = blbs
    }

    get blobs() {
        return this.uploadsBlobs
    }

    /***********************************************************************
     * Computed values
     ***********************************************************************/

    /***********************************************************************
     * Other actions
     ***********************************************************************/

    loadUploads = () => {
        this.uploadsDao.fetchUploads().then( (response:any) => {
            if (response && response.errors.length > 0) {
                this.storesInstance.ifStore.message = response;
            } else {
                this.uploads  = [];
                response.content.forEach((upload: any) => {
                    if (!upload.fileName.endsWith('.blob')) {
                        this.uploads.push(upload);
                    }
                });

            }
        }).catch( (e:any) =>{
            this.storesInstance.ifStore.message = this.uploadsDao.normaliseResponse('loading uploads', e.message);
        });
    }

    getUploadBlob = (href: string) => {
        this.uploadsDao.fetchUploadBlob(href).then( (response:any) => {
                if (response && response.errors.length > 0) {
                    this.storesInstance.ifStore.message = response;
                } else {
                    this.blobs.set(href, response.content);
                }

        }).catch( (e:any) =>{
            this.storesInstance.ifStore.message = this.uploadsDao.normaliseResponse('loading uploads', e.message);
        });
    }

    createUpload = (payload: any) => {
        this.uploadsDao.createUpload(payload).then( (response:any) => {
            if (response && response.errors.length > 0) {
                this.storesInstance.ifStore.message = response;
            } else {
                this.loadUploads();
            }
        }).catch( (e:any) =>{
            this.storesInstance.ifStore.message = this.uploadsDao.normaliseResponse('creating upload', e.message);
        });
    }

    removeUpload = (href: string) => {
        this.uploadsDao.deleteUpload(href).then( (response:any) => {
            if (response && response.errors.length > 0) {
                this.storesInstance.ifStore.message = response;
            } else {
                this.loadUploads();
            }
        }).catch( (e:any) =>{
            this.storesInstance.ifStore.message = this.uploadsDao.normaliseResponse('deleting upload', e.message);
        });
    }

}

MobX.decorate(
    UploadsStore,
    {
        uploadsArray: MobX.observable,

        loadUploads: MobX.action,
        removeUpload: MobX.action,
    }
);