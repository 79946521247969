import * as React from 'react';
import {Global} from '../global/Global';
import * as ReactRouter from 'react-router-dom';
import * as ReactStrap from 'reactstrap';
import QRCode from 'qrcode.react';
import moment from 'moment';
import html2canvas from 'html2canvas';
import * as MobX from "mobx-react";
import {FaShareAlt, FaDownload, FaExclamationTriangle} from "react-icons/fa";

import {InterfaceStore} from "../store/InterfaceStore";
import {RecordkeepingStore} from "../store/RecordkeepingStore";
import {OrgDataStore} from "../store/OrgDataStore";
import LoadingSpinner from "./LoadingSpinner";

let html2pdf = require('html2pdf.js');

class Profile extends React.Component <any, any> {

    readonly ifStore : InterfaceStore;
    readonly rkStore : RecordkeepingStore;
    readonly odStore : OrgDataStore;

    constructor(props : any) {
        super(props);
        this.state = {
            image: null,
            bioId: '',
            attachmentHref: null,
            shareModal: false,
            versionSelected: 'set0',
            versionShare: {},
            doDownload: false,
            showShareModalButtons: true,
            shareImage: null,
            pictureSrc: null,
        };

        this.ifStore = this.props.stores.ifStore;
        this.ifStore.message = null; // to prevent the message being shown when switching page

        this.odStore = this.props.stores.odStore;

        this.rkStore = this.props.stores.rkStore;
        this.rkStore.loadRecordVersionDefs();

        this.handleFieldChange = this.handleFieldChange.bind(this);
        this.getExpiryDate = this.getExpiryDate.bind(this);
        this.getVersionContent = this.getVersionContent.bind(this);
        this.triggerDownload = this.triggerDownload.bind(this);
    }

    toggleShareModal = (download : any) => {
        this.rkStore.versionHref = '';

        let consentText =  'You have been given permission to view this information only. You may not ';

        if(!download) {
            consentText += 'make copies or ';
        }

        consentText += 'disclose it to anyone else.';

        this.setState((prevState : any) => ({
            shareModal: !prevState.shareModal,
            versionShare: {
                tag: '',
                expiry: '',
                needCode: false,
                dateExpired: '',
                code: '',
                attachmentId: 0,
                attachmentHref: "",
                consent: false,
                consentText: consentText,
                customViewDate: '',
            },
            doDownload: download ? true : false,
            showShareModalButtons: true,
            shareImage: null
        }));
    }

    getDownloadFileName = (extension : any) => {
        let name = '';

        let rec = this.rkStore.record;

        if(rec && rec.recordcategory === 'Personal' && rec.recordtype === 'Bio') {
            if (this.showField(this.rkStore.recordContent, "fullname")) {
                name = this.rkStore.recordContent.fullname.value.replace(/\s/g, "_") + '-' + rec.label.replace(/\s/g, "_");
            } else if (this.showField(this.rkStore.recordContent, "prefname")) {
                name = this.rkStore.recordContent.prefname.value.replace(/\s/g, "_") + rec.label.replace(/\s/g, "_");
            }
        } else {
            name = rec.label.replace(/\s/g, "_");
        }

        return name +  extension;
    }

    showField(bio: any, name : any) {
        return (bio && bio[name] && bio[name]['value'] && bio[name]['versions'] && (bio[name]['versions']).includes(this.state.versionSelected) )
    }

    handleFieldChange(event : any) {
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;

        let versionShare : any = this.state.versionShare;

        versionShare[name] = value;

        this.setState({versionShare: versionShare});
    }

    getExpiryDate(expiryString : any) {
        let result : any = '';
        let amount : any = 1;
        let unit : any = expiryString;

        if(!unit || unit === "e") {
            unit = "years";
            amount = "900";
        }

        if(!unit || unit === "d") {
            unit = "days"
        }

        if(!unit || unit === "w") {
            unit = "weeks"
        }

        if(!unit || unit === "m") {
            unit = "months"
        }

        if(!unit || unit === "c") {
            result = this.state.versionShare.customViewDate + " 23:59:59"
        } else {
            result = moment().add(amount, unit).format("YYYY-MM-DD HH:mm:ss");
        }

        return result;
    }

    getVersionContent() {
        let content : any = {
            needCode: this.state.versionShare.needCode
        };
        if (this.state.versionShare.needCode) {
            content['code'] = Math.random().toString(36).slice(-6);
        }
        return content;
    }

    setUpShare = (reflow:boolean) => {
        window.scrollTo(0,0);

        let attachmentImage = document.getElementById("attachmentImage")
        if (attachmentImage) attachmentImage.style.height = '0px';

        let input : any = document.getElementById("containerDiv");

        if(reflow) {
            input.style.width = "200mm";
        }

        if(this.state.versionShare.consent) {
            let consentPara = document.createElement("p");
            consentPara.setAttribute("id", "consentPara");
            let consentText = document.createTextNode(this.state.versionShare.consentText);
            consentPara.appendChild(consentText);
            input.appendChild(consentPara);
        }

        let dateString = new Date().toString().substring(0, 24);

        let datePara = document.createElement("p");
        datePara.setAttribute("id", "datePara");
        let dateText = document.createTextNode(dateString );
        datePara.appendChild(dateText);
        input.appendChild(datePara);

        return input
    }

    tearDownShare = (input: any) => {
        let node : any = document.getElementById("datePara");
        input.removeChild(node)

        if (this.state.versionShare.consent) {
            node = document.getElementById("consentPara");
            input.removeChild(node)
        }

        input.style.width = '100%';
       // input.parentNode.style.overflow = 'invisible';

        let attachmentImage = document.getElementById("attachmentImage")
        if (attachmentImage) { // @ts-ignore
            attachmentImage.style.height = null;
        }

    }

    handleModalSubmit = (event : any) => {
        //THIS IS VERY IMPORTANT
        //https://stackoverflow.com/questions/39809943/react-preventing-form-submission
        event.preventDefault();

        let isBio = false;

        if(this.rkStore.record && this.rkStore.record.recordcategory === 'Personal' && this.rkStore.record.recordtype === 'Bio') {
            isBio = true;
        }

        /**********************************************************************************
         * Marshall the attachment
         **********************************************************************************/
        //Either download it or go do the online thing
        if(this.state.doDownload ) {

            if (isBio) {
                //Create a Pdf for downloading
                let input = this.setUpShare(true)
                html2pdf().set({
                    margin: 5,
                    pagebreak: {mode: 'avoid-all'},
                    html2canvas:  {
                        height: input.parentNode.clientHeight + 100,
                        windowHeight: input.parentNode.clientHeight + 100
                    },
                    allowTaint: false,
                    useCORS: true
                }).from(input).save(this.getDownloadFileName(".pdf")).then((response: any) => {
                    this.tearDownShare(input);

                    //we've downloaded, now close the modal
                    this.toggleShareModal(null);

                }).catch( (e:any) => {
                    console.log(e.message)

                });
            } else /*if (this.state.includeHeader === 'yes')*/ {
                let input = this.setUpShare(true)
                html2pdf().set({
                    margin: 5,
                    pagebreak: {mode: 'avoid-all'},
                    html2canvas: {
                        height: input.parentNode.clientHeight + 100,
                        windowHeight: input.parentNode.clientHeight + 100
                    },
                }).from(input).toPdf().output('dataurlstring').then((pdfAsString: any) => {
                    this.tearDownShare(input);

                    if (!this.rkStore.recordAttachment && !this.odStore.previewAttachment) {
                        //fake a download of the document
                        const link = document.createElement('a');
                        // create a blobURI pointing to our Blob
                        link.href = pdfAsString;
                        link.download = this.getDownloadFileName('.pdf');
                        // some browsers need the anchor to be in the doc
                        document.body.append(link);
                        link.click();
                        link.remove();
                    } else {

                        this.setState({
                            showShareModalButtons: false
                        });

                        //OK call the utility api function to join two pdfs - the header and the attachment
                        let content: any = {
                            header: pdfAsString,
                            recordId: Global.idFromUrl(this.rkStore.record.href)
                        }

                        let payload: string = JSON.stringify(content);

                        fetch(Global.getUrlPrefix() + 'attachment/prepend', {
                            method: 'POST',
                            mode: "cors",
                            body: payload,
                            headers: {
                                'Content-Type': 'application/json',
                                'Authorization': 'Bearer ' + Global.getAuthTokenCookie()
                            }
                        }).then(response => {
                            return response.blob();
                        }).then(responseObject => {
                            //fake a download of the document
                            const link = document.createElement('a');
                            // create a blobURI pointing to our Blob
                            link.href = URL.createObjectURL(responseObject);
                            link.download = this.getDownloadFileName('.pdf');
                            // some browsers need the anchor to be in the doc
                            document.body.append(link);
                            link.click();
                            link.remove();

                            this.toggleShareModal(null);
                        });
                    }
                });
            }
        } else {
            let input = this.setUpShare(false)
            html2canvas(input, {
                    height: input.parentNode.clientHeight + 100,
                    windowHeight: input.parentNode.clientHeight + 100
                }).then((canvas) => {
                this.tearDownShare(input);

                let blob =  canvas.toDataURL()

                let versionShare : any = this.state.versionShare;

                versionShare['filename'] = this.getDownloadFileName(".blob");
                versionShare['versionSelected'] = this.props.selected;
                versionShare['recordId'] = Global.idFromUrl(this.rkStore.record.href);
                versionShare['expiryDate'] = this.getExpiryDate(this.state.versionShare.expiry);
                versionShare['code'] = versionShare.needCode ? Math.random().toString(36).slice(-6) : "";

                this.setState({
                    versionShare: versionShare,
                    showShareModalButtons: false
                });

                this.rkStore.createSharedVersion(blob, versionShare);

            }).catch(function (this : Profile, e : any) {
                console.log("SOMETHING IS WRONG!", e);
                this.ifStore.message = {error: 'System error when sharing: ' + e.message};
            });
        }

        event.preventDefault();
    }

    triggerDownload() {
        this.setState({
            doDownload: true
        });

        this.toggleShareModal(null);
    }

    render() {

        let activeShare = true;
        let activeDownload = true;

        if (this.rkStore.record && this.rkStore.record.recordcategory !== 'Personal' && this.rkStore.record.recordtype !== 'Bio') {
            activeShare = this.rkStore.recordAttachment
            activeDownload = this.rkStore.recordAttachment || this.odStore.previewAttachment
        }

        return (
            <ReactStrap.Container fluid={true}>
                    < ReactStrap.Row className={'m-2'}>
                        <ReactStrap.Button className={'m-2'} color="primary" disabled = {!activeShare}
                                           onClick={this.triggerDownload}><FaShareAlt/> Share online
                            view</ReactStrap.Button>
                        <ReactStrap.Button className={'m-2'} color="link" size="sm" disabled = {!activeDownload}
                                           onClick={this.toggleShareModal}><FaDownload/> Download an uncontrolled copy
                        </ReactStrap.Button>
                    </ReactStrap.Row>
                    <hr/>

                    <ReactStrap.Modal isOpen={this.state.shareModal} toggle={ () => {this.toggleShareModal(null)}} >
                        <ReactStrap.ModalHeader toggle={ () => {this.toggleShareModal(null)}}>{this.state.doDownload ? "Download an uncontrolled copy" : "Share online view"}</ReactStrap.ModalHeader>
                        <ReactStrap.ModalBody>

                            <ReactStrap.Form onSubmit={this.handleModalSubmit}>

                                {this.state.doDownload &&
                                    <div>
                                        <p> <FaExclamationTriangle color='gold' size={16}/> WARNING! A downloaded copy of this info cannot be protected by an access code and does
                                        not expire. The system will not be able to track or control this copy if you share it.</p>
                                    </div>
                                }
                                <ReactStrap.Row className={'m-2'}>
                                    <ReactStrap.Col sm={4}>
                                        <ReactStrap.Label>Share with</ReactStrap.Label>
                                    </ReactStrap.Col>
                                    <ReactStrap.Col sm={7}>
                                        <ReactStrap.Input type="text" id="tag" name="tag" placeholder="Share Target" value={this.state.versionShare.tag} onChange={this.handleFieldChange} disabled={this.state.doDownload ? true : false}/>
                                    </ReactStrap.Col>
                                    <ReactStrap.Col sm={12}>
                                        <ReactStrap.FormText>Giving a name here, will let you see how many times this share has been viewed.</ReactStrap.FormText>
                                    </ReactStrap.Col>
                                </ReactStrap.Row>

                                <ReactStrap.Row className={'m-2'}>
                                    <ReactStrap.Col sm={4}>
                                        Viewing time
                                    </ReactStrap.Col>
                                    <ReactStrap.Col sm={8}>
                                        <ReactStrap.FormGroup check>
                                            <ReactStrap.Label check>
                                                <ReactStrap.Input type="radio" name="expiry" value="d"
                                                       checked={this.state.versionShare.expiry === 'd'}
                                                       onChange={this.handleFieldChange}
                                                       disabled={this.state.doDownload ? true : false}
                                                />
                                                1 Day
                                            </ReactStrap.Label>
                                        </ReactStrap.FormGroup>
                                        <ReactStrap.FormGroup check>
                                            <ReactStrap.Label check>
                                                <ReactStrap.Input type="radio" name="expiry" value="w"
                                                       checked={this.state.versionShare.expiry === 'w'}
                                                       onChange={this.handleFieldChange}
                                                       disabled={this.state.doDownload ? true : false}
                                                />
                                                1 Week
                                            </ReactStrap.Label>
                                        </ReactStrap.FormGroup>
                                        <ReactStrap.FormGroup check >
                                            <ReactStrap.Label check>
                                                <ReactStrap.Input type="radio" name="expiry" value="m"
                                                       checked={this.state.versionShare.expiry === 'm'}
                                                       onChange={this.handleFieldChange}
                                                       disabled={this.state.doDownload ? true : false}
                                                />
                                                1 Month
                                            </ReactStrap.Label>
                                        </ReactStrap.FormGroup>
                                        <ReactStrap.FormGroup check >
                                            <ReactStrap.Label check>
                                                <ReactStrap.Input type="radio" name="expiry" value="e"
                                                       checked={this.state.versionShare.expiry === 'e'}
                                                       onChange={this.handleFieldChange}
                                                       disabled={this.state.doDownload ? true : false}
                                                />
                                                For Ever
                                            </ReactStrap.Label>
                                        </ReactStrap.FormGroup>
                                        <ReactStrap.FormGroup check disabled>
                                            <ReactStrap.Label check>
                                                <ReactStrap.Input type="radio" name="expiry" value="c"
                                                       checked={this.state.versionShare.expiry === 'c'}
                                                       onChange={this.handleFieldChange}
                                                       disabled={this.state.doDownload ? true : false}
                                                />
                                                Until Custom Date
                                            </ReactStrap.Label>
                                        </ReactStrap.FormGroup>
                                    </ReactStrap.Col>

                                    {this.state.versionShare.expiry === 'c' &&
                                        <ReactStrap.Input
                                            type="date"
                                            name="customViewDate"
                                            id="customViewDate"
                                            placeholder="1/1/2019"
                                            value={this.state.versionShare.customViewDate} onChange={this.handleFieldChange} disabled={this.state.doDownload ? true : false}
                                        />
                                    }

                                    <ReactStrap.Col sm={12}>
                                        <ReactStrap.FormText>How long do you want this view to be available?</ReactStrap.FormText>
                                    </ReactStrap.Col>
                                </ReactStrap.Row>

                                <ReactStrap.Row className={'m-2'}>
                                    <ReactStrap.Col sm={6}>
                                        <ReactStrap.Label>Need access code?</ReactStrap.Label>
                                    </ReactStrap.Col>
                                    <ReactStrap.Col sm={1}>
                                        <ReactStrap.CustomInput type="switch" id="needCode" name="needCode" label="" value={this.state.versionShare.needCode} onChange={this.handleFieldChange} disabled={this.state.doDownload ? true : false}/>
                                    </ReactStrap.Col>
                                    <ReactStrap.Col sm={12}>
                                        <ReactStrap.FormText>Protect view with a secret access code?</ReactStrap.FormText>
                                    </ReactStrap.Col>
                                </ReactStrap.Row>

                                <hr/>

                                <ReactStrap.Row className={'m-2'}>
                                    <ReactStrap.Col sm={6}>
                                        <ReactStrap.Label>Consent Notice</ReactStrap.Label>
                                    </ReactStrap.Col>
                                    <ReactStrap.Col sm={1}>
                                        <ReactStrap.CustomInput type="switch" id="consent" name="consent" label="" value={this.state.versionShare.consent} onChange={this.handleFieldChange}/>
                                    </ReactStrap.Col>
                                    <ReactStrap.Col sm={12}>
                                        <ReactStrap.FormText>Include your permission to view the info?</ReactStrap.FormText>
                                    </ReactStrap.Col>
                                </ReactStrap.Row>

                                {this.state.versionShare.consent &&
                                <ReactStrap.Row className={'m-2'}>
                                    <ReactStrap.Col sm={6}>
                                        <ReactStrap.Label>Consent text</ReactStrap.Label>
                                    </ReactStrap.Col>
                                    <ReactStrap.Col sm={12}>
                                        <ReactStrap.Input type="textarea" id="consentText" name="consentText"
                                                     value={this.state.versionShare.consentText}
                                                     onChange={this.handleFieldChange}/>
                                    </ReactStrap.Col>
                                    <ReactStrap.Col sm={12}>
                                        <ReactStrap.FormText>Use this pemission statement or enter your own.</ReactStrap.FormText>
                                    </ReactStrap.Col>
                                </ReactStrap.Row>
                                }

                                {this.state.showShareModalButtons &&
                                    <div>
                                    <ReactStrap.Button color="primary" type="submit"
                                            className={'ml-4'}>{this.state.doDownload ? "Continue with download" : " Get View URL"}</ReactStrap.Button>
                                    <ReactStrap.Button className = "float-right" onClick={this.toggleShareModal}>Cancel</ReactStrap.Button>
                                    </div>
                                }

                                {!this.state.showShareModalButtons && !this.rkStore.versionHref &&
                                    <div>
                                        <hr/>
                                        <LoadingSpinner msg={'PreparingShare (this may take some time)...'}/>
                                    </div>
                                }

                            </ReactStrap.Form>

                        </ReactStrap.ModalBody>

                        {this.rkStore.versionHref &&
                        <ReactStrap.ModalFooter>
                            <ReactStrap.Col>
                                {this.state.versionShare.code &&
                                    <ReactStrap.Row className={'m-2'}>
                                       Access code: &nbsp; <b>{this.state.versionShare.code}</b>
                                    </ReactStrap.Row>
                                }

                                <ReactStrap.Row className={'m-2'}>
                                    {this.rkStore.versionHref}
                                </ReactStrap.Row>

                                <ReactStrap.Row className={'m-2'}>
                                    <QRCode value={this.rkStore.versionHref}/>
                                    Right click (on computer) or hold (on phone) to save.
                                </ReactStrap.Row>

                                <ReactStrap.Row className="float-right">
                                    <ReactStrap.Button color="secondary" onClick={this.toggleShareModal}>Close</ReactStrap.Button>
                                </ReactStrap.Row>
                            </ReactStrap.Col>
                        </ReactStrap.ModalFooter>
                        }
                    </ReactStrap.Modal>


            </ReactStrap.Container>
        );
    }
}
export default MobX.inject('stores')(ReactRouter.withRouter(MobX.observer(Profile)));