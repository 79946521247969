//import { decorate, observable, computed  } from "mobx";
import * as MobX from 'mobx';
import Stores from "./Stores";
import OrgDao from "../DataAccess/OrgDao";
import {Global} from "../global/Global";
//import {Global} from "../global/Global";


export interface ISite {
    description : string;
    href : string;
    label : string;
    recordsHref : string;
    tag : string;
}

export interface IRecord {
    _id: string;
    category: string;
    date: string;
    description: string;
    label: string;
    registered: boolean;
    tenantId: string;
    type: string;
}

export interface IKeyword {
    id: string,
    text: string
}

export class OrgDataStore {
    storesInstance : Stores;
    orgDao : OrgDao;

    /***********************************************************************
     * Store Fields
     ***********************************************************************/
    allSites : ISite[] = [];
    selectedSites : ISite[] = [];
    avoidKeywords : IKeyword[] = [];
    siteSearchTerm : string = '';
    currentSite: ISite = {
        description : '',
        href :  '',
        label :  '',
        recordsHref :  '',
        tag :  '',
    }
    allSiteRecords : IRecord[] = [];
    previewAttachment: any;

    constructor (storesInstance: Stores) {
        this.storesInstance = storesInstance;
        this.orgDao = new OrgDao();

        // Get all sites and selected sites from server
        //this.allSites = ["ONE SITE1", "TWO SITE2", "THREE SITE3", "THREE PLUS SITE3+", "FOUR SITE4"];
        //this.selectedSites = ["FOUR SITE4"];

        this.AddSelectedSite = this.AddSelectedSite.bind(this);
        this.RemoveSelectedSite = this.RemoveSelectedSite.bind(this);
    }

    /***********************************************************************
     * SITES
     ***********************************************************************/

    /* ALL SITES */
    GetAllSitesFromServer() {
        this.allSites = [];
        this.orgDao.fetchAllSites().then( (sites:any) => {
            let aSite : ISite;
            //console.log('ALL SITES : ', sites);
            for (let site of sites.content) {
                aSite = site as ISite;
                //console.log ('aSite\n');
                //console.log(aSite);
                this.allSites.push(aSite);
            }
        }).catch( (e:any) =>{
            this.storesInstance.ifStore.message = this.orgDao.normaliseResponse('fetching all sites: ', e.message);
        });
        //this.allSites = AllSitesFromServer;
    }
    get AllSites() {
        return this.allSites;
    }

    /* SELECTED SITES */
    GetSelectedSitesFromServer() {
        this.selectedSites = [];
        this.orgDao.fetchSelectedSites().then( (sites:any) => {
            if (sites && sites.errors.length > 0) {
                this.storesInstance.ifStore.message = sites;
            } else {
                let aSite : ISite;

                //console.log('SELECTED SITES : ', sites);
                for (let site of sites.content) {
                    aSite = site as ISite;
                    this.selectedSites.push(aSite);
                }
            }
        }).catch( (e:any) =>{
            this.storesInstance.ifStore.message = this.orgDao.normaliseResponse('fetching all selected sites: ', e.message);
        });
    }
    get SelectedSites() {
        return this.selectedSites;
    }
    AddSelectedSite(site : ISite) {
        //add to server
        this.orgDao.registerSite(site).then((result:any) => {
            //console.log('result from adding site', result);
            //add locally
            this.selectedSites.push(site);
            //update search list
            this.GetAllSitesFromServer();//also refresh when tab changed, or select/unselect buttons clicked
            this.GetSelectedSitesFromServer();//also refresh when tab changed, or select/unselect buttons clicked
        }).catch( (e:any) =>{
            this.storesInstance.ifStore.message = this.orgDao.normaliseResponse('registering a site: ', e.message);
        });
    }
    RemoveSelectedSite(site : ISite) {
        //remove from server
        this.orgDao.deRegisterSite(site).then((result:any) => {
            //console.log('result from removing site', result);
            //remove locally
            this.selectedSites.forEach( (item : any, index : any) => {
                if(item.tag === site.tag) this.selectedSites.splice(index,1);
            });

            this.GetAllSitesFromServer();//also refresh when tab changed, or select/unselect buttons clicked
            this.GetSelectedSitesFromServer();//also refresh when tab changed, or select/unselect buttons clicked
        }).catch( (e:any) =>{
            this.storesInstance.ifStore.message = this.orgDao.normaliseResponse('de-registering a site: ', e.message);
        });
    }

    get SearchSites() {
        return this.updateSearchList(this.siteSearchTerm, this.unregisteredSites );
    }

    /* UNREGISTERED SITES = ALL SITES - SELECTED SITES */
    get unregisteredSites() {
        let searchSites : ISite[] = [];
        let site : ISite;
        for (site of this.allSites) {
            //for each site in all sites, if it is not in selected sites,
            // it will be included in the list of search sites to be used in the search box.
            let aTag : string = site.tag;
            let selected : boolean = false;
            for (let sSite of this.selectedSites) {
                let sTag : string = sSite.tag;
                if (sTag === aTag) {
                    selected = true;
                }
            }
            if (selected === false) {
                searchSites.push(site);
            }
        }

        return searchSites
    }

    updateSearchList(searchTerm : any, searchSites : ISite[]) {
        // Variable to hold the current SearchSites of the list (SearchSites = AllSites - SelectedSites)
        let currentList : ISite[]= [];
        // Variable to hold the filtered SearchResults list before putting into state
        // (SearchResults = selected stuff in SearchSites)
        let newList : ISite[] = [];

        // If the search bar isn't empty
        if (searchTerm !== "") {
            // Assign the SearchSites list to currentList
            currentList = this.applySearchFilters(searchSites);
            //console.log('CURRENT SEARCH LIST : ', currentList.join(','));

            // Use .filter() to determine which items should be displayed
            // based on the search terms
            newList = currentList.filter((item : ISite) => {
                // change current item to lowercase
                const lc = item.description.toLowerCase();
                // change search term to lowercase
                const filter = searchTerm.toLowerCase();
                // check to see if the current list item includes the search term
                // If it does, it will be added to newList. Using lowercase eliminates
                // issues with capitalization in search terms and search content
                return lc.includes(filter);
            });
        } else {
            // If the search bar is empty, set newList empty
            newList = [];
        }
        // Set the searchResults state based on what our rules added to newList
        return newList;
    }

    /* FILTERS */
    //need to make sure all the keywords are lowered cases when storing.
    get AvoidKeywords() {
        return this.avoidKeywords;
    }
    set AvoidKeywords(keywords : IKeyword[]) {
        this.avoidKeywords = keywords;
    }

    applySearchFilters(searchSites : ISite[]) {
        //alert('length = ' + this.avoidKeywords.length);
        if (this.avoidKeywords.length < 1) {
            return searchSites;
        }

        let filteredSearchSites = searchSites.filter((item : ISite) => {
            // change current item to lowercase
            const text = item.label.toLowerCase() + ' ' + item.description.toLowerCase();
            // if the item does not content any avoided keywords, keep it
            for (let keyword of this.AvoidKeywords) {
                if (text.includes(keyword.text.toLowerCase())) {
                    return false;
                }
            }
            return true;
        });
        return filteredSearchSites;
    }

    /***********************************************************************
     * SITE RECORDS
     ***********************************************************************/
    registerSiteRecordToServer (aRecord : IRecord) {
        this.orgDao.registerSiteRecord(Global.idFromUrl(this.currentSite.href), aRecord).then(()=> {
            this.getSiteRecordsFromServer();//refresh
        }).catch( (e:any) =>{
            this.storesInstance.ifStore.message = this.orgDao.normaliseResponse('registering a site record', e.message);
        });
    }

    registerSiteRecordsToServer (records : IRecord[]) {
        this.orgDao.registerSiteRecords(Global.idFromUrl(this.currentSite.href), records).then(()=> {
            this.getSiteRecordsFromServer();//refresh
        }).catch( (e:any) =>{
            this.storesInstance.ifStore.message = this.orgDao.normaliseResponse('registering site records', e.message);
        });
    }

    deRegisterSiteRecordFromServer (aRecord? : IRecord) {
        if (!aRecord) {
             aRecord = {
                _id: this.storesInstance.rkStore.record.originid,
                category: this.storesInstance.rkStore.record.recordcategory,
                date: this.storesInstance.rkStore.record.citeDate,
                description: this.storesInstance.rkStore.record.description,
                label: this.storesInstance.rkStore.record.label,
                registered: true,
                tenantId: this.storesInstance.rkStore.record.site.tag,
                type: this.storesInstance.rkStore.record.recordtype
            }
        }
        //depending on whether we are calling from a site management context or a record management context
        let href = this.currentSite.href ? this.currentSite.href : this.storesInstance.rkStore.record.site.href
        this.orgDao.deRegisterSiteRecord(Global.idFromUrl(href), aRecord).then(()=> {
            this.getSiteRecordsFromServer();//refresh
            this.storesInstance.rkStore.getRecords()
        }).catch( (e:any) =>{
            this.storesInstance.ifStore.message = this.orgDao.normaliseResponse('de-registering a site record', e.message);
        });
    }

    getSiteRecordsFromServer () {
        console.log("FUNCTION CALLED: getSiteRecordsFromServer");
        this.allSiteRecords = [];
        this.orgDao.fetchAllSiteRecords(Global.idFromUrl(this.currentSite.href)).then( (records:any) => {
            console.log('RECORDS: ', records);
            let aRecord : IRecord;
            for (let record of records.content) {
                aRecord = record as IRecord;
                this.allSiteRecords.push(aRecord);
            }
        }).catch( (e:any) =>{
            this.storesInstance.ifStore.message = this.orgDao.normaliseResponse('fetching all records of a site: ', e.message);
        });
    }

/*    get SiteRecordsShown () {
        let isShown : boolean = false;
        if (this.currentSite.id !== '') {
            isShown = true;
        }
        return isShown;
    }*/

    get NonSelectedSiteRecords () {
        let nonSelectedSiteRecords : IRecord[] = [];
        let record : IRecord;
        for (record of this.allSiteRecords) {
            if (record.registered === false) {
                nonSelectedSiteRecords.push(record);
            }
        }
        return nonSelectedSiteRecords;
    }
    get SelectedSiteRecords () {
        let selectedSiteRecords : IRecord[] = [];
        let record : IRecord;
        for (record of this.allSiteRecords) {
            if (record.registered === true) {
                selectedSiteRecords.push(record);
            }
        }
        return selectedSiteRecords;
    }

    getPreviewAttachment = (href:string, record:IRecord)  => {
        URL.revokeObjectURL(this.previewAttachment);
        this.previewAttachment = null;

        //either from current site in site management context or from curent record

        this.orgDao.fetchPreviewAttachment(Global.idFromUrl(href), record).then( (attachment:any) => {
            this.previewAttachment = URL.createObjectURL(attachment.content);
        }).catch( (e:any) =>{
            this.storesInstance.ifStore.message = this.orgDao.normaliseResponse('fetching preview attachment: ', e.message);
        });
    }
}

MobX.decorate(
    OrgDataStore,
    {
        allSites: MobX.observable,
        selectedSites : MobX.observable,
        siteSearchTerm : MobX.observable,
        avoidKeywords : MobX.observable,
        currentSite: MobX.observable,
        allSiteRecords : MobX.observable,
        previewAttachment: MobX.observable,

        SearchSites: MobX.computed,
        unregisteredSites: MobX.computed,
        NonSelectedSiteRecords : MobX.computed,
        SelectedSiteRecords : MobX.computed,

        GetSelectedSitesFromServer: MobX.action,
        GetAllSitesFromServer: MobX.action,
        getPreviewAttachment: MobX.action,

    }
);